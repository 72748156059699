import { Chip, Divider, Icon, withStyles, Tooltip } from "@material-ui/core";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useState } from "react";
import { Handle, Position } from "reactflow";

/*eslint-disable*/
export default function ActionNode(node) {
  /*
    public const int AUTOMATION_START_EVENT_SUBSCRIBER_ADDED = 0;
        public const int AUTOMATION_START_EVENT_FINISHED_SERIE = 1;
        public const int AUTOMATION_START_EVENT_ENGAGED_BROADCAST = 2;
        public const int AUTOMATION_START_EVENT_ENGAGED_CATALOG = 3;
        public const int AUTOMATION_START_EVENT_ENGAGED_ITEM = 4;

        AUTOMATION_STEP_TYPE_ADD_TAG
        public const string AUTOMATION_STEP_TYPE_INPUT = "AUTOMATION_STEP_TYPE_INPUT";
        public const string AUTOMATION_STEP_TYPE_SEND_EMAIL = "AUTOMATION_STEP_TYPE_SEND_EMAIL";
        public const string AUTOMATION_STEP_TYPE_CALL_WEBHOOK = "AUTOMATION_STEP_TYPE_CALL_WEBHOOK";
        public const string AUTOMATION_STEP_TYPE_WAIT_TIME = "AUTOMATION_STEP_TYPE_WAIT_TIME";
        public const string AUTOMATION_STEP_TYPE_WAIT_EVENT = "AUTOMATION_STEP_TYPE_WAIT_EVENT";
        */

  const onClick = (event) => node.data.onClickAdd(event, node,"");

  const [triggerMethod] = useState([
    { method: "GET", value: 0 },
    { method: "POST", value: 1 },
    { method: "PUT", value: 2 },
    { method: "DELETE", value: 3 },
  ]);

  const StyleChip = withStyles({
    root: {
      backgroundColor: "gray",
      borderColor: "white",
      "& .MuiChip-label": {
        fontSize: 8,
        color: "white",
      },
    },
  })(Chip);

  const getIntervalMinutes = (duration, interval) => {
    switch (interval) {
      case "Minutes":
        return duration;
      case "Hours":
        return duration / 60;
      case "Days":
        return duration / 60 / 24;
    }
  };
  const getIcon = (type) => {
    switch (type) {
      case "AUTOMATION_STEP_TYPE_WAIT_TIME":
        return (
          <Icon fontSize="small" mt={3}>
            alarm_on
          </Icon>
        );
      case "AUTOMATION_STEP_TYPE_PAUSE_SUBSCRIBER":
        return (
          <Icon fontSize="small" mt={3}>
            pause
          </Icon>
        );
      case "AUTOMATION_STEP_TYPE_CALL_WEBHOOK":
        return (
          <Icon fontSize="small" mt={3}>
            webhook
          </Icon>
        );
      case "AUTOMATION_START_EVENT_ENGAGED_BROADCAST":
        return (
          <Icon fontSize="small" mt={3}>
            podcasts
          </Icon>
        );
      case "AUTOMATION_STEP_TYPE_SEND_EMAIL":
        return (
          <Icon fontSize="small" mt={3}>
            email
          </Icon>
        );
      case "AUTOMATION_STEP_TYPE_ADD_TAG":
        return (
          <Icon fontSize="small" mt={3}>
            sell
          </Icon>
        );
      case "AUTOMATION_STEP_TYPE_REMOVE_TAG":
        return (
          <Icon fontSize="small" mt={3}>
            sell
          </Icon>
        );
      case "AUTOMATION_STEP_TYPE_UNSUBSCRIBE":
        return (
          <Icon fontSize="small" mt={3}>
            unsubscribe
          </Icon>
        );
      case "AUTOMATION_START_EVENT_ENGAGED_CATALOG":
        return <StyleIcon fontSize="small" mt={3} />;
      case "AUTOMATION_START_EVENT_ENGAGED_ITEM":
        return <StyleIcon fontSize="small" mt={3} />;
      case "AUTOMATION_START_EVENT_REACHED_NOENGAGEMENT":
        return (
          <Icon fontSize="small" mt={3}>
            cancel_schedule_send
          </Icon>
        );
      default:
        return "error";
    }
  };

  const getAction = (type) => {
    switch (type) {
      case "AUTOMATION_STEP_TYPE_INPUT":
        return "input";
      case "AUTOMATION_STEP_TYPE_SEND_EMAIL":
        return "Send a copy";
      case "AUTOMATION_STEP_TYPE_CALL_WEBHOOK":
        return "Call a webhook";
      case "AUTOMATION_STEP_TYPE_WAIT_TIME":
        return "Set pause time";
      case "AUTOMATION_STEP_TYPE_PAUSE_SUBSCRIBER":
        return "Pause mailing";
      case "AUTOMATION_STEP_TYPE_WAIT_EVENT":
        return "Wait until";
      case "AUTOMATION_STEP_TYPE_ADD_TAG":
        return "Add a tag";
      case "AUTOMATION_STEP_TYPE_REMOVE_TAG":
        return "Remove a tag";
      case "AUTOMATION_STEP_TYPE_UNSUBSCRIBE":
        return "Unsubscribe the current subscriber";
      default:
        return "error";
    }
  };

  const getData = (type) => {
    switch (type) {
      case "AUTOMATION_STEP_TYPE_INPUT":
        return "input";
      case "AUTOMATION_STEP_TYPE_SEND_EMAIL":
        return (
          <MDBox>
            {" "}
            <MDTypography variant="caption" fontWeight="medium">
              Subject:{" "}
            </MDTypography>
            <MDTypography variant="caption">{node.data.aus_additionalData}</MDTypography>
          </MDBox>
        );
      case "AUTOMATION_STEP_TYPE_CALL_WEBHOOK":
        return (
          <MDBox>
            <MDBox display="flex">
              <MDTypography variant="caption" fontWeight="medium">
                URL:{" "}
              </MDTypography>
              <MDTypography variant="caption">
                {JSON.parse(node.data.aus_triggerDetails).tri_targetURL}{" "}
              </MDTypography>
            </MDBox>
            <MDBox display="flex">
              <MDTypography variant="caption" fontWeight="medium">
                METHOD:{" "}
              </MDTypography>
              <MDTypography variant="caption">
                {
                  triggerMethod.filter(
                    (met) => met.value == JSON.parse(node.data.aus_triggerDetails).tri_method
                  )[0].method
                }
              </MDTypography>
            </MDBox>
            <MDBox display="flex">
              <MDTypography variant="caption" fontWeight="medium">
                BODY:{" "}
              </MDTypography>
              <MDTypography variant="caption">
                {JSON.parse(node.data.aus_triggerDetails).tri_body}
              </MDTypography>
            </MDBox>
          </MDBox>
        );
      case "AUTOMATION_STEP_TYPE_WAIT_TIME":
        return (
          <MDBox display="flex" mt={2}>
            <MDTypography variant="caption">Pause for: </MDTypography>
            <MDTypography variant="caption" fontWeight="medium">
              {getIntervalMinutes(node.data.aus_intervalMinutes, node.data.aus_additionalData)}{" "}
              {node.data.aus_additionalData}
            </MDTypography>
          </MDBox>
        );
      case "AUTOMATION_STEP_TYPE_PAUSE_SUBSCRIBER":
        return (
          <MDBox display="flex" mt={2}>
            <MDTypography variant="caption">Pause for: </MDTypography>
            <MDTypography variant="caption" fontWeight="medium">
              {getIntervalMinutes(node.data.aus_intervalMinutes, node.data.aus_additionalData)}{" "}
              {node.data.aus_additionalData}
            </MDTypography>
          </MDBox>
        );
      case "AUTOMATION_STEP_TYPE_WAIT_EVENT":
        return "Wait until";
      case "AUTOMATION_STEP_TYPE_ADD_TAG":
        return (
          <>
            <MDBox display="flex">
              <MDTypography ml={1} variant="caption" fontWeight="medium">
                Tag(s):
              </MDTypography>
            </MDBox>
            <MDBox>
              {node.data.aus_tag.split(",").map((tag) => {
                return <StyleChip variant="outlined" label={tag.trim()} size="small" />;
              })}
            </MDBox>
          </>
        );
      case "AUTOMATION_STEP_TYPE_REMOVE_TAG":
        return (
          <>
            <MDBox display="flex">
              <MDTypography ml={1} variant="caption" fontWeight="medium">
                Tag(s):
              </MDTypography>
            </MDBox>
            <MDBox>
              {node.data.aus_tag.split(",").map((tag) => {
                return <StyleChip variant="outlined" label={tag.trim()} size="small" />;
              })}
            </MDBox>
          </>
        );
      case "AUTOMATION_STEP_TYPE_UNSUBSCRIBE":
        return "";
      default:
        return "";
    }
  };

  return (
    <div
      style={{
        height: "80px",
        border: "1px solid #eee",
        padding: "5px",
        borderRadius: "5px",
        background: node.selected ? "#CCCCCC" : "#96FF9D22",
      }}
    >
      <Handle type="target" position={Position.Top} />

      <MDBox display="flex">
        {getIcon(node.data.aus_type)}
        <MDBox display="flex" style={{ width: "100%" }}>
          <MDTypography variant="caption" fontWeight="medium" ml={1} style={{ width: "100px" }}>
            {getAction(node.data.aus_type)}
          </MDTypography>
        </MDBox>
        <MDBox display="flex" justifyContent="right" style={{ width: "100%" }}>
          {node.selected && (
            <Tooltip title="delete action" placement="top">
              <Icon
                color="action"
                style={{ fontSize: "15px" }}
                onClick={(e) => node.data.onDelete(e, node)}
              >
                delete
              </Icon>
            </Tooltip>
          )}
        </MDBox>
      </MDBox>
      <Divider />
      {getData(node.data.aus_type)}

      <Handle type="source" position={Position.Bottom}>
        {node.selected && (
          <Tooltip title="Add a new action" placement="top">
            <Icon color="action" fontSize="small" onClick={onClick} style={{ marginLeft: -8 }}>
              add_circle
            </Icon>
          </Tooltip>
        )}
      </Handle>
    </div>
  );
}

/*

<NodeToolbar isVisible={true} position="right">
        <button>delete</button>
        <button>copy</button>
        <button>expand</button>
      </NodeToolbar>
      
 <FormControl className={classes.formControl}>
        <InputLabel>pick the tags to use</InputLabel>
        {suggestionsTag.length > 0 && (
          <Select
            placeholder="Select the tags to use"
            multiple
            value={tags}
            onChange={handleChangeTags}
            input={<Input />}
            renderValue={(selected) => selected.join(",")}
            MenuProps={MenuProps}
          >
            {suggestionsTag.map((tag) => (
              <MenuItem key={tag.id} value={tag.name}>
                <Checkbox checked={tags.indexOf(tag.name) > -1} />
                <ListItemText primary={tag.name} />
              </MenuItem>
            ))}
          </Select>
        )}
      </FormControl>
      */
