import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import NearMeDisabledIcon from "@mui/icons-material/NearMeDisabled";
import NearMeIcon from "@mui/icons-material/NearMe";
// import EditIcon from "@material-ui/icons/Edit";
import { withStyles } from "@material-ui/core/styles";

const defaultToolbarSelectStyles = {
  iconButton: {
    marginRight: "24px",
    top: "50%",
    display: "inline-block",
    position: "relative",
    transform: "translateY(-50%)",
  },
  deleteIcon: {
    color: "#FF0000",
  },
};
/* eslint-disable */
class CustomToolbarSelect extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={"custom-toolbar-select"}>       
        <Tooltip title={"Activate the selected serie"}>
          <IconButton onClick={this.props.onActivate}>
            <NearMeIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={"Deactivate the selected serie"}>
          <IconButton onClick={this.props.onDeactivate}>
            <NearMeDisabledIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={"Delete the selected serie"}>
          <IconButton onClick={this.props.onDelete}>
            <DeleteIcon className={classes.deleteIcon} />
          </IconButton>
        </Tooltip>
      </div>
    );
  }
}

export default withStyles(defaultToolbarSelectStyles, {
  name: "CustomToolbarSelect",
})(CustomToolbarSelect);
