import { useContext, useEffect, useRef, useState } from "react";
import EmailEditor from "react-email-editor";
import theme from "assets/theme";
// react-router-dom components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import client from "ApiClient";
import {
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  ListItemText,
  MenuItem,
  NativeSelect,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import SimpleReactValidator from "simple-react-validator";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Moment from "moment";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import { Checkbox } from "@material-ui/core";
import MSPersonalizationTags from "components/MSPersonalizationTags";
import { useMaterialUIController } from "context";
import { FeatureFlags } from "context/FeatureFlags";
import MSMultipleSubjects from "components/MSMultipleSubjects";

/* eslint-disable */
function AddEmailCopy(props) {
  const [margin, setMargin] = useState(300);
  const [isLoading, setIsLoading] = useState(false);
  const [expire, setExpire] = useState(false);
  const [includeInSecuence, setIncludeInSecuence] = useState(false);
  const [addArticlesSuggestion, setAddArticlesSuggestion] = useState(true);
  const [showTRacking, setShowTracking] = useState(false);
  const [isTracking, setIsTracking] = useState(false);
  const [subject, setSubject] = useState([""]);
  const [envelopFrom, setEnvelopFrom] = useState("");
  const [displayFrom, setDisplayFrom] = useState("");
  const [token, setToken] = useState(JSON.parse(localStorage.getItem("AuthorizationToken")));
  const [valueDate, setValueDate] = useState(Moment());
  const [testEmail, setTestEmail] = useState("");
  const [openTestSB, setOpenTestSB] = useState(false);
  const closeTestSB = () => setOpenTestSB(false);
  const [arrSenderProfiles, setArrSenderProfiles] = useState([]);
  const [senderProfile, setSenderProfile] = useState(-1);
  // const [contentHtml, setContentHtml] = useState(props.body);
  const [contentText, setContentText] = useState("");
  const [mimeType, setMimeType] = useState(0);
  const { openModal, closeModal } = props;
  const [, forceUpdate] = useState();
  const [templateBody, setTemplateBody] = useState(props.body);
  const [htmlPreview, setHtmlPreview] = useState("");
  const [subjectPreview, setSubjectPreview] = useState("");
  const [catalogs, setCatalogs] = useState([]);
  const emailEditorRef = useRef(null);
  const [selectedSubject, setSelectedSubject] = useState("");
  const { features } = useContext(FeatureFlags);

  const [controller] = useMaterialUIController();
  const { miniSidenav } = controller;

  useEffect(() => {
    if (miniSidenav) setMargin(0);
    else setMargin(300);
  }, [miniSidenav]);

  const simpleValidator = useRef(
    new SimpleReactValidator({
      validators: {
        sender: {
          // name the rule
          message: "Please select a sender profile",
          rule: (val, params, validator) => {
            return val >= 0;
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
          required: true, // optional
        },
      },
    })
  );
  document.addEventListener("focusin", (e) => {
    if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
      e.stopImmediatePropagation();
    }
  });

  const clearVariables = (reason) => {
    if (reason === "backdropClick") return;
    setSubject([""]);
    setEnvelopFrom("");
    setDisplayFrom("");
    setValueDate(Moment());
    setContentText("");
    setMimeType(0);
    setTemplateBody("");
    setHtmlPreview("");
    setSubjectPreview("");
    closeModal();
  };

  const handlePreview = () => {
    setIsLoading(true);
    const options = {
      method: "POST",
      url: `emailCopy/Preview`,
      headers: {
        "content-type": "application/json",
      },
    };

    const unlayer = emailEditorRef.current?.editor;
    unlayer?.exportHtml((data) => {
      const html = data.html;
      console.log(data.chunks.body);
      options.data = JSON.stringify({
        subject: subject,
        body: html,
      });

      client
        .request(options)
        .then((response) => {
          setHtmlPreview(response.body);
          setSubjectPreview(response.subject);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    });
  };

  const handleSave = () => {
    if (simpleValidator.current.allValid()) {
      setIsLoading(true);
      const options = {
        method: "POST",
        url: `emailCopy`,
        headers: {
          "content-type": "application/json",
        },
      };
      const unlayer = emailEditorRef.current?.editor;

      unlayer?.exportHtml((data) => {
        const html = data.html;
        const design = data.design;

        options.data = JSON.stringify({
          emc_id: 0,
          emc_user_id: 0,
          emc_mimeType: mimeType,
          emc_contentText: contentText,
          emc_displayFrom: displayFrom,
          emc_envelopFrom: envelopFrom,
          emc_subject: subject[0],
          emc_subjects: subject,
          emc_contentHTML: html,
          emc_json: JSON.stringify(design),
          emc_status: includeInSecuence ? 1 : 0,
          emc_bla_id: 0,
          emc_creationDate: new Date(),
          emc_ser_id: props.serId,
          emc_type: props.serId,
          emc_expireDate: expire ? valueDate : null,
          emc_addArticlesSuggestion: addArticlesSuggestion,
          emc_sep_id: senderProfile,
          emc_trackExternalLinkClicks: isTracking,
        });

        client
          .request(options)
          .then((response) => {
            setIsLoading(false);
            props.onDone(response);
            clearVariables();
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
          });
      });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  const handleInsertVariables = (variable) => {
    editorRef.current.insertContent(variable);
  };

  const handleChangeSubject = (subject) => {
    setSubject(subject);
    setSelectedSubject(subject[0]);
  };
  const handleChangeDisplayFrom = (event) => {
    setDisplayFrom(event.target.value);
  };
  const handleChangeEnvelopFrom = (event) => {
    setEnvelopFrom(event.target.value);
  };
  const handleMimeType = (event) => {
    setMimeType(event.target.value);
  };

  const handleChangeText = (event) => {
    setContentText(event.target.value);
  };

  const handleIncludeInSecuence = () => {
    setIncludeInSecuence(!includeInSecuence);
  };
  const handleExpire = () => {
    setExpire(!expire);
  };
  const handleAddArticlesSuggestion = () => {
    setAddArticlesSuggestion(!addArticlesSuggestion);
  };
  const handleSendTest = (event) => {
    setTestEmail(event.target.value);
  };

  const onReady = (unlayer) => {
    unlayer.registerCallback("previewHtml", async function (params, done) {
      const options = {
        method: "POST",
        url: `emailCopy/Preview`,
        headers: {
          "content-type": "application/json",
        },
      };
      options.data = JSON.stringify({
        subject: subject[0],
        body: params.html,
      });

      client
        .request(options)
        .then((response) => {
          setSubjectPreview(response.subject);
          done({
            html: response.body,
          });
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    });
    unlayer.setMergeTags({
      title: {
        name: "Title",
        value: "[title]",
        sample: "[title]",
      },
      currentDate: { name: "Current date", value: "[current-date]", sample: "[current-date]" },
      broadcastId: { name: "Broadcast ID", value: "[broadcast-id]", sample: "[broadcast-id]" },
      email: { name: "Email", value: "[email]", sample: "[email]" },
      phone: { name: "Phone", value: "[phone]", sample: "[phone]" },
      firstName: { name: "First Name", value: "[first]", sample: "[first]" },
      lastName: { name: "Last Name", value: "[last]", sample: "[last]" },
      city: { name: "City", value: "[city]", sample: "[city]" },
      state: { name: "State", value: "[state]", sample: "[state]" },
      country: { name: "Country", value: "[country]", sample: "[country]" },
      optinDate: { name: "Optin Date", value: "[optin-date]", sample: "[optin-date]" },
      contactUsUrl: {
        name: "Contact Us URL",
        value: "[contact-us-url]",
        sample: "[contact-us-url]",
      },
      contactUsName: {
        name: "Contact Us Name",
        value: "[contact-us-name]",
        sample: "[contact-us-name]",
      },
      companyName: { name: "Company Name", value: "[company-name]", sample: "[company-name]" },
      companyAddress: {
        name: "Company Address",
        value: "[company-address]",
        sample: "[company-address]",
      },
      companyCountry: {
        name: "Company Country",
        value: "[company-country]",
        sample: "[company-country]",
      },
      unsubscribeUrl: {
        name: "Unsubscribe URL",
        value: "[unsubscribe-url]",
        sample: "[unsubscribe-url]",
      },
      unsubscribeParagraph: {
        name: "Unsubscribe Paragraph",
        value: "[unsubscribe-paragraph]",
        sample: "[unsubscribe-paragraph]",
      },
    });
  };

  const getCatalogs = async () => {
    const options = {
      method: "GET",
      url: `catalogs`,
      headers: {
        "content-type": "application/json",
      },
    };

    await client
      .request(options)
      .then((response) => {
        let catalog = [];
        response
          .filter((cat) => cat.cat_status == 0)
          .map((item) => {
            catalog.push({ label: item.cat_name, value: item.cat_id });
          });
        setCatalogs(catalog);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sendTest = () => {
    if (simpleValidator.current.allValid()) {
      setIsLoading(true);
      const options = {
        method: "POST",
        url: `broadcasts/Send`,
        headers: {
          "content-type": "application/json",
        },
      };

      const unlayer = emailEditorRef.current?.editor;

      unlayer?.exportHtml((data) => {
        const html = data.html;
        const design = data.design;

        options.data = JSON.stringify({
          bro_id: 0,
          bro_emc_id: 0,
          name: "",
          displayfrom: displayFrom,
          fromaddress: envelopFrom,
          subjects: selectedSubject.split("|"),
          html: html,
          pauseStandard: false,
          date: valueDate,
          bro_segments: null,
          recipient: testEmail,
          trigger_events: null,
        });

        client
          .request(options)
          .then((response) => {
            setOpenTestSB(true);
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
          });
      });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      setErrorTitle("Warning");
      setErrorContent("Please make you sure that you has completed all fields");
      setOpenErrorSB(true);
    }
  };

  const getSenderProfiles = async () => {
    setIsLoading(true);
    const options = {
      method: "GET",
      url: `senderProfiles`,
      headers: {
        "content-type": "application/json",
      },
    };
    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        setArrSenderProfiles(response);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    getCatalogs();
    getSenderProfiles();
  }, []);

  useEffect(() => {
    if (senderProfile >= 0) {
      const options = {
        method: "GET",
        url: `senderProfiles`,
        headers: {
          "content-type": "application/json",
        },
      };

      options.method = "GET";
      options.url = `senderProfiles/tracking?sep_id=${senderProfile}`;
      client
        .request(options)
        .then((response) => {
          setShowTracking(response);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    }
  }, [senderProfile]);

  return (
    <Dialog
      style={{ marginLeft: margin }}
      open={openModal}
      onClose={(e, reason) => clearVariables(reason)}
      fullScreen
      disableEscapeKeyDown
      sx={{
        //You can copy the code below in your theme
        background: theme.palette.background.default,
        "& .MuiPaper-root": {
          background: theme.palette.background.default,
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent", // Try to remove this to see the result
        },
      }}
    >
      <MDBox
        variant="gradient"
        bgColor="primary"
        borderRadius="lg"
        coloredShadow="primary"
        mx={2}
        mt={-1}
        p={2}
        mb={1}
        textAlign="center"
      >
        <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
          Create a new email copy for "{props.serName}" serie
        </MDTypography>
      </MDBox>
      <DialogContent>
        <MDBox pb={3} px={2}>
          <MDBox component="form" role="form">
            {false && (
              <>
                <MDBox display="flex" justifyContent="left">
                  <Switch checked={includeInSecuence} onChange={handleIncludeInSecuence} />
                  <MDTypography variant="h6" fontWeight="medium" mt={1}>
                    include in sending sequence
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" justifyContent="left">
                  <Switch checked={addArticlesSuggestion} onChange={handleAddArticlesSuggestion} />
                  <MDTypography variant="h6" fontWeight="medium" mt={1}>
                    add articles suggestions
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" justifyContent="left">
                  <Switch checked={expire} onChange={handleExpire} />
                  <MDTypography variant="h6" fontWeight="medium" mt={1}>
                    Expire
                  </MDTypography>
                  {expire && (
                    <MDBox mt={1} ml={3}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DateTimePicker
                          required
                          renderInput={(props) => <TextField {...props} />}
                          label="Select the expiration date "
                          value={valueDate}
                          onChange={(newValue) => {
                            setValueDate(newValue);
                          }}
                        />
                      </LocalizationProvider>
                    </MDBox>
                  )}
                </MDBox>
                <MDBox mt={2} mb={2}>
                  <NativeSelect id="cmbMime" onChange={handleMimeType} value={mimeType}>
                    <option value="0">HTML</option>
                    <option value="1">Text</option>
                    <option value="2">Text & HTML</option>
                  </NativeSelect>
                </MDBox>
              </>
            )}

            <Card style={{ padding: 10, backgroundColor: "white", marginBottom: 10 }}>
              <MDBox mb={2}>
                <Card xs={12} style={{ padding: 10, backgroundColor: "#FFFFFF", marginBottom: 10 }}>
                  <CardHeader title="Subjects" />
                  <MDBox mb={2} display="flex">
                    <MSMultipleSubjects
                      subjects={subject}
                      setSubjects={(value) => handleChangeSubject(value)}
                    />

                    <MDBox ml={2}>
                      <MSPersonalizationTags />
                    </MDBox>
                  </MDBox>
                  <MDBox color="red">
                    {simpleValidator.current.message("Subject", subject, "required")}
                  </MDBox>
                </Card>
              </MDBox>
              {arrSenderProfiles.length > 0 && (
                <MDBox mb={2}>
                  <Select
                    placeholder="Select sender profile"
                    value={senderProfile}
                    onChange={(e) => {
                      setSenderProfile(e.target.value);
                    }}
                  >
                    <MenuItem key={-1} value={-1}>
                      <ListItemText primary={"Select a sender profile"} />
                    </MenuItem>
                    {arrSenderProfiles.map((item) => (
                      <MenuItem key={item.sep_id} value={item.sep_id}>
                        <ListItemText
                          primary={"From:" + item.sep_envelopFrom}
                          secondary={"Reply to:" + item.sep_replyTo}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                  <MDBox color="red">
                    {simpleValidator.current.message("Sender Profile", senderProfile, "sender")}
                  </MDBox>
                </MDBox>
              )}
              {showTRacking && (
                <MDTypography variant="h5" fontWeight="medium" mt={1}>
                  Tracking events <Checkbox onChange={() => setIsTracking(!isTracking)} />
                </MDTypography>
              )}
              {false && (
                <>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Display From example: Blog news"
                      fullWidth
                      value={displayFrom}
                      onChange={handleChangeDisplayFrom}
                      required
                    />
                    <MDBox color="red">
                      {simpleValidator.current.message("Name", displayFrom, "required")}
                    </MDBox>
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Envelop From"
                      fullWidth
                      value={envelopFrom}
                      onChange={handleChangeEnvelopFrom}
                      required
                    />
                    <MDBox color="red">
                      {simpleValidator.current.message("Name", envelopFrom, "required")}
                    </MDBox>
                  </MDBox>
                </>
              )}
            </Card>
            {(mimeType == 1 || mimeType == 2) && (
              <MDBox>
                <MDTypography variant="h6" fontWeight="medium" mt={1}>
                  Text content
                </MDTypography>
                <MDBox mb={2}>
                  <TextField
                    variant="outlined"
                    placeholder="Text content"
                    value={contentText}
                    onChange={handleChangeText}
                    multiline
                    rows={10}
                    fullWidth
                  />
                </MDBox>
              </MDBox>
            )}

            {(mimeType == 0 || mimeType == 2) && (
              <Card style={{ padding: 10, backgroundColor: "white", marginBottom: 10 }}>
                <Grid container spacing={2}>
                  <Grid item mb={2} xs={10}>
                    <MDTypography variant="h6" fontWeight="medium" mt={1}>
                      HTML content
                    </MDTypography>
                  </Grid>
                  <Grid item xs={2} style={{ width: "100%", textAlign: "right" }}>
                    <MSPersonalizationTags />
                  </Grid>
                  <Grid item xs={12}>
                    <EmailEditor
                      ref={emailEditorRef}
                      onReady={onReady}
                      projectId={localStorage.getItem("unlayerID")}
                      minHeight={600}
                      appearance={{
                        panels: {
                          tools: {
                            dock: "left",
                            tabs: {
                              body: {
                                visible: true,
                              },
                            },
                          },
                        },
                        features: {
                          preview: true,
                        },
                      }}
                      tabs={{
                        image: {
                          enabled: false,
                        },
                      }}
                      options={{
                        customJS: [
                          window.location.protocol + "//" + window.location.host + "/customUrl.js",
                          !features.hiddenMenus.includes("catalogs")
                            ? window.location.protocol +
                              "//" +
                              window.location.host +
                              "/customTool.js"
                            : "",
                        ],
                        editor: {
                          autoSelectOnDrop: true,
                        },
                        tools: {
                          // my_tool is the name of our custom tool
                          // It is required to add custom# before the name of your custom tool
                          "custom#variables_tool": {
                            properties: {
                              catalog: {
                                editor: {
                                  data: {
                                    catalogs,
                                    token,
                                    domain: process.env.REACT_APP_MAIN_DOMAIN,
                                  },
                                },
                              },
                            },
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Card>
            )}

            {htmlPreview && (
              <Card style={{ padding: 10, backgroundColor: "white", marginBottom: 10 }}>
                <Grid item m={2}>
                  <MDButton variant="gradient" color="info" mr={2} onClick={() => handlePreview()}>
                    Preview
                  </MDButton>
                </Grid>
                <Grid item mb={2}>
                  <MDTypography variant="h6" fontWeight="medium" mt={1}>
                    Subject
                  </MDTypography>
                  <iframe
                    srcDoc={subjectPreview}
                    height="100"
                    width="100%"
                    allowFullScreen
                    style={{ border: "none" }}
                  />
                </Grid>
                <Grid item mb={2}>
                  <MDTypography variant="h6" fontWeight="medium" mt={1}>
                    Body
                  </MDTypography>
                  <iframe
                    srcDoc={htmlPreview}
                    height="600"
                    width="100%"
                    allowFullScreen
                    style={{ border: "none" }}
                  />
                </Grid>
              </Card>
            )}
          </MDBox>
          <Card style={{ padding: 10, backgroundColor: "white", marginBottom: 10 }}>
            <Grid>
              <MDInput
                onChange={(event) => handleSendTest(event)}
                label="e-mails to test"
                style={{ marginBottom: 10, width: "100%" }}
              />
              {subject && (
                <MDBox display="flex">
                  <MDTypography>Select a subject line </MDTypography>
                  <Select
                    title="Select a subject line"
                    value={selectedSubject}
                    onChange={(e) => {
                      setSelectedSubject(e.target.value);
                    }}
                    style={{ marginLeft: 10 }}
                  >
                    {subject.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        <ListItemText primary={item} />
                      </MenuItem>
                    ))}
                  </Select>
                  <MDButton
                    style={{ marginLeft: 10 }}
                    variant="gradient"
                    color="success"
                    onClick={sendTest}
                    disabled={isLoading}
                  >
                    Send
                  </MDButton>
                </MDBox>
              )}
            </Grid>
          </Card>
        </MDBox>
      </DialogContent>
      <DialogActions style={{ color: "white", backgroundColor: "white" }}>
        <MDButton
          onClick={clearVariables}
          disabled={isLoading}
          variant="outlined"
          color="secondary"
        >
          Cancel
        </MDButton>
        <MDButton onClick={handleSave} disabled={isLoading} variant="gradient" color="success">
          Save
        </MDButton>
        {isLoading && (
          <CircularProgress size={24} style={{ marginLeft: 15, position: "relative", top: 4 }} />
        )}
      </DialogActions>

      <MDSnackbar
        color="success"
        icon="success"
        title="Success"
        content="Your test email has been sent successfully"
        dateTime={""}
        open={openTestSB}
        onClose={closeTestSB}
        close={closeTestSB}
        bgWhite
      />
    </Dialog>
  );
}

export default AddEmailCopy;
