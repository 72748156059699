import { Chip, Divider, Icon, withStyles, Tooltip } from "@material-ui/core";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useState } from "react";
import { Handle, Position } from "reactflow";

/*eslint-disable*/
export default function ConditionNode(node) {
  const onClick = (event, type) => {
    node.data.onClickAdd(event, node, type);
  };

  const StyleChip = withStyles({
    root: {
      backgroundColor: "gray",
      borderColor: "white",
      "& .MuiChip-label": {
        fontSize: 8,
        color: "white",
      },
    },
  })(Chip);

  const getIcon = (type) => {
    switch (type) {
      case "AUTOMATION_STEP_TYPE_CHECK_TAG":
        return (
          <Icon fontSize="small" mt={3}>
            sell
          </Icon>
        );
      default:
        return "error";
    }
  };

  const getAction = (type) => {
    switch (type) {
      case "AUTOMATION_STEP_TYPE_CHECK_TAG":
        return "Contains the following(s) tag(s)";

      default:
        return "error";
    }
  };

  const getData = (type) => {
    switch (type) {
      case "AUTOMATION_STEP_TYPE_CHECK_TAG":
        return (
          <MDBox>
            {node.data.aus_tag.split(",").map((tag) => {
              return <StyleChip variant="outlined" label={tag.trim()} size="small" />;
            })}
          </MDBox>
        );
      default:
        return "";
    }
  };

  return (
    <div
      style={{
        height: "100px",
        border: "1px solid #eee",
        padding: "5px",
        borderRadius: "5px",
        background: node.selected ? "#CCCCCC" : "#96FF9D22",
      }}
    >
      <Handle type="target" position={Position.Top} />

      <MDBox display="flex">
        {getIcon(node.data.aus_type)}
        <MDBox display="flex" style={{ width: "100%" }}>
          <MDTypography variant="caption" fontWeight="medium" ml={1} style={{ width: "100px" }}>
            {getAction(node.data.aus_type)}
          </MDTypography>
        </MDBox>
        <MDBox display="flex" justifyContent="right" style={{ width: "100%" }}>
          {node.selected && (
            <Tooltip title="delete action" placement="top">
              <Icon
                color="action"
                style={{ fontSize: "15px" }}
                onClick={(e) => node.data.onDelete(e, node)}
              >
                delete
              </Icon>
            </Tooltip>
          )}
        </MDBox>
      </MDBox>
      <Divider />
      {getData(node.data.aus_type)}
      <Tooltip title="Yes" placement="top">
        <Handle
          type="source"
          position={Position.Bottom}
          style={{
            marginLeft: -50,
            top: "auto",
            backgroundColor: "#22FF22",
          }}
          id="yes"
        >
          {node.selected && (
            <Icon
              color="action"
              fontSize="small"
              onClick={(e) => onClick(e, "yes")}
              style={{ marginLeft: -10 }}
            >
              add_circle
            </Icon>
          )}
        </Handle>
      </Tooltip>
      <Tooltip title="No" placement="top" fontSize="50">
        <Handle
          type="source"
          position={Position.Bottom}
          style={{ marginLeft: 50, top: "auto", backgroundColor: "#FF2222" }}
          id="no"
        >
          {node.selected && (
            <Icon
              color="action"
              fontSize="small"
              onClick={(e) => onClick(e, "no")}
              style={{ marginLeft: -5 }}
            >
              add_circle
            </Icon>
          )}
        </Handle>
      </Tooltip>
    </div>
  );
}

/*

<NodeToolbar isVisible={true} position="right">
        <button>delete</button>
        <button>copy</button>
        <button>expand</button>
      </NodeToolbar>
      
 <FormControl className={classes.formControl}>
        <InputLabel>pick the tags to use</InputLabel>
        {suggestionsTag.length > 0 && (
          <Select
            placeholder="Select the tags to use"
            multiple
            value={tags}
            onChange={handleChangeTags}
            input={<Input />}
            renderValue={(selected) => selected.join(",")}
            MenuProps={MenuProps}
          >
            {suggestionsTag.map((tag) => (
              <MenuItem key={tag.id} value={tag.name}>
                <Checkbox checked={tags.indexOf(tag.name) > -1} />
                <ListItemText primary={tag.name} />
              </MenuItem>
            ))}
          </Select>
        )}
      </FormControl>
      */
