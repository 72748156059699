// https://dailyfix.org

import client from "ApiClient";
import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import MDBox from "./MDBox";
import MDTypography from "./MDTypography";

/* eslint-disable */
export default function EmailIntros(props) {
  const [isLoading, setIsLoading] = useState(false);
  const { openModal, closeModal } = props;
  const [articleData, setArticleData] = useState([]);
  const [selectedIntro, setSelectedIntro] = useState();
  const [intro, setIntro] = useState("");
  const [preheader, setPreheader] = useState("");

  const handleChangeIntro = (event) => {
    setIntro(event.target.value);
  };

  const handleChangePreheader = (event) => {
    setPreheader(event.target.value);
  };

  const handleChangePersonality = (event) => {
    const index = articleData.map((item) => item.email_intro.emi_id).indexOf(event.target.value);
    setSelectedIntro(event.target.value);
    setIntro(articleData[index].email_intro.emi_html);
    setPreheader(articleData[index].email_intro.emi_preheader);
  };

  useEffect(() => {
    if (props.blaId > 0) getData();
  }, [props.blaId]);

  const options = {
    method: "GET",
    url: "emailIntros",
    headers: {
      "content-type": "application/json",
    },
  };

  const getData = async () => {
    options.method = "GET";
    options.url = `emailIntros?bla_id=${props.blaId}`;
    await client
      .request(options)
      .then((response) => {
        setArticleData(response);
        console.log(response[0].email_intro.emi_id);
        setSelectedIntro(response[0].email_intro.emi_id);
        setIntro(response[0].email_intro.emi_html);
        setPreheader(response[0].email_intro.emi_preheader);
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.log(error);
      });
  };

  const savePost = async () => {
    setIsLoading(true);
    const options = {
      method: "PUT",
      url: `emailIntros/updateEmi`,
      headers: {
        "content-type": "application/json",
      },
    };
    options.data = JSON.stringify({
      emi_id: selectedIntro,
      emi_preheader: preheader,
      emi_html: intro,
    });

    // console.log(options.data);
    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        // closeModal();
      })
      .catch((error) => {
        setIsLoading(false);
        // eslint-disable-next-line
        console.log(error);
      });
  };

  return (
    <div>
      <Dialog open={openModal} onClose={closeModal}>
        <Card>
          <MDBox pt={4} pb={3} px={2}>
            <DialogContent>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={0}
                mt={0}
                p={2}
                mb={4}
                textAlign="center"
              >
                <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
                  Email Intros for "{props.article}"
                </MDTypography>
              </MDBox>
              {selectedIntro != null && (
                <MDBox component="form" role="form">
                  <MDBox mb={2} display="flex" justifyContent="left">
                    <MDTypography variant="h6" fontWeight="medium" ml={1} mr={2}>
                      What is the personality?
                    </MDTypography>
                    <Select
                      disabled={isLoading}
                      onChange={handleChangePersonality}
                      value={selectedIntro}
                      label="Pick a personality to edit the email intro"
                    >
                      {articleData.map((item) => (
                        <MenuItem key={item.email_intro.emi_id} value={item.email_intro.emi_id}>
                          {item.personality.per_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </MDBox>
                  <MDBox m={2}>
                    <TextField
                      type="text"
                      label="Email preheader"
                      multiline
                      fullWidth
                      value={preheader}
                      onChange={handleChangePreheader}
                    />
                  </MDBox>
                  <MDBox m={2}>
                    <TextField
                      type="text"
                      label="Email intro paragraph"
                      multiline
                      fullWidth
                      value={intro}
                      onChange={handleChangeIntro}
                    />
                  </MDBox>
                </MDBox>
              )}
            </DialogContent>
            <DialogActions style={{ color: "white", backgroundColor: "white" }}>
              <Button autoFocus onClick={closeModal} disabled={isLoading}>
                Close
              </Button>

              <Button onClick={savePost} disabled={isLoading} autoFocus>
                Save
              </Button>
              {isLoading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 15, position: "relative", top: 4 }}
                />
              )}
            </DialogActions>
          </MDBox>
        </Card>
      </Dialog>
    </div>
  );
}
