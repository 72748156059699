import { useEffect, useRef, useState } from "react";
// react-router-dom components
import Card from "@mui/material/Card";
import theme from "assets/theme";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import client from "ApiClient";
import SimpleReactValidator from "simple-react-validator";
import { CircularProgress, Dialog, DialogActions, DialogContent } from "@mui/material";
import MDButton from "components/MDButton";
import Segments from "components/Segments";
import { useMaterialUIController } from "context";

/* eslint-disable */
function Add(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [segments, setSegments] = useState(null);
  const { openModal, closeModal } = props;
  const closeErrorSB = () => setErrorSB(false);
  const [, forceUpdate] = useState();
  const simpleValidator = useRef(new SimpleReactValidator());

  const [margin, setMargin] = useState(300);
  const [controller] = useMaterialUIController();
  const { miniSidenav } = controller;

  useEffect(() => {
    if (miniSidenav) setMargin(0);
    else setMargin(300);
  }, [miniSidenav]);

  const clearVariables = (reason) => {
    if (reason === "backdropClick") return;
    setName("");
    setDescription("");
    setSegments(null);
    simpleValidator.current.hideMessages();
    closeModal();
  };

  const handleSave = () => {
    if (simpleValidator.current.allValid()) {
      setIsLoading(true);
      const options = {
        method: "POST",
        url: `segments`,
        headers: {
          "content-type": "application/json",
        },
      };

      options.data = JSON.stringify({
        seg_name: name,
        seg_description: description,
        seg_criteria: JSON.stringify(segments),
      });

      client
        .request(options)
        .then((response) => {
          setIsLoading(false);
          props.onDone();
          clearVariables();
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    } else {
      setIsLoading(false);
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <Dialog
      open={openModal}
      onClose={(e, reason) => clearVariables(reason)}
      fullScreen
      disableEscapeKeyDown
      style={{ marginLeft: margin }}
      sx={{
        //You can copy the code below in your theme
        background: "#F4F0F700",
        "& .MuiPaper-root": {
          background: theme.palette.background.default,
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent", // Try to remove this to see the result
        },
      }}
    >
      <MDBox
        variant="gradient"
        bgColor="primary"
        borderRadius="lg"
        coloredShadow="primary"
        mx={2}
        mt={-1}
        p={2}
        mb={1}
        textAlign="center"
      >
        <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
          Create a new segment
        </MDTypography>
      </MDBox>
      <DialogContent>
        <MDBox pt={4} pb={3} px={2}>
          <Card style={{ padding: 10, backgroundColor: "white", marginBottom: 10 }}>
            <MDBox pt={2} pb={2}>
              <MDInput
                type="text"
                label="Type a friendly name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </MDBox>
            <MDBox pt={2} pb={2}>
              <MDInput
                type="text"
                label="Type a description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </MDBox>
            <MDBox pt={2} pb={2}>
              <Segments
                changeSegments={(value) => setSegments(value)}
                filterSegments={null}
                showRecipientsCount={true}
                isOpen={true}
                segments={null}
              />
            </MDBox>
          </Card>
        </MDBox>
      </DialogContent>
      <DialogActions style={{ color: "white", backgroundColor: "white" }}>
        <MDButton
          onClick={clearVariables}
          disabled={isLoading}
          variant="outlined"
          color="secondary"
        >
          Cancel
        </MDButton>
        <MDButton onClick={handleSave} disabled={isLoading} variant="gradient" color="success">
          Save
          {isLoading && (
            <CircularProgress size={24} style={{ marginLeft: 15, position: "relative", top: 4 }} />
          )}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
export default Add;
