import {
  Select,
  Card,
  CardHeader,
  Chip,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  TextField,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { FeatureFlags } from "context/FeatureFlags";
import client from "ApiClient";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import Segments from "components/Segments";
import validator from "validator";
import MUIDataTable from "mui-datatables";
import { useContext, useEffect, useState } from "react";
import CustomToolbarTrigger from "./customToolbarTrigger";

/* eslint-disable */
export default function Triggers(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [showAddButton, setShowAddButton] = useState(
    props.addButton != null ? props.addButton : true
  );
  const [addTrigger, setAddTrigger] = useState(false);
  const [selectedURLMethod, setSelectedURLMethod] = useState(0);
  const [triggerTargetURL, setTriggerTargetURL] = useState("");
  const [triggerBody, setTriggerBody] = useState("");
  const [selectedTriggerEvent, setSelectedTriggerEvent] = useState(1);
  const [selectedTriggerAction, setSelectedTriggerAction] = useState(3);
  const [triggersData, setTriggersData] = useState(props.triggers != null ? props.triggers : []);
  const [search, setSearch] = useState("");
  const [series, setSeries] = useState(props.series != null ? props.series : []);
  const [selectedSeries, setSelectedSerie] = useState("");

  const [segments, setSegments] = useState(null);
  const [segmentsTrigger, setSegmentsTrigger] = useState(null);

  // const [ItemsData, SetItemsData] = useState([]);
  const [originalItems, setOriginalItems] = useState([]);
  const [ItemsFilteredData, SetItemsFilteredsData] = useState([]);
  const [selectedItem, SetSelectedItem] = useState(0);
  const [selectedItemName, SetSelectedItemName] = useState("Any");
  const [selectItem, setSelectItem] = useState(false);

  const [CatalogsData, SetCatalogsData] = useState([]);
  const [selectedCatalog, SetSelectedCatalog] = useState(0);

  const [broadcastData, SetBroadcastData] = useState([]);
  const [BroadcastFilteredData, SetBroadcastFilteredData] = useState([]);
  const [selectBroadcast, setSelectBroadcast] = useState(false);
  const [selectedBroadcast, SetSelectedBroadcast] = useState(0);
  const [tags, setTags] = useState([]);
  const [suggestionsTag, setSuggestionsTag] = useState([]);
  const [urlLink, setUrlLink] = useState("");
  const [validationURL, setValidationURL] = useState("");
  const { features } = useContext(FeatureFlags);
  let triggersSelected = [];
  const filter = createFilterOptions();

  const [triggerEvent] = useState([
    { event: "CLICKS THE FOLLOWING LINK", value: 12 },
    { event: "OPENS", value: 1 },
    { event: "UNSUBSCRIBES", value: 2 },
    { event: "BOUNCES", value: 3 },
    { event: "COMPLAINTS", value: 4 },
    { event: "REACHES THE NO ENGAGEMENT LIMIT", value: 30 },
  ]);

  const [triggerActions] = useState([
    { action: "CALL URL", value: 3 },
    { action: "SEND BROADCAST", value: 4 },
    { action: "ADD A TAG", value: 5 },
    { action: "REMOVE A TAG", value: 7 },
  ]);

  const [triggerMethod] = useState([
    { method: "GET", value: 0 },
    { method: "POST", value: 1 },
    { method: "PUT", value: 2 },
    { method: "DELETE", value: 3 },
  ]);

  if (!features.hiddenMenus.includes("series")) {
    if (triggerEvent.length == 0) {
      triggerEvent.push({ event: "CLICKS ON THE FOLLOWING ITEM(s)", value: 0 });
      triggerEvent.push({ event: "FINISHES THIS SERIE", value: 40 });
    }
    if (triggerActions.length == 0) {
      triggerActions.push({ action: "COPY TO SERIE", value: 0 });
      triggerActions.push({ action: "MOVE TO SERIE", value: 1 });
      triggerActions.push({ action: "REMOVE FROM SERIE", value: 2 });
    }
  }

  const options = {
    method: "GET",
    url: "series",
  };

  const getTriggers = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `triggers`;
    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        if (JSON.stringify(response) !== "[{}]") setTriggersData(response);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  /*
  const getAds = async () => {
    const options = {
      method: "GET",
      url: `ads`,
      headers: {
        "content-type": "application/json",
      },
    };

    await client
      .request(options)
      .then((response) => {
        SetAdsData(response);
        SetAdsFilteredData(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getArticles = async () => {
    const options = {
      method: "GET",
      url: `blogArticles/GetArticles`,
      headers: {
        "content-type": "application/json",
      },
    };

    await client
      .request(options)
      .then((response) => {
        SetArticlesData(response);
        SetArticleFilteredsData(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  */

  const getItems = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `items`;
    options.params = {
      cat_id: selectedCatalog,
    };

    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        setOriginalItems(response);
        if (search != "")
          SetItemsFilteredsData(
            response.filter((item) => item.item_name.toLowerCase().includes(search.toLowerCase()))
          );
        else SetItemsFilteredsData(response);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getCatalogs = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `catalogs`;

    await client
      .request(options)
      .then((response) => {
        SetCatalogsData(response);
        if (response.length > 0) SetSelectedCatalog(response[0].cat_id);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getBroadcasts = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `broadcasts`;

    await client
      .request(options)
      .then((response) => {
        SetBroadcastData(response);
        SetBroadcastFilteredData(response);
        if (response.length > 0) SetSelectedBroadcast(response[0].bro_id);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getTags = async () => {
    const options = {
      method: "GET",
      url: `broadcasts/getTags`,
      headers: {
        "content-type": "application/json",
      },
    };

    await client
      .request(options)
      .then((response) => {
        setSuggestionsTag([]);
        response.map((tag, index) => {
          setSuggestionsTag((prev) => [...prev, { id: index, name: tag }]);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleFilterItem = (e) => {
    const filterInput = e.target.value;
    setSearch(filterInput);
    if (filterInput == "") SetItemsFilteredsData(originalItems);
    else
      SetItemsFilteredsData(
        items.filter((item) => item.item_name.toLowerCase().includes(filterInput.toLowerCase()))
      );
  };

  const getActionsDetails = () => {
    switch (selectedTriggerAction) {
      case 0:
        return selectedSeries.toString();
      case 1:
        return selectedSeries.toString();
      case 2:
        return selectedSeries.toString();
      case 3:
        return JSON.stringify({
          tri_targetURL: triggerTargetURL,
          tri_body: triggerBody,
          tri_method: selectedURLMethod,
        });
      case 4:
        return selectedBroadcast.toString();
      case 5:
        return tags.join(",");
      case 7:
        return tags.join(",");
    }
  };

  const getEventDetails = () => {
    console.log(selectedTriggerEvent);
    switch (selectedTriggerEvent) {
      case 0:
        return selectedItem.toString();
      case 12:
        console.log(urlLink);
        return urlLink;
      default:
        return "";
    }
  };

  const addTriggerHandler = () => {
    setTriggersData((prev) => [
      ...prev,
      {
        tre_id: 0,
        tre_user_id: 0,
        tre_ser_id: 0,
        tre_emc_id: 0,
        tre_bro_id: 0,
        tre_segments: JSON.stringify(segmentsTrigger),
        tre_event: selectedTriggerEvent,
        tre_eventDetails: getEventDetails(),
        tre_action: selectedTriggerAction,
        tre_actionDetails: getActionsDetails(),
        tre_status: 0,
      },
    ]);
    setAddTrigger(false);
    setSelectedTriggerEvent(0);
    setSelectedTriggerAction(0);
    setSelectedSerie(0);
    setTags([]);
    setSelectedURLMethod(0);
    setTriggerTargetURL("");
    setUrlLink("");
  };

  const handleDeleteTriggers = () => {
    triggersSelected.map((trs) => {
      setTriggersData((current) => current.filter((trigger) => trigger != trs));
    });
  };

  useEffect(() => {
    props.changeTriggers(triggersData);
    // getSubscribersSegments();
  }, [triggersData]);

  useEffect(() => {
    getItems();
  }, [selectedCatalog]);

  useEffect(() => {
    getCatalogs();
    if (props.triggers != null && props.triggers.length > 0) setOpen(true);
  }, []);

  useEffect(() => {
    // console.log(props.showButton);
    setShowButton(props.showButton);
    if (!props.showButton) setOpen(true);
  }, [props]);

  useEffect(() => {
    if (urlLink !== "" && !validator.isURL(urlLink)) setValidationURL("Please enter a valid URL");
    else setValidationURL("");
  }, [urlLink]);

  const getSeries = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `series`;
    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        if (JSON.stringify(response) !== "[{}]") setSeries(response);
        if (response.length > 0) setSelectedSerie(response[0].ser_id);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const handleChangeTriggerEvent = (event) => {
    setSelectedTriggerEvent(event.target.value);
  };
  const handleChangeTriggerAction = (event) => {
    setSelectedTriggerAction(event.target.value);
  };
  const handleChangeSeries = (event) => {
    setSelectedSerie(event.target.value);
  };
  const onChangeTriggerBody = (event) => {
    setTriggerBody(event.target.value);
  };
  const onChangeTriggerTargetURL = (event) => {
    setTriggerTargetURL(event.target.value);
  };
  const onChangeSegment = (value) => {
    setSegments(value);
  };

  const onChangeCatalog = (event) => {
    SetSelectedCatalog(event.target.value);
  };

  const onChangeSegmentTrigger = (value) => {
    setSegmentsTrigger(value);
  };
  const handleItemsClick = (event, id, name) => {
    SetSelectedItem(id);
    SetSelectedItemName(name);
  };

  const getEvent = (tri) => {
    switch (tri.tre_event) {
      case 0:
        return (
          <div>
            <MDTypography variant="body" fontWeight="medium">
              CLICKS ON THE FOLLOWING ITEM(s)
            </MDTypography>
            <br></br>
            <MDTypography variant="body">
              {tri.tre_eventDetails == 0
                ? "Any "
                : ItemsFilteredData.filter((item) => item.item_id == tri.tre_eventDetails)[0]
                    .item_name}
            </MDTypography>
          </div>
        );
      case 1:
        return (
          <MDTypography variant="body" fontWeight="medium">
            OPENS
          </MDTypography>
        );
      case 2:
        return (
          <MDTypography variant="body" fontWeight="medium">
            UNSUBSCRIBES
          </MDTypography>
        );
      case 3:
        return (
          <MDTypography variant="body" fontWeight="medium">
            BOUNCES
          </MDTypography>
        );
      case 4:
        return (
          <MDTypography variant="body" fontWeight="medium">
            COMPLAINTS
          </MDTypography>
        );
      case 12:
        return (
          <div>
            <MDTypography variant="body" fontWeight="medium">
              CLICKS ON THE FOLLOWING URL LINK
            </MDTypography>
            <br></br>
            <MDTypography variant="body">{tri.tre_eventDetails}</MDTypography>
          </div>
        );
      case 30:
        return (
          <MDTypography variant="body" fontWeight="medium">
            REACHES THE NO ENGAGEMENT LIMIT
          </MDTypography>
        );
      case 40:
        return (
          <MDTypography variant="body" fontWeight="medium">
            FINISHES THIS SERIE
          </MDTypography>
        );
    }
  };

  const getEventDetail = (tri) => {
    switch (tri.tre_action) {
      case 0:
        return (
          <div>
            <MDTypography variant="body" fontWeight="medium">
              COPY TO SERIE:{" "}
            </MDTypography>
            <MDTypography variant="body">
              {series.filter((ser) => ser.ser_id == tri.tre_actionDetails)[0].ser_name}{" "}
            </MDTypography>
          </div>
        );
      case 1:
        return (
          <div>
            <MDTypography variant="body" fontWeight="medium">
              MOVE TO SERIE:{" "}
            </MDTypography>
            <MDTypography variant="body">
              {" "}
              {series.filter((ser) => ser.ser_id == tri.tre_actionDetails)[0].ser_name}{" "}
            </MDTypography>
          </div>
        );
      case 2:
        return (
          <div>
            <MDTypography variant="body" fontWeight="medium">
              REMOVE FROM SERIE:{" "}
            </MDTypography>
            <MDTypography variant="body">
              {" "}
              {series.filter((ser) => ser.ser_id == tri.tre_actionDetails)[0].ser_name}{" "}
            </MDTypography>
          </div>
        );
      case 3:
        return (
          <div>
            <MDTypography variant="body" fontWeight="medium">
              URL:{" "}
            </MDTypography>
            <MDTypography variant="body">
              {JSON.parse(tri.tre_actionDetails).tri_targetURL}{" "}
            </MDTypography>
            <br></br>
            <MDTypography variant="body" fontWeight="medium">
              METHOD:{" "}
            </MDTypography>
            <MDTypography variant="body">
              {
                triggerMethod.filter(
                  (met) => met.value == JSON.parse(tri.tre_actionDetails).tri_method
                )[0].method
              }
            </MDTypography>
            <br></br>
            <MDTypography variant="body" fontWeight="medium">
              BODY:{" "}
            </MDTypography>
            <MDTypography variant="body">{JSON.parse(tri.tre_actionDetails).tri_body}</MDTypography>
          </div>
        );
      case 4:
        return (
          <div>
            <MDTypography variant="body" fontWeight="medium">
              SEND BROADCAST:{" "}
            </MDTypography>
            <MDTypography variant="body">
              {" "}
              {broadcastData.filter((bro) => bro.bro_id == tri.tre_actionDetails)[0].bro_name}{" "}
            </MDTypography>
          </div>
        );
    }
  };

  const getAction = (value) => {
    switch (value) {
      case 0:
        return <MDTypography>COPY TO SERIE</MDTypography>;
      case 1:
        return <MDTypography>MOVE TO SERIE</MDTypography>;
      case 2:
        return <MDTypography>REMOVE FROM SERIE</MDTypography>;
      case 3:
        return <MDTypography>CALL URL</MDTypography>;
      case 4:
        return <MDTypography>SEND BROADCAST</MDTypography>;
      case 5:
        return <MDTypography>ADD A TAG</MDTypography>;
      case 7:
        return <MDTypography>REMOVE A TAG</MDTypography>;
    }
  };

  const getActionDetails = (tri) => {
    switch (tri.tre_action) {
      case 0:
        return (
          <div>
            <MDTypography variant="body" fontWeight="medium">
              COPY TO SERIE:{" "}
            </MDTypography>
            <MDTypography variant="body">
              {tri.tre_actionDetails > 0
                ? series.filter((ser) => ser.ser_id == tri.tre_actionDetails)[0].ser_name
                : "This serie"}
            </MDTypography>
          </div>
        );
      case 1:
        return (
          <div>
            <MDTypography variant="body" fontWeight="medium">
              MOVE TO SERIE:{" "}
            </MDTypography>
            <MDTypography variant="body">
              {tri.tre_actionDetails > 0
                ? series.filter((ser) => ser.ser_id == tri.tre_actionDetails)[0].ser_name
                : "This serie"}
            </MDTypography>
          </div>
        );
      case 2:
        return (
          <div>
            <MDTypography variant="body" fontWeight="medium">
              REMOVE FROM SERIE:{" "}
            </MDTypography>
            <MDTypography variant="body">
              {tri.tre_actionDetails > 0
                ? series.filter((ser) => ser.ser_id == tri.tre_actionDetails)[0].ser_name
                : "This serie"}
            </MDTypography>
          </div>
        );
      case 3:
        return (
          <div>
            <MDTypography variant="body" fontWeight="medium">
              URL:{" "}
            </MDTypography>
            <MDTypography variant="body">
              {JSON.parse(tri.tre_actionDetails).tri_targetURL}{" "}
            </MDTypography>
            <br></br>
            <MDTypography variant="body" fontWeight="medium">
              METHOD:{" "}
            </MDTypography>
            <MDTypography variant="body">
              {
                triggerMethod.filter(
                  (met) => met.value == JSON.parse(tri.tre_actionDetails).tri_method
                )[0].method
              }
            </MDTypography>
            <br></br>
            <MDTypography variant="body" fontWeight="medium">
              BODY:{" "}
            </MDTypography>
            <MDTypography variant="body">{JSON.parse(tri.tre_actionDetails).tri_body}</MDTypography>
          </div>
        );
      case 4:
        return (
          <div>
            <MDTypography variant="body" fontWeight="medium">
              SEND BROADCAST:{" "}
            </MDTypography>
            <MDTypography variant="body">
              {" "}
              {broadcastData.filter((bro) => bro.bro_id == tri.tre_actionDetails)[0].bro_name}{" "}
            </MDTypography>
          </div>
        );
      case 5:
        return (
          <div>
            <MDTypography variant="body" fontWeight="medium">
              ADD A TAG:{" "}
            </MDTypography>
            <MDTypography variant="body"> {tri.tre_actionDetails} </MDTypography>
          </div>
        );
      case 7:
        return (
          <div>
            <MDTypography variant="body" fontWeight="medium">
              REMOVE A TAG:{" "}
            </MDTypography>
            <MDTypography variant="body"> {tri.tre_actionDetails} </MDTypography>
          </div>
        );
    }
  };

  const getStatus = (value) => {
    switch (value) {
      case 0:
        return (
          <Tooltip title={"Enabled"}>
            <CheckBoxIcon fontSize="small" color="success" />
          </Tooltip>
        );
      case 1:
        return (
          <Tooltip title={"Disabled"}>
            <IndeterminateCheckBoxIcon fontSize="small" color="error" />
          </Tooltip>
        );
    }
  };

  const renderMenuSeries = (item) => {
    switch (item.value) {
      case 0:
        if (series.length > 0)
          return (
            <MenuItem key={item.value} value={item.value}>
              {item.action}
            </MenuItem>
          );
        break;
      case 1:
        if (series.length > 0)
          return (
            <MenuItem key={item.value} value={item.value}>
              {item.action}
            </MenuItem>
          );
        break;
      default:
        return (
          <MenuItem key={item.value} value={item.value}>
            {item.action}
          </MenuItem>
        );
    }
  };

  const columnsTriggers = [
    {
      label: "Event",
      name: "tre_event",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return getEvent(triggersData[dataIndex]);
        },
      },
    },
    {
      label: "Action",
      name: "tre_action",
      options: {
        filterOptions: { fullWidth: true },
        customBodyRender: (value) => getAction(value),
      },
    },
    {
      label: "Details",
      name: "tre_actionDetails",
      options: {
        filterOptions: { fullWidth: true },
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return getActionDetails(triggersData[dataIndex]);
        },
      },
    },
  ];

  const customToolbarTrigger = () => <CustomToolbarTrigger onDelete={handleDeleteTriggers} />;

  const tableTriggersOptions = {
    textLabels: {
      body: {
        noMatch: "No triggers added",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "Clear filter",
      },
      filterType: "dropdown",
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "interest selected",
      },
    },
    print: false,
    filter: false,
    viewColumns: false,
    selectableRowsHideCheckboxes: false,
    selectableRowsOnClick: false,
    responsive: "simple",
    filterType: "dropdown",
    selectableRows: "multiple",
    fixedHeader: true,
    fixedSelectColumn: true,
    onRowSelectionChange: (curRowSelected, allRowsSelected) => {
      triggersSelected = [];
      allRowsSelected.map((row) => {
        triggersSelected.push(triggersData[row.dataIndex]);
      });
    },
    customToolbarSelect: customToolbarTrigger,
  };

  return (
    <Grid>
      {showButton && (
        <MDButton onClick={() => setOpen(!open)} fullWidth variant="gradient" color="primary">
          Triggers {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </MDButton>
      )}
      {open && (
        <Card xs={12} style={{ padding: 10, backgroundColor: "#FFFFFF", marginBottom: 10 }}>
          <Grid container spacing={1} m={2}>
            {showAddButton && (
              <Grid item xs={12}>
                <MDButton
                  m={2}
                  mt={2}
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    setSelectedTriggerAction(3);
                    setSelectedTriggerEvent(1);
                    setAddTrigger(!addTrigger);
                    getSeries();
                    getBroadcasts();
                    getTags();
                  }}
                >
                  Add trigger
                </MDButton>
              </Grid>
            )}
            {addTrigger && (
              <Grid
                container
                spacing={1}
                m={2}
                sx={{
                  //You can copy the code below in your theme
                  background: "#FFFFFF",
                  "& .MuiPaper-root": {
                    background: "#FFFFFF",
                  },
                  "& .MuiBackdrop-root": {
                    backgroundColor: "transparent", // Try to remove this to see the result
                  },
                }}
              >
                <Grid item xs={12}>
                  <MDBox display="flex" justifyContent="left">
                    <MDTypography variant="h6" fontWeight="medium" mt={1}>
                      Select the segments for this trigger{" "}
                      {
                        " (or leave everything unchecked to trigger to your entire subscribers list)"
                      }
                    </MDTypography>
                  </MDBox>
                  <MDBox mb={2}>
                    <Segments
                      changeSegments={(value) => onChangeSegmentTrigger(value)}
                      filterSegments={props.filterSegments}
                      showRecipientsCount={false}
                      segments={null}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox pb={2} display="flex" justifyContent="left">
                    <MDTypography variant="h6" fontWeight="medium" mt={1} ml={1} mr={2}>
                      When any subscriber
                    </MDTypography>
                    <Select
                      disabled={isLoading}
                      onChange={handleChangeTriggerEvent}
                      value={selectedTriggerEvent}
                      inputProps={{
                        name: "select-trigger",
                        id: "select-trigger",
                      }}
                    >
                      {triggerEvent.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.event}
                        </MenuItem>
                      ))}
                    </Select>
                    {selectedTriggerEvent == 0 && (
                      <>
                        <MDTypography variant="subtitle2" fontWeight="medium" ml={1} mr={2} mt={1}>
                          in catalog
                        </MDTypography>
                        <Select
                          disabled={isLoading}
                          onChange={(e) => onChangeCatalog(e)}
                          value={selectedCatalog}
                        >
                          {CatalogsData.map((cat) => (
                            <MenuItem key={cat.cat_id} value={cat.cat_id}>
                              {cat.cat_name}
                            </MenuItem>
                          ))}
                        </Select>
                        <MDTypography variant="subtitle2" fontWeight="medium" ml={1} mr={2} mt={1}>
                          "{selectedItemName}"
                        </MDTypography>

                        <MDButton
                          onClick={() => setSelectItem(!selectItem)}
                          variant="gradient"
                          color="info"
                        >
                          Select item{" "}
                          {selectItem ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </MDButton>
                      </>
                    )}
                    {selectedTriggerEvent == 12 && (
                      <MDBox ml={5}>
                        <MDInput
                          label="write a url"
                          value={urlLink}
                          onChange={(e) => setUrlLink(e.target.value)}
                        />
                        <span
                          style={{
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {validationURL}
                        </span>
                      </MDBox>
                    )}
                  </MDBox>
                  {selectedTriggerEvent == 0 && selectItem && (
                    <Card
                      style={{
                        height: "300px",
                        marginBottom: 20,
                        marginLeft: 10,
                        marginRight: 10,
                        border: "solid",
                        borderRadius: 0,
                      }}
                    >
                      <CardHeader
                        title={
                          <div>
                            <MDTypography variant="body">Items</MDTypography>
                            <MDInput
                              onChange={(event) => handleFilterItem(event)}
                              label="Filter"
                              style={{ width: "95%", margin: 5 }}
                            />
                          </div>
                        }
                        style={{ color: "white", backgroundColor: "white" }}
                      />

                      <List style={{ overflow: "auto" }}>
                        <ListItemButton
                          sx={{ width: "100%" }}
                          key={0}
                          onClick={(event) => handleItemsClick(event, 0, "Any")}
                        >
                          <ListItemText>
                            <span style={{ fontSize: "0.9rem" }}>{"Any"}</span>
                          </ListItemText>
                        </ListItemButton>
                        <Divider variant="inset" component="li" />
                        {ItemsFilteredData.map((item, index) => (
                          <>
                            <ListItemButton
                              sx={{ width: "100%" }}
                              key={item.bla_id}
                              onClick={(event) =>
                                handleItemsClick(event, item.item_id, item.item_name)
                              }
                            >
                              <ListItemText>
                                <span style={{ fontSize: "0.9rem" }}>{item.item_name}</span>
                              </ListItemText>
                            </ListItemButton>
                            <Divider variant="inset" component="li" />
                          </>
                        ))}
                      </List>
                    </Card>
                  )}
                  <MDBox pb={2} display="flex" justifyContent="left">
                    <MDTypography variant="h6" fontWeight="medium" ml={1} mr={2}>
                      Perform the following action
                    </MDTypography>
                    <Select
                      disabled={isLoading}
                      onChange={handleChangeTriggerAction}
                      value={selectedTriggerAction}
                      inputProps={{
                        id: "selectAction",
                      }}
                    >
                      {triggerActions.map((item) => renderMenuSeries(item))}
                    </Select>
                    {(selectedTriggerAction == 0 || selectedTriggerAction == 1) && (
                      <Select
                        disabled={isLoading}
                        onChange={handleChangeSeries}
                        value={selectedSeries}
                        inputProps={{
                          id: "select-series",
                        }}
                      >
                        {series.map((item) => (
                          <MenuItem key={item.ser_id} value={item.ser_id}>
                            {item.ser_name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                    {selectedTriggerAction == 2 && (
                      <Select
                        disabled={isLoading}
                        onChange={handleChangeSeries}
                        value={selectedSeries}
                        inputProps={{
                          id: "select-series",
                        }}
                      >
                        <MenuItem key={0} value={0}>
                          this serie
                        </MenuItem>
                        {series.map((item) => (
                          <MenuItem key={item.ser_id} value={item.ser_id}>
                            {item.ser_name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </MDBox>
                  {selectedTriggerAction == 4 && (
                    <Select
                      style={{ width: "50%", marginLeft: 10, marginBottom: 10 }}
                      disabled={isLoading}
                      onChange={(event) => SetSelectedBroadcast(event.target.value)}
                      value={selectedBroadcast}
                      inputProps={{
                        id: "select-broadcasts",
                      }}
                    >
                      {broadcastData.map((item) => (
                        <MenuItem key={item.bro_id} value={item.bro_id}>
                          {item.bro_name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}

                  {selectedTriggerAction == 5 && (
                    <Autocomplete
                      value={tags}
                      style={{ width: "50%", marginLeft: 10, marginBottom: 10 }}
                      onChange={(event, newValue) => {
                        setTags(
                          newValue.map((tag) =>
                            tag.indexOf('Create new tag "') === -1
                              ? tag
                              : tag.substring(16).slice(0, -1)
                          )
                        );
                      }}
                      multiple
                      id="tags-filled"
                      options={suggestionsTag.map((option) => option.name)}
                      freeSolo
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Type to search or add a new tag and press enter"
                        />
                      )}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some((option) => inputValue === option);
                        if (inputValue.trim() !== "" && !isExisting) {
                          filtered.push(`Create new tag "${inputValue}"`);
                        }
                        return filtered;
                      }}
                    />
                  )}

                  {selectedTriggerAction == 7 && (
                    <Autocomplete
                      value={tags}
                      style={{ width: "50%", marginLeft: 10, marginBottom: 10 }}
                      onChange={(event, newValue) => {
                        setTags(
                          newValue.map((tag) =>
                            tag.indexOf('Create new tag "') === -1
                              ? tag
                              : tag.substring(16).slice(0, -1)
                          )
                        );
                      }}
                      multiple
                      id="tags-filled"
                      options={suggestionsTag.map((option) => option.name)}
                      freeSolo
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Type to search or add a new tag and press enter"
                        />
                      )}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some((option) => inputValue === option);
                        if (inputValue.trim() !== "" && !isExisting) {
                          filtered.push(`Create new tag "${inputValue}"`);
                        }
                        return filtered;
                      }}
                    />
                  )}

                  {selectedTriggerAction == 3 && (
                    <>
                      <MDBox pb={2} display="flex" justifyContent="left">
                        <MDTypography variant="h6" fontWeight="medium" ml={1} mr={2}>
                          Method
                        </MDTypography>
                        <Select
                          onChange={(event) => setSelectedURLMethod(event.target.value)}
                          value={selectedURLMethod}
                        >
                          {triggerMethod.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.method}
                            </MenuItem>
                          ))}
                        </Select>
                      </MDBox>
                      <MDBox m={2}>
                        <MDInput
                          type="text"
                          label="Target URL"
                          fullWidth
                          value={triggerTargetURL}
                          onChange={onChangeTriggerTargetURL}
                          required
                        />
                      </MDBox>
                      <MDBox m={2}>
                        <MDInput
                          type="text"
                          label="JSON body"
                          fullWidth
                          value={triggerBody}
                          onChange={onChangeTriggerBody}
                          required
                        />
                      </MDBox>
                    </>
                  )}
                  <MDBox pb={2} display="flex" justifyContent="left">
                    <MDButton
                      m={2}
                      mt={2}
                      variant="outlined"
                      color="secondary"
                      onClick={() => setAddTrigger(false)}
                    >
                      Close
                    </MDButton>
                    <MDButton
                      style={{ marginLeft: 5 }}
                      m={2}
                      mt={2}
                      variant="gradient"
                      color="success"
                      onClick={() => addTriggerHandler()}
                    >
                      Save
                    </MDButton>
                  </MDBox>
                </Grid>
              </Grid>
            )}
            <Grid item xs={11}>
              <MUIDataTable
                data={triggersData}
                columns={columnsTriggers}
                options={tableTriggersOptions}
              />
            </Grid>
          </Grid>
        </Card>
      )}
    </Grid>
  );
}
