/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
// import Gptlab from "layouts/gptlab";
import Reports from "layouts/reports";
import Subscribers from "layouts/subscribers";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";
import Settings from "layouts/settings";
import Terms from "layouts/terms";
import Replies from "layouts/replies";
// import SalesPage from "layouts/SalesPage";
import Broadcasts from "layouts/Broadcasts";
import Series from "layouts/Series";
import Sales from "layouts/Sales";
import Catalogs from "layouts/catalogs";
import Blogs from "layouts/Blogs";
import SuppressionLists from "layouts/suppressionList";
import Segments from "layouts/segments";
import Forms from "layouts/Forms";
import Done from "layouts/authentication/sign-up/done";
import Subscription from "layouts/authentication/sign-up/subscription";
// import Createjson from "layouts/authentication/sign-up/createjson";
import SenderDomains from "layouts/SenderDomains";
import SenderProfile from "layouts/SenderProfiles";
import Forgot from "layouts/authentication/sign-in/forgot";
import ForgotDone from "layouts/authentication/sign-in/forgotDone";
import PasswordReset from "layouts/authentication/sign-in/passwordReset";
import ReplyAccounts from "layouts/ReplyAccounts";
import Automations from "layouts/Automations";
import Billing from "layouts/Billing";
import Thankyou from "layouts/static/thankyou";
import AlreadySubscribed from "layouts/static/alreadySubscribed";
import ConfirmEmail from "layouts/static/confirmEmail";
import Preview from "layouts/Broadcasts/preview";

/* eslint-disable */

const routes = [
  /*
  {
    type: "collapse",
    name: "GPT prompt LAB",
    key: "gptPromptLab",
    icon: <Icon fontSize="small">abc</Icon>,
    route: "/gptlab",
    component: <Gptlab />,
  },*/
  {
    type: "collapse",
    name: "Dashboard",
    key: "reports",
    icon: <Icon fontSize="small">analytics</Icon>,
    route: "/reports",
    component: <Reports />,
  },
  {
    type: "collapse",
    name: "Catalogs",
    key: "catalogs",
    icon: <Icon fontSize="small">style</Icon>,
    route: "/catalogs",
    component: <Catalogs />,
  },
  {
    type: "collapse",
    name: "Series",
    key: "series",
    icon: <Icon fontSize="small">dynamic_feed</Icon>,
    route: "/series",
    component: <Series type={1} />,
  },
  {
    type: "collapse",
    name: "Broadcasts",
    key: "broadcasts",
    icon: <Icon fontSize="small">podcastsIcon</Icon>,
    route: "/broadcasts",
    component: <Broadcasts />,
  },
  {
    type: "collapse",
    name: "Automations",
    key: "automations",
    icon: <Icon fontSize="small">schema</Icon>,
    route: "/automations",
    component: <Automations />,
  },
  {
    type: "collapse",
    name: "Blogs",
    key: "blogs",
    icon: <Icon fontSize="small">article</Icon>,
    route: "/blogs",
    component: <Blogs />,
  },
  {
    type: "collapse",
    name: "Sales",
    key: "sales",
    icon: <Icon fontSize="small">paid</Icon>,
    route: "/sales",
    component: <Sales />,
  },
  {
    type: "title",
    title: "Subscribers",
    key: "SubscribersTitle",
  },
  {
    type: "collapse",
    name: "Management",
    key: "subscribers",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/subscribers",
    component: <Subscribers />,
  },
  {
    type: "collapse",
    name: "Segments",
    key: "segments",
    icon: <Icon fontSize="small">segment</Icon>,
    route: "/segments",
    component: <Segments />,
  },
  {
    type: "collapse",
    name: "Forms",
    key: "forms",
    icon: <Icon fontSize="small">dynamic_form</Icon>,
    route: "/forms",
    component: <Forms />,
  },
  {
    type: "collapse",
    name: "Suppression Lists",
    key: "suppressionLists",
    icon: <Icon fontSize="small">playlist_remove</Icon>,
    route: "/suppressionLists",
    component: <SuppressionLists />,
  },
  {
    type: "collapse",
    name: "Sender profile",
    key: "senderProfile",
    icon: <Icon fontSize="small">send</Icon>,
    route: "/senderProfile",
    component: <SenderProfile />,
  },
  {
    type: "collapse",
    name: "Sender domains",
    key: "senderDomains",
    icon: <Icon fontSize="small">public</Icon>,
    route: "/senderDomains",
    component: <SenderDomains />,
  },
  {
    type: "collapse",
    name: "Replies",
    key: "replies",
    icon: <Icon fontSize="small">reply </Icon>,
    route: "/replies",
    component: <Replies />,
  },
  {
    type: "",
    name: "Settings",
    key: "settings",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/settings",
    component: <Settings />,
  },
  {
    type: "internal",
    name: "Reply accounts",
    key: "replyAccounts",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/ReplyAccounts",
    component: <ReplyAccounts />,
  },
  {
    type: "",
    name: "Log out",
    key: "log-out",
    icon: <Icon fontSize="small">logout</Icon>,
    route: "",
    component: <SignIn />,
  },
  {
    type: "",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/",
    component: <SignIn />,
  },
  {
    type: "",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
  {
    type: "",
    name: "Not Found",
    key: "notFound",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/notFound",
    component: <SignIn />,
  },
  {
    type: "",
    name: "signIn",
    key: "signIn",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "",
    name: "Terms",
    key: "terms",
    icon: "",
    route: "/terms",
    component: <Terms />,
  },
  {
    type: "internal",
    name: "Done",
    key: "done",
    icon: "",
    route: "/done",
    component: <Done />,
  },
  {
    type: "internal",
    name: "forgot",
    key: "forgot",
    icon: "",
    route: "/forgot",
    component: <Forgot />,
  },
  {
    type: "internal",
    name: "forgotDone",
    key: "forgotDone",
    icon: "",
    route: "/forgotDone",
    component: <ForgotDone />,
  },
  {
    type: "internal",
    name: "passwordReset",
    key: "passwordReset",
    icon: "",
    route: "/passwordReset",
    component: <PasswordReset />,
  },
  {
    type: "internal",
    name: "Subscription",
    key: "subscription",
    icon: "",
    route: "/subscription",
    component: <Subscription />,
  },
  {
    type: "route",
    name: "Billing",
    key: "billing",
    icon: "",
    route: "/billing",
    component: <Billing />,
  },
  {
    type: "route",
    name: "thankyou",
    key: "thankyou",
    icon: "",
    route: "/thank-you",
    component: <Thankyou />,
  },
  {
    type: "route",
    name: "alreadySubscribed",
    key: "alreadySubscribed",
    icon: "",
    route: "/already-subscribed",
    component: <AlreadySubscribed />,
  },
  {
    type: "route",
    name: "confirmEmail",
    key: "confirmEmail",
    icon: "",
    route: "/confirm-email",
    component: <ConfirmEmail />,
  },
  {
    type: "route",
    name: "preview",
    key: "preview",
    icon: "",
    route: "/broadcasts/preview",
    component: <Preview />,
  },
];

/*
   {
    type: "",
    name: "createjson",
    key: "createjson",
    icon: "",
    route: "/createjson",
    component: <Createjson />,
  },
   {
    type: "",
    name: "SalesPage",
    key: "salesPage",
    icon: "",
    route: "/",
    component: <SalesPage />,
  },

  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/billing",
    component: <Billing />,
  },
  {
    type: "collapse",
    name: "RTL",
    key: "rtl",
    icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
    route: "/rtl",
    component: <RTL />,
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },

  
*/

//export default routes.filter((route) => route.key != "automation");
export default routes;
