import {
  Checkbox,
  MenuItem,
  Select,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  Tooltip,
} from "@material-ui/core";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";

/*eslint-disable*/
export default function MSCustomFields(props) {
  const [fields, setFields] = useState(props.fields);

  const handleNameChange = (index, event) => {
    let data = [...fields];
    data[index].name = event.target.value;
    setFields(data);
  };

  const handleRequiredChange = (index) => {
    let data = [...fields];
    data[index].required = !data[index].required;
    setFields(data);
  };

  const handleTypeChange = (index, event) => {
    let data = [...fields];
    data[index].type = event.target.value;
    setFields(data);
  };

  const addField = () => {
    let newField = { name: "", value: "", required: false, type: 0 };
    setFields([...fields, newField]);
  };

  const removeFields = (index) => {
    let data = [...fields];
    data.splice(index, 1);
    setFields(data);
  };

  useEffect(() => {
    props.setFields(fields);
  }, [fields]);

  useEffect(() => {
    if (props.fields.length != 0 && props.fields != fields) setFields(props.fields);
  }, [props.fields]);

  return (
    <div style={{ width: "100%" }}>
      <Grid container spacing={1}>
        {fields &&
          fields.map((field, index) => {
            return (
              <Grid item xs={11}>
                <MDBox display="flex" justifyContent="space-between">
                  <Select
                    value={field.type}
                    label="Select the type of your custom field"
                    onChange={(event) => handleTypeChange(index, event)}
                  >
                    <MenuItem value="0">Text</MenuItem>
                    <MenuItem value="1">Date</MenuItem>
                  </Select>
                  <MDInput
                    key={index}
                    value={field.name}
                    onChange={(event) => handleNameChange(index, event)}
                    style={{ marginTop: 10 }}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.required}
                        onChange={() => handleRequiredChange(index)}
                      />
                    }
                    label="Required"
                  />
                  <IconButton
                    onClick={() => {
                      removeFields(index);
                    }}
                    sx={{ fontWeight: "bold" }}
                    color="error"
                    aria-label="prompt"
                  >
                    <Tooltip id="button-report" title="Remove custom field">
                      <Icon fontSize="small">delete</Icon>
                    </Tooltip>
                  </IconButton>
                </MDBox>
              </Grid>
            );
          })}
        <Grid item xs={1}>
          <MDBox justifyContent="right">
            <IconButton
              onClick={() => {
                addField();
              }}
              sx={{ fontWeight: "bold" }}
              color="primary"
              aria-label="prompt"
            >
              <Tooltip id="button-report" title="Add new custom field">
                <Icon fontSize="small">add</Icon>
              </Tooltip>
            </IconButton>
          </MDBox>
        </Grid>
      </Grid>
    </div>
  );
}
