import { useContext, useEffect, useRef, useState } from "react";
import theme from "assets/theme";
// react-router-dom components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import client from "ApiClient";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  TextField,
  Tooltip,
  Select,
  Autocomplete,
  Chip,
  CardHeader,
} from "@mui/material";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import { Checkbox, Icon, Radio, RadioGroup, Step, StepLabel, Stepper } from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import SimpleReactValidator from "simple-react-validator";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Moment from "moment-timezone";
import MDButton from "components/MDButton";
import EmailEditor from "react-email-editor";
import MDSnackbar from "components/MDSnackbar";
import Segments from "components/Segments";
import Triggers from "components/triggers";
import moment from "moment/moment";
import MSPersonalizationTags from "components/MSPersonalizationTags";
import { useMaterialUIController } from "context";
import { FeatureFlags } from "context/FeatureFlags";
import MSMultipleSubjects from "components/MSMultipleSubjects";

/* eslint-disable */
function Add(props) {
  const [margin, setMargin] = useState(300);
  const [isLoading, setIsLoading] = useState(true);
  const [pauseSeries, setPauseSeries] = useState(false);
  const [showTRacking, setShowTracking] = useState(false);
  const [isTracking, setIsTracking] = useState(false);
  const [subject, setSubject] = useState([]);
  const [broId, setBroId] = useState(props.broId);
  const [emcId, setEmcId] = useState("");
  const [name, setName] = useState("");
  const [envelopFrom, setEnvelopFrom] = useState("");
  const [displayFrom, setDisplayFrom] = useState("");
  const [testEmail, setTestEmail] = useState("");
  const [arrSenderProfiles, setArrSenderProfiles] = useState([]);
  const [senderProfile, setSenderProfile] = useState(-1);
  // const [subscribersData, SetSubscribersData] = useState([]);
  const [token, setToken] = useState(JSON.parse(localStorage.getItem("AuthorizationToken")));
  const [valueDate, setValueDate] = useState(Moment());
  const [dateFrom, setDateFrom] = useState(12);
  const [dateTo, setDateTo] = useState(12);
  const [dateAt, setDateAt] = useState(12);
  const [templateBody, setTemplateBody] = useState(props.body);
  const [htmlPreview, setHtmlPreview] = useState("");
  const [subjectPreview, setSubjectPreview] = useState("");
  const [sendMode, setSendMode] = useState("0");
  const [suppressions, setSuppressions] = useState();
  const [suppressionsSelected, setSuppressionsSelected] = useState([]);
  const [recipients, setRecipients] = useState(0);
  const [contentText, setContentText] = useState("");
  const [mimeType, setMimeType] = useState(0);
  const { openModal, closeModal } = props;
  const [, forceUpdate] = useState();
  const [jsonDesign, setJsonDesign] = useState();
  const [catalogs, setCatalogs] = useState([]);
  const [segmentsRows, setSegmentsRows] = useState(props.segments);
  const [segments, setSegments] = useState(props.segments);
  const [triggers, setTriggers] = useState(props.triggers);
  const [triggersAux, setTriggersAux] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState("");
  const emailEditorRef = useRef(null);

  const [errorContent, setErrorContent] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [openErrorSB, setOpenErrorSB] = useState(false);
  const closeErrorSB = () => setOpenErrorSB(false);
  const [openTestSB, setOpenTestSB] = useState(false);
  const closeTestSB = () => setOpenTestSB(false);

  const [activeStep, setActiveStep] = useState(1);
  const [skipped, setSkipped] = useState(new Set());
  const [skippedSteps, setSkippedSteps] = useState([]);
  const [failedSteps, setFailedSteps] = useState([]);
  const [broadcast, setBroadcast] = useState(null);

  const { features } = useContext(FeatureFlags);

  const [controller] = useMaterialUIController();
  const { miniSidenav } = controller;

  useEffect(() => {
    if (miniSidenav) setMargin(300);
    else setMargin(0);
  }, [miniSidenav]);

  const simpleValidator = useRef(
    new SimpleReactValidator({
      validators: {
        sender: {
          // name the rule
          message: "Please select a sender profile",
          rule: (val, params, validator) => {
            return val >= 0;
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
          required: true, // optional
        },
      },
    })
  );

  const clearVariables = (reason) => {
    if (reason === "backdropClick") return;
    setName("");
    setSubject([]);
    setPauseSeries(false);
    setEnvelopFrom("");
    setDisplayFrom("");
    setValueDate(Moment());
    setContentText("");
    setMimeType(0);
    setTemplateBody("");
    setHtmlPreview("");
    setSubjectPreview("");
    setSuppressionsSelected([]);
    setSenderProfile(-1);
    setActiveStep(1);
    setJsonDesign("");
    setTriggersAux(null);
    setSegmentsRows(null);
    setTemplateBody("");
    setSegments(null);
    setSendMode("0");
    setBroadcast(null);
    simpleValidator.current.hideMessages();
    closeModal();
  };

  ///////////////////////////////////////////////////////////STEPS START ///////////////////////////////////////////////////////////////////////////////

  const steps = ["Broadcast", "Content", "Segments", "Triggers", "Sending time"];

  const isStepOptional = (step) => {
    return skippedSteps.includes(step);
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const isStepFailed = (step) => {
    return failedSteps.includes(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    // setMappedHeaders(mappedHeaders.filter((item) => !mappedField.includes(item) && item != selectedHeader));
    let fail = false;
    switch (activeStep) {
      case 0:
        if (name === "") {
          fail = true;
          simpleValidator.current.showMessageFor("Name", name, "required");
          forceUpdate(1);
        }
        if (subject === "") {
          fail = true;
          simpleValidator.current.showMessageFor("Subject", subject, "required");
          forceUpdate(1);
        }
        if (senderProfile < 0) {
          fail = true;
          simpleValidator.current.showMessageFor("Sender Profile", senderProfile, "sender");
          forceUpdate(1);
        }
        if (!fail) setIsLoading(true);
        break;
      case 1:
        const unlayer = emailEditorRef.current?.editor;
        unlayer.exportHtml((data) => {
          setTemplateBody(data.html);
          setJsonDesign(data.design);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        });
        break;
      case 2:
        setSegments(segmentsRows);
        break;
      case 3:
        setTriggersAux(triggers);
        break;
    }

    if (fail) return;
    if (activeStep === steps.length - 1) {
      handleSave();
    } else {
      if (activeStep != 1) setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const handleBack = () => {
    switch (activeStep) {
      case 0:
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        break;
      case 1:
        const unlayer = emailEditorRef.current?.editor;
        unlayer.exportHtml((data) => {
          setTemplateBody(data.html);
          setJsonDesign(data.design);
          setActiveStep((prevActiveStep) => prevActiveStep - 1);
        });
        break;

      case 2:
        setSegments(segmentsRows);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        break;
      case 3:
        setTriggersAux(triggers);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        break;
      case 4:
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        break;
    }
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  useEffect(() => {}, [activeStep]);

  const getStep = (step) => {
    let label = "";
    switch (step) {
      case 0: //BROADCAST
        return (
          <Card style={{ padding: 10, backgroundColor: "#FFFFFF", marginBottom: 10 }}>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Broadcast name"
                style={{ width: "50%" }}
                value={name}
                onChange={handleChangeName}
                required
              />
              <MDBox color="red">{simpleValidator.current.message("Name", name, "required")}</MDBox>
            </MDBox>
            <MDBox mb={2}>
              <MDBox mb={2} display="flex">
                <Card
                  xs={12}
                  style={{
                    width: "100%",
                    padding: 10,
                    backgroundColor: "#FFFFFF",
                    marginBottom: 10,
                  }}
                >
                  <CardHeader
                    title={
                      <MDBox mb={2} display="flex">
                        <MDTypography mr={2}>Subjects</MDTypography> <MSPersonalizationTags />
                      </MDBox>
                    }
                  ></CardHeader>
                  <MDBox mb={2} display="flex">
                    <MSMultipleSubjects
                      subjects={subject}
                      setSubjects={(value) => handleChangeSubject(value)}
                    />
                  </MDBox>
                  <MDBox color="red">
                    {simpleValidator.current.message("Subject", subject, "required")}
                  </MDBox>
                </Card>
              </MDBox>
            </MDBox>
            {arrSenderProfiles.length > 0 && (
              <MDBox mb={2}>
                <Select
                  placeholder="Select sender profile"
                  value={senderProfile}
                  onChange={(e) => {
                    setIsTracking(false);
                    setSenderProfile(e.target.value);
                  }}
                >
                  <MenuItem key={-1} value={-1}>
                    <ListItemText primary={"Select a sender profile"} secondary="_" />
                  </MenuItem>
                  {arrSenderProfiles.map((item) => (
                    <MenuItem key={item.sep_id} value={item.sep_id}>
                      <ListItemText
                        primary={"From:" + item.sep_envelopFrom}
                        secondary={"Reply to:" + item.sep_replyTo}
                      />
                    </MenuItem>
                  ))}
                </Select>
                <MDBox color="red">
                  {simpleValidator.current.message("Sender Profile", senderProfile, "sender")}
                </MDBox>
              </MDBox>
            )}
            <MDBox display="flex" justifyContent="left">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isTracking}
                    onChange={() => setIsTracking(!isTracking)}
                    disabled={!showTRacking}
                  />
                }
                label="Track opens and clicks"
              />

              <Tooltip
                id="descriptionHelp"
                title={
                  <MDTypography color={"white"}>
                    Track opens and clicks, only if the domain are verified for tracking
                  </MDTypography>
                }
              >
                <Icon style={{ marginTop: 8, marginLeft: 2 }}>info</Icon>
              </Tooltip>
            </MDBox>

            {false && (
              <>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Display From example: Blog news"
                    fullWidth
                    value={displayFrom}
                    onChange={handleChangeDisplayFrom}
                    required
                  />
                  <MDBox color="red">
                    {simpleValidator.current.message("display from", displayFrom, "required")}
                  </MDBox>
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Envelop From"
                    fullWidth
                    value={envelopFrom}
                    onChange={handleChangeEnvelopFrom}
                    required
                  />
                  <MDBox color="red">
                    {simpleValidator.current.message("envelop from", envelopFrom, "required|email")}
                  </MDBox>
                </MDBox>
              </>
            )}
            {false && (
              <MDBox display="flex" justifyContent="left">
                <Tooltip
                  id="descriptionHelp"
                  title={
                    <MDTypography color={"white"}>
                      Standard series will be paused for the entire day of the broadcast scheduled
                      date
                    </MDTypography>
                  }
                >
                  <MDTypography variant="h6" fontWeight="medium">
                    <IconButton color="info">
                      <HelpOutlineIcon />
                    </IconButton>
                    Pause standard series
                  </MDTypography>
                </Tooltip>
                <Switch checked={pauseSeries} onChange={handlePauseSeries} />
              </MDBox>
            )}
          </Card>
        );
      case 1: //CONTENT
        return (
          <>
            <Card style={{ padding: 5, backgroundColor: "#FFFFFF", marginBottom: 10 }}>
              <Grid container spacing={2}>
                <Grid item mb={2} xs={10}>
                  <MDTypography variant="h5" fontWeight="medium">
                    HTML content
                  </MDTypography>
                </Grid>
                {false && (
                  <Grid item xs={2} style={{ width: "100%", textAlign: "right" }}>
                    <MSPersonalizationTags />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <EmailEditor
                    ref={emailEditorRef}
                    onReady={onReady}
                    projectId={localStorage.getItem("unlayerID")}
                    minHeight={600}
                    appearance={{
                      panels: {
                        tools: {
                          dock: "left",
                          tabs: {
                            body: {
                              visible: true,
                            },
                          },
                        },
                      },
                    }}
                    tabs={{
                      image: {
                        enabled: false,
                      },
                    }}
                    options={{
                      customJS: [
                        window.location.protocol + "//" + window.location.host + "/customUrl.js",
                        !features.hiddenMenus.includes("catalogs")
                          ? window.location.protocol +
                            "//" +
                            window.location.host +
                            "/customTool.js"
                          : "",
                      ],
                      editor: {
                        autoSelectOnDrop: true,
                      },
                      features: {
                        preview: false,
                      },
                      tools: {
                        // my_tool is the name of our custom tool
                        // It is required to add custom# before the name of your custom tool
                        "custom#variables_tool": {
                          properties: {
                            catalog: {
                              editor: {
                                data: {
                                  catalogs: catalogs,
                                  token: token,
                                  domain: process.env.REACT_APP_MAIN_DOMAIN,
                                },
                              },
                            },
                          },
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Card>
            <Card style={{ padding: 10, backgroundColor: "white", marginBottom: 10 }}>
              <Grid xs={12}>
                <MDInput
                  onChange={(event) => handleSendTest(event)}
                  label="e-mails to test"
                  style={{ marginBottom: 10, width: "100%" }}
                />
                {subject && (
                  <MDBox display="flex">
                    <MDTypography>Select a subject line </MDTypography>
                    <Select
                      title="Select a subject line"
                      value={selectedSubject}
                      onChange={(e) => {
                        setSelectedSubject(e.target.value);
                      }}
                      style={{ marginLeft: 10 }}
                    >
                      {subject.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          <ListItemText primary={item} />
                        </MenuItem>
                      ))}
                    </Select>
                    <MDButton
                      style={{ marginLeft: 10 }}
                      variant="gradient"
                      color="success"
                      onClick={sendTest}
                      disabled={senderProfile < 0 || isLoading}
                    >
                      Send test
                    </MDButton>
                  </MDBox>
                )}
                {senderProfile < 0 && (
                  <MDTypography variant="caption" color="error">
                    You need to pick a sender profile in order to send a test email
                  </MDTypography>
                )}
              </Grid>
            </Card>
          </>
        );

      case 2: //SEGMENTS
        return (
          <Card style={{ padding: 10, backgroundColor: "#FFFFFF", marginBottom: 10 }}>
            {suppressions && suppressions.length > 0 && (
              <MDBox mb={2} display="flex" justifyContent="left" textAlign="center">
                <MDTypography variant="h5" fontWeight="medium" mt={3}>
                  Select the suppressions lists to use
                </MDTypography>
                <Autocomplete
                  style={{ width: "50%", marginTop: 10, marginLeft: 5 }}
                  value={suppressionsSelected}
                  onChange={(event, newValue) => {
                    setSuppressionsSelected(newValue);
                  }}
                  id="tags-filled"
                  multiple
                  options={suppressions.map((option) => option.spr_name)}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                    ))
                  }
                  renderInput={(params) => <TextField {...params} placeholder="Type to search" />}
                />

                <Tooltip title="Suppression lists">
                  <Icon style={{ marginTop: 25, marginLeft: 5 }}>info</Icon>
                </Tooltip>
              </MDBox>
            )}
            <MDBox mb={2}>
              <MDTypography variant="h5" fontWeight="medium" mt={1}>
                Select the segments to send the broadcast{" "}
                {" (or leave everything unchecked to send to your entire subscribers list)"}
              </MDTypography>
              <Segments
                changeSegments={(value) => onChangeSegment(value)}
                filterSegments={null}
                showRecipientsCount={true}
                segments={segments == null ? null : JSON.stringify(segments)}
                load={true}
                showButton={false}
              />
            </MDBox>
          </Card>
        );
      case 3: //TRIGGERS
        return (
          <Triggers
            showButton={false}
            changeTriggers={(value) => onChangeTriggers(value)}
            filterSegments={segmentsRows}
            triggers={triggersAux == null ? null : triggersAux}
          />
        );
      case 4: //SENDING TIME
        return (
          <Card style={{ padding: 10, backgroundColor: "#FFFFFF", marginBottom: 10 }}>
            <MDBox display="flex" justifyContent="left">
              <MDBox mt={1}>
                <MDTypography variant="h5" fontWeight="medium" mt={1} mb={2}>
                  Select send date
                </MDTypography>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    disabled={sendMode === "3"}
                    minDate={Moment().toDate()}
                    required
                    renderInput={(props) => <TextField {...props} />}
                    label="Select the date and time to send"
                    value={valueDate}
                    onChange={(newValue) => {
                      setValueDate(newValue);
                    }}
                  />
                </LocalizationProvider>
              </MDBox>
            </MDBox>
            <MDBox display="flex" justifyContent="left">
              <MDBox mb={3}>
                <FormControl>
                  <MDTypography variant="h5" fontWeight="medium" mt={2}>
                    Select send time
                  </MDTypography>
                  <RadioGroup value={sendMode} onChange={handleChangeMode}>
                    <MDBox display="flex">
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label={process.env.REACT_APP_SITE_TITLE + " will send at the optimal time."}
                      />
                      <Tooltip title="Send at the optimal to each recipient hour of the day">
                        <Icon style={{ marginTop: 8 }}>info</Icon>
                      </Tooltip>
                    </MDBox>
                    {Moment().format("YYYY-MM-DD") === Moment(valueDate).format("YYYY-MM-DD") && (
                      <FormControlLabel value="3" control={<Radio />} label="Send immediately" />
                    )}
                    <FormControlLabel value="2" control={<Radio />} label="Send everything at" />
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <TimePicker
                        disabled={sendMode !== "2"}
                        renderInput={(props) => <TextField {...props} />}
                        label="Select the time (hour) in the day"
                        value={dateAt}
                        onChange={(newValue) => {
                          setDateAt(newValue);
                        }}
                      />
                    </LocalizationProvider>
                  </RadioGroup>
                </FormControl>
              </MDBox>
            </MDBox>
          </Card>
        );
    }
  };

  ///////////////////////////////////////////////////////////STEPS END ///////////////////////////////////////////////////////////////////////////////

  const options = {
    method: "POST",
    url: `emailCopy/Preview`,
    headers: {
      "content-type": "application/json",
    },
  };

  const getBroadcast = async (unlayer) => {
    options.method = "GET";
    options.url = `broadcasts/GetById?bro_id=${props.broId}`;
    setActiveStep(0);
    await client
      .request(options)
      .then((response) => {
        setBroadcast(response);
        setBroId(response.bro_id);
        setEmcId(response.bro_emc_id);
        setName(response.name);
        setSubject(Array.isArray(response.subjects) ? response.subjects : [response.subjects]);
        setPauseSeries(response.pauseStandard);
        setValueDate(response.date);
        setContentText(response.html);
        setTemplateBody(response.html);
        setSegmentsRows(response.bro_segments);
        setSegments(response.bro_segments);
        setTriggers(response.trigger_events);
        setTriggersAux(response.trigger_events);

        if (
          response.bro_senderProfile != null &&
          JSON.parse(response.bro_senderProfile).length > 0 &&
          arrSenderProfiles != undefined
        )
          setSenderProfile(
            arrSenderProfiles.filter(
              (item) => item.sep_id === JSON.parse(response.bro_senderProfile).sep_id
            )[0].sep_id
          );

        setIsTracking(response.bro_trackExternalLinkClicks);
        const jsonMode = response.bro_sendMode;
        setSendMode(jsonMode.mode.toString());
        switch (jsonMode.mode) {
          case 2:
            setDateAt(Moment(jsonMode.hourSpecific + ":" + jsonMode.minuteSpecific, "H:m"));
            break;
          case 3:
            setValueDate(Moment());
            break;
        }

        // console.log(response);
        if (response.json.trim() != "") {
          const json = JSON.parse(response.json);
          setJsonDesign(json);
          unlayer?.loadDesign(json);
        } else {
          unlayer.loadDesign({
            html: response.html,
            classic: true,
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSave = () => {
    if (simpleValidator.current.allValid()) {
      const timeZone = localStorage.getItem("timeZone");
      const options = {
        method: "PUT",
        url: `broadcasts`,
        headers: {
          "content-type": "application/json",
        },
      };
      if (props.clone) options.method = "POST";

      let errors = false;

      if (!errors) {
        setIsLoading(true);
        const unlayer = emailEditorRef.current?.editor;
        if (unlayer != undefined) {
          unlayer?.exportHtml((data) => {
            const html = data.html;
            const design = data.design;

            const sendModeJson = {
              mode: sendMode,
              hourFrom: dateFrom,
              hourTo: dateTo,
              hourSpecific: moment(dateAt).format("H"),
              minuteSpecific: moment(dateAt).format("m"),
            };

            options.data = JSON.stringify({
              bro_id: props.clone ? 0 : broId,
              bro_emc_id: props.clone ? 0 : emcId,
              bro_status: 0,
              bro_ema_id: broadcast.bro_ema_id,
              name: name,
              subjects: subject,
              html: html,
              json: JSON.stringify(design),
              pauseStandard: pauseSeries ? true : false,
              date:
                sendMode === "3"
                  ? moment().format()
                  : valueDate
                  ? moment(valueDate).format()
                  : moment().format(),
              bro_segments:
                segmentsRows == null
                  ? {
                      statuses: [],
                      genders: [],
                      children: [],
                      ageRanges: [],
                      educations: [],
                      employments: [],
                      ethnicities: [],
                      broadcastsEngaged: [],
                      broadcastsNoEngaged: [],
                      tags: [],
                      excludeTags: [],
                      customFieldsText: [],
                      customFieldsDate: [],
                    }
                  : segmentsRows,
              bro_sendMode: sendModeJson,
              bro_spr_ids: suppressions
                .filter((s) => suppressionsSelected.includes(s.spr_name))
                .map((a) => a.spr_id)
                .join(","),
              recipient: testEmail,
              trigger_events: triggers,
              bro_senderProfile:
                senderProfile < 0
                  ? null
                  : JSON.stringify(
                      arrSenderProfiles.filter((item) => item.sep_id == senderProfile)[0]
                    ),
              bro_trackExternalLinkClicks: isTracking,
            });

            client
              .request(options)
              .then((response) => {
                setIsLoading(false);
                clearVariables();
              })
              .catch((error) => {
                setIsLoading(false);
                console.log(error);
              });
          });
        } else {
          const sendModeJson = {
            mode: sendMode,
            hourFrom: dateFrom,
            hourTo: dateTo,
            hourSpecific: moment(dateAt).format("H"),
            minuteSpecific: moment(dateAt).format("m"),
          };

          options.data = JSON.stringify({
            bro_id: props.clone ? 0 : broId,
            bro_emc_id: props.clone ? 0 : emcId,
            bro_status: 0,
            bro_ema_id: broadcast.bro_ema_id,
            name: name,
            subjects: subject,
            html: templateBody,
            json: JSON.stringify(jsonDesign),
            pauseStandard: pauseSeries ? true : false,
            date:
              sendMode === "3"
                ? moment().format()
                : valueDate
                ? moment(valueDate).format()
                : moment().format(),
            bro_segments:
              segmentsRows == null
                ? {
                    statuses: [],
                    genders: [],
                    children: [],
                    ageRanges: [],
                    educations: [],
                    employments: [],
                    ethnicities: [],
                    broadcastsEngaged: [],
                    broadcastsNoEngaged: [],
                    tags: [],
                    excludeTags: [],
                    customFieldsText: [],
                    customFieldsDate: [],
                  }
                : segmentsRows,
            bro_sendMode: sendModeJson,
            bro_spr_ids: suppressions
              .filter((s) => suppressionsSelected.includes(s.spr_name))
              .map((a) => a.spr_id)
              .join(","),
            recipient: testEmail,
            trigger_events: triggers,
            bro_senderProfile:
              senderProfile < 0
                ? null
                : JSON.stringify(
                    arrSenderProfiles.filter((item) => item.sep_id == senderProfile)[0]
                  ),
            bro_trackExternalLinkClicks: isTracking,
          });

          client
            .request(options)
            .then((response) => {
              setIsLoading(false);
              clearVariables();
            })
            .catch((error) => {
              setIsLoading(false);
              console.log(error);
            });
        }
      }
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      setErrorTitle("Warning");
      setErrorContent("Please make you sure that you has completed all fields");
      setOpenErrorSB(true);
    }
  };

  const handleSaveDraft = () => {
    const timeZone = localStorage.getItem("timeZone");
    const options = {
      method: "PUT",
      url: `broadcasts`,
      headers: {
        "content-type": "application/json",
      },
    };
    if (props.clone) {
      options.method = "POST";
      options.url = `broadcasts/saveDraft`;
    }

    // let errors = false;
    setIsLoading(true);

    const unlayer = emailEditorRef.current?.editor;
    if (unlayer != undefined) {
      unlayer?.exportHtml((data) => {
        const html = data.html;
        const design = data.design;

        const sendModeJson = {
          mode: sendMode,
          hourFrom: dateFrom,
          hourTo: dateTo,
          hourSpecific: moment(dateAt).format("H"),
          minuteSpecific: moment(dateAt).format("m"),
        };

        options.data = JSON.stringify({
          bro_id: props.clone ? 0 : broId,
          bro_emc_id: props.clone ? 0 : emcId,
          bro_status: 6,
          bro_ema_id: broadcast.bro_ema_id,
          name: name,
          subjects: subject,
          html: html,
          json: JSON.stringify(design),
          pauseStandard: pauseSeries ? true : false,
          date:
            sendMode === "3"
              ? moment().format()
              : valueDate
              ? moment(valueDate).format()
              : moment().format(),
          bro_segments:
            segmentsRows == null
              ? {
                  statuses: [],
                  genders: [],
                  children: [],
                  ageRanges: [],
                  educations: [],
                  employments: [],
                  ethnicities: [],
                  broadcastsEngaged: [],
                  broadcastsNoEngaged: [],
                  tags: [],
                  excludeTags: [],
                  customFieldsText: [],
                  customFieldsDate: [],
                }
              : segmentsRows,
          bro_sendMode: sendModeJson,
          bro_spr_ids: suppressions
            .filter((s) => suppressionsSelected.includes(s.spr_name))
            .map((a) => a.spr_id)
            .join(","),
          recipient: testEmail,
          trigger_events: triggers,
          bro_senderProfile: JSON.stringify(
            arrSenderProfiles.filter((item) => item.sep_id == senderProfile)[0]
          ),
          bro_trackExternalLinkClicks: isTracking,
        });

        client
          .request(options)
          .then((response) => {
            setIsLoading(false);
            clearVariables();
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
          });
      });
    } else {
      const sendModeJson = {
        mode: sendMode,
        hourFrom: dateFrom,
        hourTo: dateTo,
        hourSpecific: moment(dateAt).format("H"),
        minuteSpecific: moment(dateAt).format("m"),
      };

      options.data = JSON.stringify({
        bro_id: props.clone ? 0 : broId,
        bro_emc_id: props.clone ? 0 : emcId,
        bro_status: 6,
        bro_ema_id: broadcast.bro_ema_id,
        name: name,
        subjects: subject,
        html: templateBody,
        json: JSON.stringify(jsonDesign),
        pauseStandard: pauseSeries ? true : false,
        date:
          sendMode === "3"
            ? moment().format()
            : valueDate
            ? moment(valueDate).format()
            : moment().format(),
        bro_segments:
          segmentsRows == null
            ? {
                statuses: [],
                genders: [],
                children: [],
                ageRanges: [],
                educations: [],
                employments: [],
                ethnicities: [],
                broadcastsEngaged: [],
                broadcastsNoEngaged: [],
                tags: [],
                excludeTags: [],
                customFieldsText: [],
                customFieldsDate: [],
              }
            : segmentsRows,
        bro_sendMode: sendModeJson,
        bro_spr_ids: suppressions
          .filter((s) => suppressionsSelected.includes(s.spr_name))
          .map((a) => a.spr_id)
          .join(","),
        recipient: testEmail,
        trigger_events: triggers,
        bro_senderProfile: JSON.stringify(
          arrSenderProfiles.filter((item) => item.sep_id == senderProfile)[0]
        ),
        bro_trackExternalLinkClicks: isTracking,
      });
      client
        .request(options)
        .then((response) => {
          setIsLoading(false);
          clearVariables();
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    }
  };

  const sendTest = () => {
    if (simpleValidator.current.allValid() && senderProfile >= 0) {
      setIsLoading(true);
      const options = {
        method: "POST",
        url: `broadcasts/Send`,
        headers: {
          "content-type": "application/json",
        },
      };

      const unlayer = emailEditorRef.current?.editor;
      unlayer?.exportHtml((data) => {
        const html = data.html;
        const design = data.design;

        options.data = JSON.stringify({
          bro_id: 0,
          bro_emc_id: 0,
          name: "",
          displayfrom: displayFrom,
          fromaddress: envelopFrom,
          subjects: selectedSubject.split("|"),
          html: html,
          pauseStandard: false,
          date: Moment(),
          bro_segments: null,
          recipient: testEmail,
          trigger_events: null,
          bro_senderProfile: JSON.stringify(
            arrSenderProfiles.filter((item) => item.sep_id == senderProfile)[0]
          ),
          bro_tracking: isTracking,
        });
        client
          .request(options)
          .then((response) => {
            setOpenTestSB(true);
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
          });
      });
    } else {
      if (senderProfile < 0) simpleValidator.current.showMessages();
      forceUpdate(1);
      setErrorTitle("Warning");
      setErrorContent("Please make you sure that you has completed all fields");
      setOpenErrorSB(true);
    }
  };

  const getCatalogs = async () => {
    const options = {
      method: "GET",
      url: `catalogs`,
      headers: {
        "content-type": "application/json",
      },
    };

    await client
      .request(options)
      .then((response) => {
        let catalog = [];
        response
          .filter((cat) => cat.cat_status == 0)
          .map((item) => {
            catalog.push({ label: item.cat_name, value: item.cat_id });
          });
        setCatalogs(catalog);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSuppressions = async () => {
    const options = {
      method: "GET",
      url: `suppression`,
      headers: {
        "content-type": "application/json",
      },
    };

    await client
      .request(options)
      .then((response) => {
        setSuppressions(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onReady = (unlayer) => {
    unlayer.setBodyValues({
      contentWidth: "900px", // or percent "50%"
    });
    unlayer.registerCallback("previewHtml", async function (params, done) {
      const options = {
        method: "POST",
        url: `emailCopy/Preview`,
        headers: {
          "content-type": "application/json",
        },
      };
      options.data = JSON.stringify({
        subject: subject[0],
        body: params.html,
      });
      client
        .request(options)
        .then((response) => {
          setSubjectPreview(response.subject);
          done({
            html: response.body,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    });
    unlayer.setMergeTags({
      title: {
        name: "Title",
        value: "[title]",
        sample: "[title]",
      },
      currentDate: { name: "Current date", value: "[current-date]", sample: "[current-date]" },
      broadcastId: { name: "Broadcast ID", value: "[broadcast-id]", sample: "[broadcast-id]" },
      email: { name: "Email", value: "[email]", sample: "[email]" },
      phone: { name: "Phone", value: "[phone]", sample: "[phone]" },
      firstName: { name: "First Name", value: "[first]", sample: "[first]" },
      lastName: { name: "Last Name", value: "[last]", sample: "[last]" },
      city: { name: "City", value: "[city]", sample: "[city]" },
      state: { name: "State", value: "[state]", sample: "[state]" },
      country: { name: "Country", value: "[country]", sample: "[country]" },
      optinDate: { name: "Optin Date", value: "[optin-date]", sample: "[optin-date]" },
      contactUsUrl: {
        name: "Contact Us URL",
        value: "[contact-us-url]",
        sample: "[contact-us-url]",
      },
      contactUsName: {
        name: "Contact Us Name",
        value: "[contact-us-name]",
        sample: "[contact-us-name]",
      },
      companyName: { name: "Company Name", value: "[company-name]", sample: "[company-name]" },
      companyAddress: {
        name: "Company Address",
        value: "[company-address]",
        sample: "[company-address]",
      },
      companyCountry: {
        name: "Company Country",
        value: "[company-country]",
        sample: "[company-country]",
      },
      unsubscribeUrl: {
        name: "Unsubscribe URL",
        value: "[unsubscribe-url]",
        sample: "[unsubscribe-url]",
      },
      unsubscribeParagraph: {
        name: "Unsubscribe Paragraph",
        value: "[unsubscribe-paragraph]",
        sample: "[unsubscribe-paragraph]",
      },
    });
    if (broadcast == null) getBroadcast(unlayer);
    else {
      if (jsonDesign != "") {
        unlayer?.loadDesign(jsonDesign);
      } else {
        unlayer.loadDesign({
          html: templateBody,
          classic: true,
        });
      }
    }
    setIsLoading(false);
  };

  const getSenderProfiles = async () => {
    options.method = "GET";
    options.url = `senderProfiles`;
    await client
      .request(options)
      .then((response) => {
        setArrSenderProfiles(response);
        if (response.length > 0 && broadcast != null && broadcast.bro_senderProfile != null)
          setSenderProfile(
            response.filter(
              (item) => item.sep_id === JSON.parse(broadcast.bro_senderProfile).sep_id
            )[0].sep_id
          );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCatalogs();
    getSenderProfiles();
    getSuppressions();
  }, [broadcast]);

  useEffect(() => {
    if (senderProfile >= 0) {
      options.method = "GET";
      options.url = `senderProfiles/tracking?sep_id=${senderProfile}`;
      client
        .request(options)
        .then((response) => {
          setShowTracking(response);
          setIsTracking(response);
        })
        .catch((error) => {
          console.log(error);
        });
    } else setShowTracking(false);
  }, [senderProfile]);

  useEffect(() => {
    if (segmentsRows != null) {
      // console.log("calculateRecipients");
      options.method = "POST";
      options.url = `broadcasts/calculateRecipients`;
      options.data = segmentsRows;

      client
        .request(options)
        .then((response) => {
          setRecipients(response);
        })
        .catch((error) => {
          console.log(error);
        });
      // console.log(segmentsRows);
    }
    //calculateRecipients();
  }, [segmentsRows]);

  useEffect(() => {
    if (window.innerWidth < 1200) setMargin(0);
    else setMargin(300);
    //calculateRecipients();
  }, [window.innerWidth]);

  const handleChangeSubject = (subjects) => {
    setSubject(subjects);
    setSelectedSubject(subjects[0]);
  };
  const handleChangeDisplayFrom = (event) => {
    setDisplayFrom(event.target.value);
  };
  const handleChangeEnvelopFrom = (event) => {
    setEnvelopFrom(event.target.value);
  };
  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const handleChangeText = (event) => {
    setContentText(event.target.value);
  };
  const handleSendTest = (event) => {
    setTestEmail(event.target.value);
  };
  const handlePauseSeries = () => {
    setPauseSeries(!pauseSeries);
  };

  const onChangeSegment = (value) => {
    setSegmentsRows(value);
  };
  const onChangeTriggers = (value) => {
    setTriggers(value);
  };

  const handleChangeMode = (event) => {
    setSendMode(event.target.value);
  };

  const previewNewTab = () => {
    setIsLoading(true);
    const options = {
      method: "POST",
      url: `emailCopy/draftPreview`,
      headers: {
        "content-type": "application/json",
      },
    };
    const unlayer = emailEditorRef.current?.editor;
    unlayer?.exportHtml((data) => {
      options.data = options.data = data.html;
      client
        .request(options)
        .then((response) => {
          setIsLoading(false);
          window.open(`#/broadcasts/preview?id=${response}`, "_blank");
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    });
  };

  return (
    <Dialog
      open={openModal}
      onClose={(e, reason) => clearVariables(reason)}
      fullScreen
      disableEscapeKeyDown
      style={{ marginLeft: margin }}
      sx={{
        //You can copy the code below in your theme
        background: "#F4F0F700",
        "& .MuiPaper-root": {
          background: theme.palette.background.default,
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent", // Try to remove this to see the result
        },
      }}
    >
      <MDBox
        bgColor="primary"
        variant="gradient"
        borderRadius="lg"
        coloredShadow="primary"
        mx={2}
        mt={-1}
        p={2}
        textAlign="center"
      >
        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
          Edit broadcast {name}
        </MDTypography>
      </MDBox>
      <DialogContent>
        <Stepper activeStep={activeStep} style={{ padding: 10 }}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = <MDTypography variant="caption">Optional</MDTypography>;
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel i {...labelProps}>
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>

        <MDBox m={5}>{getStep(activeStep)}</MDBox>
      </DialogContent>
      <DialogActions style={{ color: "white", backgroundColor: "white" }}>
        <MDBox
          sx={{
            display: "flex",
            justifyContent: "left",
            flexDirection: "row",
            p: 2,
          }}
        >
          {isLoading && (
            <CircularProgress size={24} style={{ marginRight: 15, position: "relative", top: 4 }} />
          )}
          <MDButton
            sx={{ mr: 1 }}
            onClick={clearVariables}
            disabled={isLoading}
            variant="outlined"
            color="secondary"
          >
            Cancel
          </MDButton>
          {activeStep === 1 && (
            <MDButton
              sx={{ mr: 1 }}
              type="link  "
              onClick={() => emailEditorRef.current?.editor.showPreview("desktop")}
              disabled={isLoading}
              variant="outlined"
              color="success"
            >
              Preview
            </MDButton>
          )}
          <MDButton
            sx={{ mr: 1 }}
            disabled={isLoading}
            onClick={handleSaveDraft}
            variant={"outlined"}
            color={"success"}
          >
            Save as draft
          </MDButton>
          <MDButton
            disabled={activeStep === 0 || isLoading}
            onClick={handleBack}
            sx={{ mr: 1 }}
            variant="gradient"
            color="info"
          >
            Back
          </MDButton>

          {isStepOptional(activeStep) && (
            <MDButton
              disabled={isLoading}
              onClick={handleSkip}
              sx={{ mr: 1 }}
              variant="outlined"
              color="info"
            >
              Skip
            </MDButton>
          )}
          <MDButton
            disabled={isLoading}
            onClick={handleNext}
            sx={{ mr: 1 }}
            variant={activeStep === steps.length - 1 ? "gradient" : "outlined"}
            color={activeStep === steps.length - 1 ? "success" : "info"}
          >
            {activeStep === steps.length - 1 ? "Send" : "Next"}
          </MDButton>
        </MDBox>
      </DialogActions>
      <MDSnackbar
        color="success"
        icon="success"
        title="Success"
        content="Your test email has been sent successfully"
        dateTime={""}
        open={openTestSB}
        onClose={closeTestSB}
        close={closeTestSB}
        bgWhite
      />
      <MDSnackbar
        color="error"
        icon="warning"
        title={errorTitle}
        content={errorContent}
        dateTime={""}
        open={openErrorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite
      />
    </Dialog>
  );
}
export default Add;
