/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// import { useState } from "react";
// @mui material components
import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Card, TableCell } from "@material-ui/core";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import EditIcon from "@mui/icons-material/Edit";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import Moment from "moment";
import client from "ApiClient";
import MDButton from "components/MDButton";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  MenuItem,
  Tooltip,
  Select,
} from "@mui/material";
import CustomToolbarSelect from "./customToolbarSelect";
import EditEmailCopy from "./editEmailCopy";
import AddEmailCopy from "./addEmailCopy";

/* eslint-disable */
export default function EmailCopyTable(props) {
  const [acconuntsData, setAcconuntsData] = useState([]);
  const [addGroup, setAddGroup] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [openCopy, setOpenCopy] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // const [emailCopy, setEmailCopy] = useState([]);
  const [openLog, setOpenLog] = useState(false);
  const [previewTemplate, setPreviewTemplate] = useState("");
  const [subjectPreview, setSubjectPreview] = useState("");
  const [selectedCopy, setSelectedCopy] = useState(null);
  const [series, setSeries] = useState([]);
  const [selectedSeries, setSelectedSerie] = useState();
  const [percentage, setPercentage] = useState("false");
  const [create, setCreate] = useState(false);

  const handleCloseModal = () => setOpenModal(false);
  const handleOpenModal = () => setOpenModal(true);
  // const handleCloseModal = () => setOpenModal(false);

  let emailsSelected = [];

  const oldRender = TableCell.render;
  TableCell.render = function (...args) {
    const [props, ...otherArgs] = args;
    if (typeof props === "object" && props && "isEmpty" in props) {
      const { isEmpty, ...propsWithoutEmpty } = props;
      return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
    } else {
      return oldRender.apply(this, args);
    }
  };

  const changeGroup = (currentGroupSelected) => {
    setCurrentEmailCopy(currentGroupSelected);
  };

  const options = {
    method: "GET",
    url: "emailCopy",
  };

  const getData = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `emailCopy/withstats`;
    options.params = {
      emc_ser_id: props.serId,
    };

    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        // console.log(response);
        if (JSON.stringify(response) !== "[{}]") setAcconuntsData(response);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    setSubjectPreview("");
    setPreviewTemplate("");
    getData();
  }, [props.serId]);

  useEffect(() => {
    if (!openLog) {
      getData();
      setAcconuntsData([]);
      setPreviewTemplate("");
      setSubjectPreview("");
    }
  }, [openLog]);

  const getDateValue = (date) => {
    return Moment(date).format("L");
  };

  const handleEdit = () => {
    handleOpenModal();
  };

  const handleDelete = () => {
    setIsLoading(true);
    options.method = "DELETE";
    options.url = `emailCopy`;
    options.data = JSON.stringify(emailsSelected);
    options.headers = {
      "content-type": "application/json",
    };
    console.log(options.data);
    client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        getData();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const handleActivate = () => {
    setIsLoading(true);
    options.method = "PUT";
    options.url = `emailCopy`;
    for (var i = 0; i < emailsSelected.length; i++) {
      emailsSelected[i].emc_status = 0;
    }
    options.data = JSON.stringify(emailsSelected);
    options.headers = {
      "content-type": "application/json",
    };
    client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        getData();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const handleDeactivate = () => {
    setIsLoading(true);
    options.method = "PUT";
    options.url = `emailCopy`;
    for (var i = 0; i < emailsSelected.length; i++) {
      emailsSelected[i].emc_status = 1;
    }
    options.data = JSON.stringify(emailsSelected);
    options.headers = {
      "content-type": "application/json",
    };

    client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        getData();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const handleMoveUp = (index) => {
    setIsLoading(true);
    options.method = "POST";
    options.url = `emailCopy/moveUp`;

    options.data = JSON.stringify(acconuntsData[index]);
    options.headers = {
      "content-type": "application/json",
    };

    client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        if (JSON.stringify(response) !== "[{}]") setAcconuntsData(response);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const handlePreview = (subject, body) => {
    setIsLoading(true);
    const options = {
      method: "POST",
      url: `emailCopy/Preview`,
      headers: {
        "content-type": "application/json",
      },
    };
    options.data = JSON.stringify({
      subject: subject,
      body: body,
    });

    client
      .request(options)
      .then((response) => {
        setSubjectPreview(response.subject);
        setPreviewTemplate(response.body);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const handleMoveDown = (index) => {
    setIsLoading(true);
    options.method = "POST";
    options.url = `emailCopy/moveDown`;

    options.data = JSON.stringify(acconuntsData[index]);
    options.headers = {
      "content-type": "application/json",
    };

    client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        if (JSON.stringify(response) !== "[{}]") setAcconuntsData(response);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const copySerie = () => {
    setIsLoading(true);
    options.method = "POST";
    options.url = `emailCopy/copyToSerie`;
    options.data = JSON.stringify({
      emc_id: selectedCopy.emc_id,
      ser_id: selectedSeries,
    });
    options.headers = {
      "content-type": "application/json",
    };

    client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        setOpenCopy(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const getSeries = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `series`;
    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        if (JSON.stringify(response) !== "[{}]") {
          setSeries(response);
          setSelectedSerie(response[0].ser_id);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getSenderProfiles = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `senderProfiles`;
    await client
      .request(options)
      .then((response) => {
        response.length > 0 ? setCreate(true) : setCreate(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changePercentageHandler = (event) => {
    window.sessionStorage.setItem("broadcast_percentage", event.target.value);
    setPercentage(event.target.value);
  };

  const handleChangeSeries = (event) => {
    setSelectedSerie(event.target.value);
  };

  useEffect(() => {
    if (window.sessionStorage.getItem("broadcast_percentage") == null)
      window.sessionStorage.setItem("broadcast_percentage", percentage);
    else setPercentage(window.sessionStorage.getItem("broadcast_percentage"));
    getSenderProfiles();
    getSeries();
  }, []);

  const getStatus = (value) => {
    switch (value) {
      case 0:
        return (
          <Tooltip title={"Active"}>
            <CheckBoxIcon fontSize="small" color="success" />
          </Tooltip>
        );
      case 1:
        return (
          <Tooltip title={"Inactive"}>
            <IndeterminateCheckBoxIcon fontSize="small" color="error" />
          </Tooltip>
        );
    }
  };

  const columns = [
    {
      label: " ",
      name: "emc_status",
      options: {
        filter: true,
        customBodyRender: (value) => getStatus(value),
      },
    },
    {
      label: "Subject",
      name: "emc_subject",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "Sent",
      name: "total_sent",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "Opens",
      name: "total_opens",
      options: {
        display: percentage == "true" ? false : true,
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "Clicks",
      name: "total_clicks",
      options: {
        display: percentage == "true" ? false : true,
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "Opens %",
      name: "total_opens_perc",
      options: {
        display: percentage == "true" ? true : false,
        filterOptions: { fullWidth: true },
        filter: true,
        customBodyRender: (value) => (percentage ? value + " %" : value),
      },
    },
    {
      label: "Clicks %",
      name: "total_clicks_perc",
      options: {
        display: percentage == "true" ? true : false,
        filterOptions: { fullWidth: true },
        filter: true,
        customBodyRender: (value) => (percentage ? value + " %" : value),
      },
    },
    {
      label: "Complaints",
      name: "total_complaints",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "Unsubscribes",
      name: "total_unsubcribes",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "Creation date",
      name: "emc_creationDate",
      options: {
        filter: true,
        customBodyRender: (value) => getDateValue(value),
      },
    },
    {
      label: " ",
      name: "emc_id",
      options: {
        filter: true,
        setCellHeaderProps: () => {
          return { align: "right" };
        },
        setCellProps: () => {
          return { align: "right" };
        },
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div>
              {!(acconuntsData[dataIndex].emc_order == 0) && (
                <Tooltip title={"Move up"} disabled>
                  <IconButton
                    onClick={() => handleMoveUp(dataIndex)}
                    disabled={acconuntsData[dataIndex].emc_order == 0}
                  >
                    <ArrowUpwardIcon />
                  </IconButton>
                </Tooltip>
              )}
              {!(acconuntsData[dataIndex].emc_order == acconuntsData.length - 1) && (
                <Tooltip title={"Move down"}>
                  <IconButton
                    onClick={() => handleMoveDown(dataIndex)}
                    disabled={acconuntsData[dataIndex].emc_order == acconuntsData.length - 1}
                  >
                    <ArrowDownwardIcon />
                  </IconButton>
                </Tooltip>
              )}
              <IconButton
                onClick={() => {
                  setSelectedCopy(acconuntsData[dataIndex]);
                  setOpenCopy(true);
                }}
                sx={{ fontWeight: "bold" }}
                color="primary"
                aria-label="prompt"
              >
                <Tooltip id="button-report" title="Copy to serie">
                  <ReadMoreIcon />
                </Tooltip>
              </IconButton>
              <IconButton
                onClick={() => {
                  setSelectedCopy(acconuntsData[dataIndex]);
                  setOpenLog(true);
                }}
                sx={{ fontWeight: "bold" }}
                color="primary"
                aria-label="prompt"
              >
                <Tooltip id="button-report" title="Edit">
                  <EditIcon />
                </Tooltip>
              </IconButton>
            </div>
          );
        },
      },
    },
  ];

  const CustomToolbar = ({ displayData }) => {
    return (
      <Tooltip title="Change the stats view between counts and percentage" placement="top">
        <Select
          style={{ marginLeft: 15 }}
          value={percentage}
          label="Change the view of opens and clicks"
          onChange={(event) => changePercentageHandler(event)}
        >
          <MenuItem value="false">#</MenuItem>
          <MenuItem value="true">%</MenuItem>
        </Select>
      </Tooltip>
    );
  };

  const customToolbarSelect = () => (
    <CustomToolbarSelect
      onEdit={handleEdit}
      onDelete={handleDelete}
      onActivate={handleActivate}
      onDeactivate={handleDeactivate}
      onMoveUp={handleMoveUp}
      onMoveDown={handleMoveDown}
    />
  );

  const tableOptions = {
    textLabels: {
      body: {
        noMatch: "You don't have any email copy",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "Clear filter",
      },
      filterType: "dropdown",
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "email copy selected",
      },
    },
    print: false,
    filter: false,
    viewColumns: false,
    selectableRowsHideCheckboxes: false,
    selectableRowsOnClick: false,
    responsive: "simple",
    filterType: "dropdown",
    selectableRows: "multiple",
    fixedHeader: true,
    fixedSelectColumn: true,
    searchOpen: true,
    searchAlwaysOpen: true,
    searchPlaceholder: "Search",
    downloadOptions: { filename: "emailCopy.csv", separator: "," },
    onRowClick: (rowData, index) => {
      handlePreview(
        acconuntsData[index.dataIndex].emc_subject,
        acconuntsData[index.dataIndex].emc_contentHTML
      );
    },
    onRowSelectionChange: (curRowSelected, allRowsSelected) => {
      emailsSelected = [];
      // emailsSelected.push(acconuntsData[curRowSelected[0].dataIndex]);
      allRowsSelected.map((row) => {
        emailsSelected.push(acconuntsData[row.dataIndex]);
      });
    },
    customToolbarSelect: customToolbarSelect,
    customToolbar: CustomToolbar,
  };

  return (
    <div>
      <AddEmailCopy
        openModal={openModal}
        closeModal={handleCloseModal}
        onDone={getData}
        serId={props.serId}
        serName={props.serName}
      />
      <EditEmailCopy
        openModal={openLog}
        closeModal={() => {
          getData();
          setOpenLog(false);
        }}
        copy={selectedCopy}
        serName={props.serName}
      />
      <Dialog open={openCopy} closeModal={() => setOpenCopy(false)}>
        <MDBox
          variant="gradient"
          bgColor="secondary"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-1}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
            {"Copy "} {selectedCopy != null ?? selectedCopy.emc_subject}
          </MDTypography>
        </MDBox>
        <DialogContent>
          <MDTypography variant="h6" fontWeight="medium" mt={1}>
            Copy to serie
          </MDTypography>
          <Select
            label={"select any serie"}
            fullWidth
            onChange={handleChangeSeries}
            value={selectedSeries}
          >
            {series.map((item) => (
              <MenuItem key={item.ser_id} value={item.ser_id}>
                {item.ser_name}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>

        <DialogActions style={{ color: "white", backgroundColor: "white" }}>
          <MDButton
            onClick={() => setOpenCopy(false)}
            disabled={isLoading}
            variant="outlined"
            color="secondary"
          >
            Cancel
          </MDButton>
          <MDButton onClick={copySerie} disabled={isLoading} variant="gradient" color="success">
            Save
          </MDButton>
          {isLoading && (
            <CircularProgress size={24} style={{ marginLeft: 15, position: "relative", top: 4 }} />
          )}
        </DialogActions>
      </Dialog>

      <MDBox
        mx={2}
        mt={1}
        py={3}
        px={2}
        bgColor="badgeColors.primary"
        borderRadius="lg"
        variant="gradient"
      >
        <Grid container>
          <Grid item xs={12} md={10}>
            <MDBox display="flex">
              <MDTypography variant="h2">Email copies for "{props.serName}" serie</MDTypography>
              {isLoading ?? (
                <CircularProgress
                  size={30}
                  style={{ marginLeft: 15, position: "relative", top: 4 }}
                />
              )}
            </MDBox>
          </Grid>
          <Grid item xs={12} md={2} style={{ width: "100%", textAlign: "right" }}>
            <MDBox justifyContent="right">
              {create && (
                <Tooltip title="Create a new email copy">
                  <MDButton variant="gradient" color="secondary" onClick={() => setOpenModal(true)}>
                    Create new
                  </MDButton>
                </Tooltip>
              )}{" "}
            </MDBox>
          </Grid>
        </Grid>
        {!create && (
          <MDTypography variant="caption" color="error">
            You need to create a sender profile to add an email copy
          </MDTypography>
        )}
      </MDBox>

      <MDBox>
        <Grid container p={2}>
          <Grid item xs={12}>
            <div style={{ height: "100%", width: "100%" }}>
              <MUIDataTable data={acconuntsData} columns={columns} options={tableOptions} />
            </div>
          </Grid>
          {previewTemplate != "" && (
            <Card
              style={{ padding: 10, backgroundColor: "white", marginTop: 10, width: "100%" }}
              xs={12}
            >
              <Grid container mt={5}>
                <Grid item mb={2} xs={12}>
                  <MDTypography variant="h6" fontWeight="medium" mt={1}>
                    Subject
                  </MDTypography>
                  <iframe
                    srcDoc={subjectPreview}
                    height="100"
                    width="100%"
                    allowFullScreen
                    style={{ border: "none" }}
                  />
                </Grid>
                <Grid item mb={2} xs={12}>
                  <MDTypography variant="h6" fontWeight="medium" mt={1}>
                    Body
                  </MDTypography>
                  <iframe
                    srcDoc={previewTemplate}
                    height="600"
                    width="100%"
                    allowFullScreen
                    style={{ border: "none" }}
                  />
                </Grid>
              </Grid>
            </Card>
          )}
        </Grid>
      </MDBox>
    </div>
  );
}
