import theme from "assets/theme";
import { useMaterialUIController } from "context";
import {
  Grid,
  List,
  DialogActions,
  DialogContent,
  ListSubheader,
  ListItemText,
  InputLabel,
  MenuItem,
  Select,
  Icon,
  FormControl,
  CircularProgress,
  Divider,
  makeStyles,
} from "@material-ui/core";
import { FeatureFlags } from "context/FeatureFlags";
import SimpleReactValidator from "simple-react-validator";
import MDInput from "components/MDInput";
import {
  Chip,
  Dialog,
  InputAdornment,
  ListItemButton,
  TextField,
  Tooltip,
  Card,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import MDButton from "components/MDButton";
import { useContext, useEffect, useRef, useState } from "react";
import "reactflow/dist/style.css";
import { ReactFlowProvider } from "reactflow";
import client from "ApiClient";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Flow from "./Flow";
import EmailCopy from "./emailCopy";

const useStyles = makeStyles((themes) => ({
  formControl: {
    margin: themes.spacing(1),
    minWidth: 120,
    maxWidth: 600,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: themes.spacing(3),
  },
}));

/*eslint-disable*/
export default function Add(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [showEvents, setShowEvents] = useState(true);
  const [showActions, setShowActions] = useState(false);
  const [showAddEvent, setShowAddEvent] = useState(false);
  const [showAddList, setShowAddList] = useState(false);
  const [showAddSerie, setShowAddSerie] = useState(false);
  const [showAddBroadcast, setShowAddBroadcast] = useState(false);
  const [showAddCatalog, setShowAddCatalog] = useState(false);
  const [showAddWaitFor, setShowAddWaitFor] = useState(false);
  const [showAddWebHook, setShowAddWebHook] = useState(false);
  const [showAddCopy, setShowAddCopy] = useState(false);
  const [showContainTag, setShowContainTag] = useState(false);
  const [showAddTag, setShowAddTag] = useState(false);
  const [showRemoveTag, setShowRemoveTag] = useState(false);
  const [showAddTagEvent, setShowAddTagEvent] = useState(false);
  const [showMakeInactive, setShowMakeInactive] = useState(false);
  const [openEmailCopy, setOpenEmailCopy] = useState(false);
  const [updateAction, setUpdateAction] = useState(false);
  const [saveEmailCopy, setSaveEmailCopy] = useState(false);
  const [conditionType, setConditionType] = useState("NONE");
  const { openModal, closeModal } = props;
  const [series, setSeries] = useState([]);
  const [selectedSeries, setSelectedSeries] = useState([]);
  const [broadcasts, setBroadcasts] = useState([]);
  const [selectedBroadcasts, setSelectedBroadcasts] = useState([]);
  const [catalogs, setCatalogs] = useState([]);
  const [selectedCatalogs, setSelectedCatalogs] = useState([]);
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState("Any");
  const [selectedItemId, setSelectedItemId] = useState(0);
  const [selectedWaitTime, setSelectedWaitTime] = useState("Minutes");
  const [waitDuration, setWaitDuration] = useState(1);
  const [selectedURLMethod, setSelectedURLMethod] = useState(0);
  const [triggerTargetURL, setTriggerTargetURL] = useState("");
  const [triggerBody, setTriggerBody] = useState("");
  const [tags, setTags] = useState([]);
  const [suggestionsTag, setSuggestionsTag] = useState([]);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [emailCopies, setEmailCopies] = useState([]);
  const [selectedEmailCopy, setSelectedEmailCopy] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [, forceUpdate] = useState();
  const ref = useRef();

  const simpleValidator = useRef(new SimpleReactValidator());
  const { features } = useContext(FeatureFlags);
  const filter = createFilterOptions();

  const [margin, setMargin] = useState(300);
  const [controller] = useMaterialUIController();
  const { miniSidenav } = controller;

  useEffect(() => {
    if (miniSidenav) setMargin(0);
    else setMargin(300);
  }, [miniSidenav]);

  const classes = useStyles();

  const [triggerMethod] = useState([
    { method: "GET", value: 0 },
    { method: "POST", value: 1 },
    { method: "PUT", value: 2 },
    { method: "DELETE", value: 3 },
  ]);

  const clearVariables = (reason) => {
    if (reason === "backdropClick") return;
    setUpdateAction(false);
    setShowAddWaitFor(false);
    setShowActions(false);
    setShowEvents(false);
    setShowAddList(false);
    setShowAddSerie(false);
    setShowAddBroadcast(false);
    setShowAddCatalog(false);
    setShowAddWebHook(false);
    setShowAddCopy(false);
    setOpenEmailCopy(false);
    setShowActions(false);
    setShowAddTag(false);
    setShowRemoveTag(false);
    setShowMakeInactive(false);
    setShowAddTagEvent(false);
    setShowEvents(true);

    setSelectedNodes([]);
    setSelectedSeries([]);
    setSelectedBroadcasts([]);
    setSelectedCatalogs([]);
    setSelectedItems("Any");
    setSelectedWaitTime("Minutes");
    setWaitDuration(1);
    setSelectedURLMethod(0);
    setSelectedItemId(0);
    setTriggerTargetURL("");
    setTriggerBody("");
    setTags([]);
    setEmailCopies([]);
    setSelectedEmailCopy(null);

    setName("");
    setDescription("");
    simpleValidator.current.hideMessages();
    closeModal();
  };

  const handleSave = () => {
    if (simpleValidator.current.allValid()) {
      const options = {
        method: "POST",
        url: `automations`,
        headers: {
          "content-type": "application/json",
        },
      };

      let autJson = ref.current.onSave();
      autJson.nodes.map((node) => {
        node.selected = false;
      });

      options.data = JSON.stringify({
        aut_name: name,
        aut_description: description,
        aut_json: JSON.stringify(autJson),
      });
      client
        .request(options)
        .then((response) => {
          setIsLoading(false);
          props.onDone();
          clearVariables();
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  const getIntervalMinutes = (duration, interval) => {
    switch (interval) {
      case "Minutes":
        return duration;
      case "Hours":
        return duration * 60;
      case "Days":
        return duration * 60 * 24;
    }
  };

  const getDurationByInterval = (duration, interval) => {
    switch (interval) {
      case "Minutes":
        return duration;
      case "Hours":
        return duration / 60;
      case "Days":
        return duration / 60 / 24;
    }
  };

  const onClickAdd = (event, data, type) => {
    console.log("TYPE", type);
    setConditionType(type);
    setUpdateAction(false);
    setShowAddWaitFor(false);
    setShowActions(false);
    setShowEvents(false);
    setShowAddList(false);
    setShowAddSerie(false);
    setShowAddBroadcast(false);
    setShowAddCatalog(false);
    setShowAddWebHook(false);
    setShowAddCopy(false);
    setSelectedNodes([data]);
    setShowAddTag(false);
    setShowRemoveTag(false);
    setShowMakeInactive(false);
    setShowAddTagEvent(false);
    setShowActions(true);
  };

  const addEvent = (event) => {
    const data = {
      aus_id: 0,
      aus_step_id: 0,
      aus_sub_id: 0,
      aus_type: "",
      aus_intervalMinutes: 0,
      aus_bro_id: 0,
      aus_ser_id: 0,
      aus_emc_id: 0,
      aus_sep_id: 0,
      aus_cat_id: 0,
      aus_item_id: 0,
      aus_tag: "",
      aus_triggerDetails: "",
      aus_additionalData: "",
    };
    switch (event) {
      case "addSubscriber":
        data.aus_type = "AUTOMATION_START_EVENT_SUBSCRIBER_ADDED";
        data.aus_tag = tags.join(",");
        if (updateAction) {
          ref.current.onUpdate(selectedNodes[0], data);
          setUpdateAction(false);
        } else ref.current.onAddEvent(data);
        setShowAddList(false);
        setTags([]);
        break;
      case "addTag":
        data.aus_type = "AUTOMATION_START_EVENT_TAG_ADDED";
        data.aus_tag = tags.join(",");
        if (updateAction) {
          ref.current.onUpdate(selectedNodes[0], data);
          setUpdateAction(false);
        } else ref.current.onAddEvent(data);
        setShowAddTagEvent(false);
        setTags([]);
        break;
      case "finishSeries":
        data.aus_type = "AUTOMATION_START_EVENT_FINISHED_SERIE";
        data.aus_ser_id = series
          .filter((serie) => serie.ser_name == selectedSeries)
          .map((serie) => serie.ser_id)[0];
        data.aus_additionalData = selectedSeries;

        if (updateAction) {
          ref.current.onUpdate(selectedNodes[0], data);
          setUpdateAction(false);
        } else ref.current.onAddEvent(data);
        setShowAddSerie(false);
        setSelectedSeries([]);
        break;
      case "engagedBroadcast":
        data.aus_type = "AUTOMATION_START_EVENT_ENGAGED_BROADCAST";
        data.aus_bro_id = broadcasts
          .filter((broadcast) => broadcast.bro_name == selectedBroadcasts)
          .map((broadcast) => broadcast.bro_id)[0];
        data.aus_additionalData = selectedBroadcasts;

        if (updateAction) {
          ref.current.onUpdate(selectedNodes[0], data);
          setUpdateAction(false);
        } else ref.current.onAddEvent(data);
        setShowAddBroadcast(false);
        setSelectedBroadcasts([]);
        break;
      case "engagedItem":
        data.aus_cat_id = catalogs
          .filter((catalog) => catalog.cat_name == selectedCatalogs)
          .map((catalog) => catalog.cat_id)[0];
        data.aus_item_id =
          selectedItems == "Any"
            ? 0
            : items
                .filter((item) => item.item_name == selectedItems)
                .map((item) => item.item_id)[0];
        data.aus_type =
          data.aus_item_id == 0
            ? "AUTOMATION_START_EVENT_ENGAGED_CATALOG"
            : "AUTOMATION_START_EVENT_ENGAGED_ITEM";

        data.aus_additionalData = selectedCatalogs + " - " + selectedItems;

        if (updateAction) {
          ref.current.onUpdate(selectedNodes[0], data);
          setUpdateAction(false);
        } else ref.current.onAddEvent(data);
        setShowAddCatalog(false);
        setSelectedCatalogs([]);
        setSelectedItems("Any");
        break;
      case "engagementLimit":
        data.aus_type = "AUTOMATION_START_EVENT_REACHED_NOENGAGEMENT";
        ref.current.onAddEvent(data);
        setShowEvents(false);
        break;
    }
    setShowEvents(true);
  };

  const addAction = (event) => {
    if (selectedNodes.length == 0) {
      alert("Please select a node");
      return;
    }
    const data = {
      aus_id: 0,
      aus_step_id: 0,
      aus_sub_id: 0,
      aus_type: "",
      aus_intervalMinutes: 0,
      aus_bro_id: 0,
      aus_ser_id: 0,
      aus_emc_id: 0,
      aus_sep_id: 0,
      aus_cat_id: 0,
      aus_item_id: 0,
      aus_tag: "",
      aus_triggerDetails: "",
      aus_additionalData: "",
    };
    switch (event) {
      case "waitFor":
        data.aus_type = "AUTOMATION_STEP_TYPE_WAIT_TIME";
        data.aus_intervalMinutes = getIntervalMinutes(waitDuration, selectedWaitTime);
        data.aus_additionalData = selectedWaitTime;

        if (updateAction) {
          ref.current.onUpdate(selectedNodes[0], data);
          setUpdateAction(false);
        } else ref.current.onAddAction(data, conditionType);

        setShowAddWaitFor(false);
        setSelectedWaitTime("Minutes");
        setWaitDuration(1);
        break;
      case "makeInactive":
        data.aus_type = "AUTOMATION_STEP_TYPE_PAUSE_SUBSCRIBER";
        data.aus_intervalMinutes = getIntervalMinutes(waitDuration, selectedWaitTime);
        data.aus_additionalData = selectedWaitTime;

        if (updateAction) {
          ref.current.onUpdate(selectedNodes[0], data);
          setUpdateAction(false);
        } else ref.current.onAddAction(data, conditionType);

        setShowMakeInactive(false);
        setSelectedWaitTime("Minutes");
        setWaitDuration(1);
        break;
      case "webHook":
        data.aus_type = "AUTOMATION_STEP_TYPE_CALL_WEBHOOK";
        data.aus_triggerDetails = JSON.stringify({
          tri_targetURL: triggerTargetURL,
          tri_body: triggerBody,
          tri_method: selectedURLMethod,
        });

        if (updateAction) {
          ref.current.onUpdate(selectedNodes[0], data);
          setUpdateAction(false);
        } else ref.current.onAddAction(data, conditionType);

        setTriggerTargetURL("");
        setTriggerBody("");
        setSelectedURLMethod(0);
        setShowAddWebHook(false);
        break;
      case "sendCopy":
        data.aus_type = "AUTOMATION_STEP_TYPE_SEND_EMAIL";
        data.aus_emc_id = selectedEmailCopy.emc_id;
        data.aus_additionalData = selectedEmailCopy.emc_subject;
        if (updateAction) {
          ref.current.onUpdate(selectedNodes[0], data);
          setUpdateAction(false);
        } else ref.current.onAddAction(data, conditionType);
        setSaveEmailCopy(false);
        break;
      case "engagedItem":
        data.type = "AUTOMATION_START_EVENT_ENGAGED_ITEM";
        data.aus_id = "";
        data.aus_step_id = "";
        data.aus_sub_id = "";
        data.aus_type = "";
        data.aus_intervalMinutes = "";
        data.aus_bro_id = "";
        data.aus_ser_id = "";
        data.aus_emc_id = "";
        data.aus_sep_id = "";
        data.aus_cat_id = "";
        data.aus_item_id = "";
        data.aus_tag = "";
        data.aus_triggerDetails = "";
        break;
      case "engagementLimit":
        data.type = "AUTOMATION_START_EVENT_REACHED_NOENGAGEMENT";
        data.aus_id = "";
        data.aus_step_id = "";
        data.aus_sub_id = "";
        data.aus_type = "";
        data.aus_intervalMinutes = "";
        data.aus_bro_id = "";
        data.aus_ser_id = "";
        data.aus_emc_id = "";
        data.aus_sep_id = "";
        data.aus_cat_id = "";
        data.aus_item_id = "";
        data.aus_tag = "";
        data.aus_triggerDetails = "";
        break;
      case "checkTag":
        data.aus_type = "AUTOMATION_STEP_TYPE_CHECK_TAG";
        data.aus_tag = tags.join(",");
        if (updateAction) {
          ref.current.onUpdate(selectedNodes[0], data);
          setUpdateAction(false);
        } else ref.current.onAddCondition(data);
        setShowContainTag(false);
        setTags([]);
        break;
      case "addTag":
        data.aus_type = "AUTOMATION_STEP_TYPE_ADD_TAG";
        data.aus_tag = tags.join(",");
        if (updateAction) {
          ref.current.onUpdate(selectedNodes[0], data);
          setUpdateAction(false);
        } else ref.current.onAddAction(data, conditionType);
        setShowAddTag(false);
        setTags([]);
        break;
      case "removeTag":
        data.aus_type = "AUTOMATION_STEP_TYPE_REMOVE_TAG";
        data.aus_tag = tags.join(",");
        if (updateAction) {
          ref.current.onUpdate(selectedNodes[0], data);
          setUpdateAction(false);
        } else ref.current.onAddAction(data, conditionType);
        setShowRemoveTag(false);
        setTags([]);
        break;
      case "unsubscribe":
        data.aus_type = "AUTOMATION_STEP_TYPE_UNSUBSCRIBE";
        ref.current.onAddAction(data, conditionType);
        break;
    }
  };

  const nodeSelect = (n) => {
    setUpdateAction(false);
    setShowAddWaitFor(false);
    setShowActions(false);
    setShowEvents(false);
    setShowAddList(false);
    setShowAddSerie(false);
    setShowAddBroadcast(false);
    setShowAddCatalog(false);
    setShowAddWebHook(false);
    setShowAddCopy(false);
    setOpenEmailCopy(false);
    setShowActions(false);
    setShowAddTag(false);
    setShowRemoveTag(false);
    setShowAddTagEvent(false);
    setShowMakeInactive(false);

    setSelectedNodes([]);
    setSelectedSeries([]);
    setSelectedBroadcasts([]);
    setSelectedCatalogs([]);
    setSelectedItems("Any");
    setSelectedWaitTime("Minutes");
    setWaitDuration(1);
    setSelectedURLMethod(0);
    setSelectedItemId(0);
    setTriggerTargetURL("");
    setTriggerBody("");
    setTags([]);
    setSelectedEmailCopy(null);

    if (n.length == 0) {
      setShowEvents(true);
      return;
    }

    setSelectedNodes(n);
    let node = n[0];

    switch (node.data.aus_type) {
      case "AUTOMATION_STEP_TYPE_WAIT_TIME":
        setShowAddWaitFor(true);
        setSelectedWaitTime(node.data.aus_additionalData);
        setWaitDuration(
          getDurationByInterval(node.data.aus_intervalMinutes, node.data.aus_additionalData)
        );
        setUpdateAction(true);
        break;
      case "AUTOMATION_STEP_TYPE_PAUSE_SUBSCRIBER":
        setShowMakeInactive(true);
        setSelectedWaitTime(node.data.aus_additionalData);
        setWaitDuration(
          getDurationByInterval(node.data.aus_intervalMinutes, node.data.aus_additionalData)
        );
        setUpdateAction(true);
        break;
      case "AUTOMATION_STEP_TYPE_WAIT_EVENT":
        break;
      case "AUTOMATION_STEP_TYPE_CALL_WEBHOOK":
        const triggerDetails = JSON.parse(node.data.aus_triggerDetails);
        setTriggerTargetURL(triggerDetails.tri_targetURL);
        setTriggerBody(triggerDetails.tri_body);
        setSelectedURLMethod(triggerDetails.tri_method);
        setShowAddWebHook(true);
        setUpdateAction(true);
        break;
      case "AUTOMATION_STEP_TYPE_SEND_EMAIL":
        setShowAddCopy(true);
        setUpdateAction(true);
        setSelectedEmailCopy(emailCopies.filter((ec) => ec.emc_id == node.data.aus_emc_id)[0]);
        break;
      case "AUTOMATION_START_EVENT_ENGAGED_CATALOG":
        if (items.length > 0) {
          node.data.aus_item_id == 0
            ? setSelectedItems("Any")
            : setSelectedItems(
                items
                  .filter((item) => item.item_id == node.data.aus_item_id)
                  .map((item) => item.item_name)[0]
              );
        }
        setSelectedItemId(node.data.aus_item_id);
        setSelectedCatalogs(
          catalogs.filter((cat) => cat.cat_id == node.data.aus_cat_id)[0].cat_name
        );
        setShowAddCatalog(true);
        setUpdateAction(true);
        break;
      case "AUTOMATION_STEP_TYPE_ADD_TAG":
        setTags(node.data.aus_tag.split(","));
        setUpdateAction(true);
        setShowAddTag(true);
        break;
      case "AUTOMATION_STEP_TYPE_REMOVE_TAG":
        setTags(node.data.aus_tag.split(","));
        setUpdateAction(true);
        setShowRemoveTag(true);
        break;
      case "AUTOMATION_START_EVENT_ENGAGED_ITEM":
        if (items.length > 0) {
          node.data.aus_item_id == 0
            ? setSelectedItems("Any")
            : setSelectedItems(
                items
                  .filter((item) => item.item_id == node.data.aus_item_id)
                  .map((item) => item.item_name)[0]
              );
        }
        setSelectedItemId(node.data.aus_item_id);
        setSelectedCatalogs(
          catalogs.filter((cat) => cat.cat_id == node.data.aus_cat_id)[0].cat_name
        );
        setShowAddCatalog(true);
        setUpdateAction(true);
        break;
      case "AUTOMATION_START_EVENT_TAG_ADDED":
        setTags(node.data.aus_tag.split(","));
        setShowAddTagEvent(true);
        setUpdateAction(true);
        break;
      case "AUTOMATION_START_EVENT_SUBSCRIBER_ADDED":
        setTags(node.data.aus_tag.split(","));
        setShowAddList(true);
        setUpdateAction(true);
        break;
      case "AUTOMATION_START_EVENT_FINISHED_SERIE":
        setSelectedSeries(node.data.aus_additionalData);
        setShowAddSerie(true);
        setUpdateAction(true);
        break;
      case "AUTOMATION_START_EVENT_ENGAGED_BROADCAST":
        setSelectedBroadcasts(node.data.aus_additionalData);
        setShowAddBroadcast(true);
        setUpdateAction(true);
        break;
      case "AUTOMATION_START_EVENT_REACHED_NOENGAGEMENT":
        break;
    }
  };

  const options = {
    method: "GET",
    url: `broadcasts/getTags`,
    headers: {
      "content-type": "application/json",
    },
  };

  const getTags = async () => {
    const options = {
      method: "GET",
      url: `broadcasts/getTags`,
      headers: {
        "content-type": "application/json",
      },
    };

    await client
      .request(options)
      .then((response) => {
        setSuggestionsTag([]);
        response.map((tag, index) => {
          setSuggestionsTag((prev) => [...prev, { id: index, name: tag }]);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSeries = async () => {
    options.method = "GET";
    options.url = `series`;
    await client
      .request(options)
      .then((response) => {
        setSeries(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getBroadcasts = async () => {
    options.method = "GET";
    options.url = `broadcasts`;
    await client
      .request(options)
      .then((response) => {
        setBroadcasts(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCatalogs = async () => {
    options.method = "GET";
    options.url = `catalogs`;
    await client
      .request(options)
      .then((response) => {
        setCatalogs(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getItems = async () => {
    options.method = "GET";
    options.url = `items`;
    options.params = {
      cat_id: catalogs
        .filter((cat) => cat.cat_name == selectedCatalogs)
        .map((catalog) => catalog.cat_id)[0],
    };
    await client
      .request(options)
      .then((response) => {
        setItems(response);
        if (selectedItemId > 0)
          setSelectedItems(
            response
              .filter((item) => item.item_id == selectedItemId)
              .map((item) => item.item_name)[0]
          );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (openModal) {
      getCatalogs();
      getSeries();
      getTags();
      getBroadcasts();
    }
  }, [openModal]);

  useEffect(() => {
    getItems();
  }, [selectedCatalogs]);

  useEffect(() => {
    if (saveEmailCopy) addAction("sendCopy");
  }, [selectedEmailCopy]);
  /*
  
  useEffect(() => {
    console.log(emailCopies);
  }, [emailCopies]);
*/
  return (
    <Dialog
      open={openModal}
      onClose={(e, reason) => clearVariables(reason)}
      fullScreen
      style={{ marginLeft: margin }}
      disableEnforceFocus
      disableEscapeKeyDown
      sx={{
        //You can copy the code below in your theme
        background: "#F4F0F700",
        "& .MuiPaper-root": {
          background: theme.palette.background.default,
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent", // Try to remove this to see the result
        },
      }}
    >
      <MDBox
        variant="gradient"
        bgColor="primary"
        borderRadius="lg"
        coloredShadow="primary"
        mx={2}
        mt={-1}
        p={2}
        mb={1}
        textAlign="center"
      >
        <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
          Create a new automaion
        </MDTypography>
      </MDBox>

      <DialogContent>
        <EmailCopy
          openModal={openEmailCopy}
          closeModal={() => setOpenEmailCopy(false)}
          onDone={(ec) => {
            setSaveEmailCopy(true);
            if (updateAction) {
              const idx = emailCopies.findIndex((e) => e.emc_id == ec.emc_id);
              let ecs = emailCopies;
              ecs[idx] = ec;
              setEmailCopies(ecs);
            } else {
              setEmailCopies((prev) => [...prev, ec]);
            }
            setSelectedEmailCopy(ec);
          }}
          emailCopy={selectedEmailCopy}
        />
        <Grid container spacing={2} style={{ height: "100%" }}>
          <Grid item xs={3}>
            <Card style={{ padding: 10, backgroundColor: "#FFFFFF", height: "100%" }}>
              <MDBox>
                <MDInput
                  style={{ padding: 10 }}
                  type="text"
                  label="Automation name"
                  fullWidth
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                <MDBox color="red">
                  {simpleValidator.current.message("Name", name, "required")}
                </MDBox>
              </MDBox>
              <MDBox>
                <MDInput
                  style={{ padding: 10 }}
                  type="text"
                  label="Description"
                  fullWidth
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                />
                <MDBox color="red">
                  {simpleValidator.current.message("Description", description, "required")}
                </MDBox>
              </MDBox>
              <Divider />
              <MDBox mt={2}>
                {showEvents && (
                  <List
                    style={{ overflow: "auto" }}
                    subheader={
                      <ListSubheader component="div" id="nested-list-subheader">
                        Triggers
                      </ListSubheader>
                    }
                  >
                    <ListItemButton
                      key="addSubscriber"
                      onClick={() => {
                        setShowEvents(false);
                        setShowAddList(true);
                      }}
                    >
                      <ListItemText
                        primary="Subscriber added"
                        secondary="Automation starts when a new contact is added to a tag"
                      />
                    </ListItemButton>
                    <ListItemButton
                      key="addTag"
                      onClick={() => {
                        setShowEvents(false);
                        setShowAddTagEvent(true);
                      }}
                    >
                      <ListItemText
                        primary="Subscriber gets tagged"
                        secondary="Automation starts when a tag gets added to a contact"
                      />
                    </ListItemButton>
                    {!features.hiddenMenus.includes("series") && (
                      <ListItemButton
                        key="finishSeries"
                        onClick={() => {
                          setShowEvents(false);
                          setShowAddSerie(true);
                        }}
                      >
                        {" "}
                        <ListItemText
                          primary="Finish a serie"
                          secondary="Start automation when a subscriber finish a serie"
                        />
                      </ListItemButton>
                    )}
                    <ListItemButton
                      key="engagedBroadcast"
                      onClick={() => {
                        setShowEvents(false);
                        setShowAddBroadcast(true);
                      }}
                    >
                      <ListItemText
                        primary="Engaged with a broadcast"
                        secondary="Automation starts when a subscriber clicks a link in a broadcast"
                      />
                    </ListItemButton>
                    {!features.hiddenMenus.includes("catalogs") && (
                      <ListItemButton
                        key="engagedItem"
                        onClick={() => {
                          setShowEvents(false);
                          setShowAddCatalog(true);
                        }}
                      >
                        <ListItemText
                          primary="Engaged with a catalog/item"
                          secondary="Automation starts when a subscriber engaged with a catalog/item"
                        />
                      </ListItemButton>
                    )}
                    <ListItemButton
                      key="engagementLimit"
                      onClick={() => {
                        addEvent("engagementLimit");
                      }}
                    >
                      <ListItemText
                        primary="Reached no engagement limit"
                        secondary="Automation starts when a subscriber reaches no engagement limit"
                      />
                    </ListItemButton>
                  </List>
                )}
                {showActions && (
                  <List
                    style={{ overflow: "auto" }}
                    subheader={
                      <ListSubheader component="div" id="nested-list-subheader">
                        Actions
                      </ListSubheader>
                    }
                  >
                    <ListItemButton
                      key="waitFor"
                      onClick={() => {
                        setShowActions(false);
                        setShowAddWaitFor(true);
                      }}
                    >
                      <ListItemText
                        primary="Wait for"
                        secondary="Add a pause between actions in this automation."
                      />
                    </ListItemButton>
                    {/*
                    <ListItemButton
                      key="makeInactive"
                      onClick={() => {
                        setShowActions(false);
                        setShowMakeInactive(true);
                      }}
                    >
                      <ListItemText
                        primary="Pause mailing"
                        secondary="Set a pause time period before sending any other emails."
                      />
                    </ListItemButton>
                    */}
                    <ListItemButton
                      key="webHook"
                      onClick={() => {
                        setShowActions(false);
                        setShowAddWebHook(true);
                      }}
                    >
                      <ListItemText primary="Call a webhook" secondary="Call a webhook" />
                    </ListItemButton>
                    <ListItemButton
                      key="sendCopy"
                      onClick={() => {
                        setShowActions(false);
                        setShowAddCopy(true);
                      }}
                    >
                      <ListItemText
                        primary="Send an email"
                        secondary="Send an email copy to the subscriber"
                      />
                    </ListItemButton>
                    <ListItemButton
                      key="containTag"
                      onClick={() => {
                        setTags([]);
                        setShowActions(false);
                        setShowContainTag(true);
                      }}
                    >
                      <ListItemText
                        primary="Contain a tag"
                        secondary="The subscriber contains the following(s) tag(s)"
                      />
                    </ListItemButton>
                    <ListItemButton
                      key="addTag"
                      onClick={() => {
                        setTags([]);
                        setShowActions(false);
                        setShowAddTag(true);
                      }}
                    >
                      <ListItemText primary="Add a tag" secondary="Add a tag to the subscriber" />
                    </ListItemButton>
                    <ListItemButton
                      key="removeTag"
                      onClick={() => {
                        setTags([]);
                        setShowActions(false);
                        setShowRemoveTag(true);
                      }}
                    >
                      <ListItemText
                        primary="Remove a tag"
                        secondary="Remove a tag to the subscriber"
                      />
                    </ListItemButton>
                    <ListItemButton
                      key="unsubscribe"
                      onClick={() => {
                        addAction("unsubscribe");
                      }}
                    >
                      <ListItemText primary="Unsubscribe" secondary="Unsubscribe the subscriber" />
                    </ListItemButton>
                  </List>
                )}
                {showAddList && (
                  <Card style={{ padding: 5, margin: 5 }}>
                    <MDBox>
                      <MDTypography variant="h4">Subscribes to a list</MDTypography>
                      <MDTypography variant="subtitle2">
                        Contacts will enter this automation when subscribed with the following
                        tag(s)
                      </MDTypography>
                    </MDBox>
                    <MDBox mt={2} mb={2}>
                      <MDBox display="flex">
                        <MDTypography variant="h6" fontWeight="medium">
                          Tags
                        </MDTypography>
                        <Tooltip title="Type to search or add a new tag and press enter">
                          <Icon style={{ marginTop: 0, marginLeft: 5 }}>info</Icon>
                        </Tooltip>
                      </MDBox>
                      <Autocomplete
                        value={tags}
                        onChange={(event, newValue) => {
                          setTags(
                            newValue.map((tag) =>
                              tag.indexOf('Create new tag "') === -1
                                ? tag
                                : tag.substring(16).slice(0, -1)
                            )
                          );
                        }}
                        multiple
                        id="tags-filled"
                        options={suggestionsTag.map((option) => option.name)}
                        freeSolo
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Type to search or add a new tag and press enter"
                          />
                        )}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);
                          const { inputValue } = params;
                          // Suggest the creation of a new value
                          const isExisting = options.some((option) => inputValue === option);
                          if (inputValue.trim() !== "" && !isExisting) {
                            filtered.push(`Create new tag "${inputValue}"`);
                          }
                          return filtered;
                        }}
                      />
                    </MDBox>
                    <MDBox display="flex">
                      <MDButton
                        onClick={() => {
                          setShowAddList(false);
                          setUpdateAction(false);
                          setShowEvents(true);
                        }}
                        color="secondary"
                        variant="outlined"
                      >
                        Close
                      </MDButton>
                      <MDButton
                        style={{ marginLeft: 5 }}
                        onClick={() => addEvent("addSubscriber")}
                        color="success"
                        variant="gradient"
                      >
                        Save
                      </MDButton>
                    </MDBox>
                  </Card>
                )}
                {showAddTagEvent && (
                  <Card style={{ padding: 5, margin: 5 }}>
                    <MDBox>
                      <MDTypography variant="h4">Subscriber gets tagged</MDTypography>
                      <MDTypography variant="subtitle2">
                        Automation starts when a tag gets added to a contact
                      </MDTypography>
                    </MDBox>
                    <MDBox mt={2} mb={2}>
                      <MDBox display="flex">
                        <MDTypography variant="h6" fontWeight="medium">
                          Tags
                        </MDTypography>
                        <Tooltip title="Type to search or add a new tag and press enter">
                          <Icon style={{ marginTop: 0, marginLeft: 5 }}>info</Icon>
                        </Tooltip>
                      </MDBox>
                      <Autocomplete
                        value={tags}
                        onChange={(event, newValue) => {
                          setTags(
                            newValue.map((tag) =>
                              tag.indexOf('Create new tag "') === -1
                                ? tag
                                : tag.substring(16).slice(0, -1)
                            )
                          );
                        }}
                        multiple
                        id="tags-filled"
                        options={suggestionsTag.map((option) => option.name)}
                        freeSolo
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Type to search or add a new tag and press enter"
                          />
                        )}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);
                          const { inputValue } = params;
                          // Suggest the creation of a new value
                          const isExisting = options.some((option) => inputValue === option);
                          if (inputValue.trim() !== "" && !isExisting) {
                            filtered.push(`Create new tag "${inputValue}"`);
                          }
                          return filtered;
                        }}
                      />
                    </MDBox>
                    <MDBox display="flex">
                      <MDButton
                        onClick={() => {
                          setShowAddTagEvent(false);
                          setUpdateAction(false);
                          setShowEvents(true);
                        }}
                        color="secondary"
                        variant="outlined"
                      >
                        Close
                      </MDButton>
                      <MDButton
                        style={{ marginLeft: 5 }}
                        onClick={() => addEvent("addTag")}
                        color="success"
                        variant="gradient"
                      >
                        Save
                      </MDButton>
                    </MDBox>
                  </Card>
                )}
                {showAddSerie && (
                  <Card style={{ padding: 5, margin: 5 }}>
                    <MDBox>
                      <MDTypography variant="h4">Finished a serie</MDTypography>
                      <MDTypography variant="subtitle2">
                        Contacts will enter this automation when finished the following serie
                      </MDTypography>
                    </MDBox>
                    <MDBox mt={2} mb={2}>
                      <Autocomplete
                        value={selectedSeries}
                        onChange={(event, newValue) => {
                          setSelectedSeries(newValue);
                        }}
                        id="tags-filled"
                        options={series.map((option) => option.ser_name)}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Type to search" />
                        )}
                      />
                    </MDBox>
                    <MDBox display="flex">
                      <MDButton
                        onClick={() => {
                          setShowAddSerie(false);
                          setUpdateAction(false);
                          setShowEvents(true);
                        }}
                        color="secondary"
                        variant="outlined"
                      >
                        Close
                      </MDButton>
                      <MDButton
                        style={{ marginLeft: 5 }}
                        onClick={() => addEvent("finishSeries")}
                        color="success"
                        variant="gradient"
                      >
                        Save
                      </MDButton>
                    </MDBox>
                  </Card>
                )}

                {showAddBroadcast && (
                  <Card style={{ padding: 5, margin: 5 }}>
                    <MDBox>
                      <MDTypography variant="h4">Engaged with a broadcast</MDTypography>
                      <MDTypography variant="subtitle2">
                        Contacts will enter this automation when engage the following broadcast
                      </MDTypography>
                    </MDBox>
                    <MDBox mt={2} mb={2}>
                      <Autocomplete
                        value={selectedBroadcasts}
                        onChange={(event, newValue) => {
                          setSelectedBroadcasts(newValue);
                        }}
                        id="tags-filled"
                        options={broadcasts.map((option) => option.bro_name)}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Type to search" />
                        )}
                      />
                    </MDBox>
                    <MDBox display="flex">
                      <MDButton
                        onClick={() => {
                          setUpdateAction(false);
                          setShowAddBroadcast(false);
                          setShowEvents(true);
                        }}
                        color="secondary"
                        variant="outlined"
                      >
                        Close
                      </MDButton>
                      <MDButton
                        style={{ marginLeft: 5 }}
                        onClick={() => addEvent("engagedBroadcast")}
                        color="success"
                        variant="gradient"
                      >
                        Save
                      </MDButton>
                    </MDBox>
                  </Card>
                )}

                {showAddCatalog && (
                  <Card style={{ padding: 5, margin: 5 }}>
                    <MDBox>
                      <MDTypography variant="h4">Engaged catalog/item</MDTypography>
                      <MDTypography variant="subtitle2">
                        Contacts will enter this automation when engage the following catalog/item
                      </MDTypography>
                    </MDBox>

                    <MDBox mt={2} mb={2}>
                      <Autocomplete
                        value={selectedCatalogs}
                        onChange={(event, newValue) => {
                          setSelectedCatalogs(newValue);
                        }}
                        id="tags-filled"
                        options={catalogs.map((option) => option.cat_name)}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Type to search" />
                        )}
                      />
                    </MDBox>
                    <MDBox>
                      <FormControl className={classes.formControl}>
                        <InputLabel>pick the item</InputLabel>
                        <Select
                          placeholder="Select the item"
                          fullWidth
                          value={selectedItems}
                          onChange={(e) => setSelectedItems(e.target.value)}
                        >
                          <MenuItem key={0} value="Any">
                            <ListItemText primary="Any" />
                          </MenuItem>
                          {items.map((item) => (
                            <MenuItem key={item.item_id} value={item.item_name}>
                              <ListItemText primary={item.item_name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </MDBox>
                    <MDBox display="flex">
                      <MDButton
                        onClick={() => {
                          setUpdateAction(false);
                          setShowAddCatalog(false);
                          setShowEvents(true);
                        }}
                        color="secondary"
                        variant="outlined"
                      >
                        Close
                      </MDButton>
                      <MDButton
                        style={{ marginLeft: 5 }}
                        onClick={() => addEvent("engagedItem")}
                        color="success"
                        variant="gradient"
                      >
                        Save
                      </MDButton>
                    </MDBox>
                  </Card>
                )}
                {showAddWaitFor && (
                  <Card style={{ padding: 5, margin: 5 }}>
                    <MDBox>
                      <MDTypography variant="h4">Set pause time</MDTypography>
                      <MDTypography variant="subtitle2">
                        Add a pause between actions in this automation
                      </MDTypography>
                    </MDBox>

                    <MDBox mt={2} mb={2}>
                      <FormControl className={classes.formControl}>
                        <MDInput
                          fullWidth
                          required
                          style={{ paddingRight: 20 }}
                          type="number"
                          placeholder="Enter a number"
                          label="Duration"
                          value={waitDuration}
                          onChange={(e) => {
                            setWaitDuration(e.target.value);
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Select
                                  placeholder="Select the catalog"
                                  fullWidth
                                  value={selectedWaitTime}
                                  onChange={(e) => setSelectedWaitTime(e.target.value)}
                                >
                                  <MenuItem key={0} value="Minutes">
                                    <ListItemText primary="Minutes" />
                                  </MenuItem>
                                  <MenuItem key={1} value="Hours">
                                    <ListItemText primary="Hours" />
                                  </MenuItem>
                                  <MenuItem key={2} value="Days">
                                    <ListItemText primary="Days" />
                                  </MenuItem>
                                </Select>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    </MDBox>
                    <MDBox display="flex">
                      <MDButton
                        onClick={() => {
                          setUpdateAction(false);
                          setShowAddWaitFor(false);
                          setShowActions(true);
                        }}
                        color="secondary"
                        variant="outlined"
                      >
                        Close
                      </MDButton>
                      <MDButton
                        style={{ marginLeft: 5 }}
                        onClick={() => addAction("waitFor")}
                        color="success"
                        variant="gradient"
                      >
                        Save
                      </MDButton>
                    </MDBox>
                  </Card>
                )}
                {showMakeInactive && (
                  <Card style={{ padding: 5, margin: 5 }}>
                    <MDBox>
                      <MDTypography variant="h4">Pause mailing to subcribers</MDTypography>
                      <MDTypography variant="subtitle2">
                        Set a pause time period before sending any more emails.
                      </MDTypography>
                    </MDBox>

                    <MDBox mt={2} mb={2}>
                      <FormControl className={classes.formControl}>
                        <MDInput
                          fullWidth
                          required
                          style={{ paddingRight: 20 }}
                          type="number"
                          placeholder="Enter a number"
                          label="Duration"
                          value={waitDuration}
                          onChange={(e) => {
                            setWaitDuration(e.target.value);
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Select
                                  placeholder="Select the catalog"
                                  fullWidth
                                  value={selectedWaitTime}
                                  onChange={(e) => setSelectedWaitTime(e.target.value)}
                                >
                                  <MenuItem key={0} value="Minutes">
                                    <ListItemText primary="Minutes" />
                                  </MenuItem>
                                  <MenuItem key={1} value="Hours">
                                    <ListItemText primary="Hours" />
                                  </MenuItem>
                                  <MenuItem key={2} value="Days">
                                    <ListItemText primary="Days" />
                                  </MenuItem>
                                </Select>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    </MDBox>
                    <MDBox display="flex">
                      <MDButton
                        onClick={() => {
                          setUpdateAction(false);
                          setShowMakeInactive(false);
                          setShowActions(true);
                        }}
                        color="secondary"
                        variant="outlined"
                      >
                        Close
                      </MDButton>
                      <MDButton
                        style={{ marginLeft: 5 }}
                        onClick={() => addAction("makeInactive")}
                        color="success"
                        variant="gradient"
                      >
                        Save
                      </MDButton>
                    </MDBox>
                  </Card>
                )}
                {showAddWebHook && (
                  <Card style={{ padding: 5, margin: 5 }}>
                    <MDBox>
                      <MDTypography variant="h4">Call webhook</MDTypography>
                      <MDTypography variant="subtitle2">
                        Call a webhook (external event)
                      </MDTypography>
                    </MDBox>

                    <MDBox mt={2} mb={2}>
                      <FormControl className={classes.formControl}>
                        <MDBox pb={2} display="flex" justifyContent="left">
                          <MDTypography variant="h6" fontWeight="medium" ml={1} mr={2}>
                            Method
                          </MDTypography>
                          <Select
                            onChange={(event) => setSelectedURLMethod(event.target.value)}
                            value={selectedURLMethod}
                          >
                            {triggerMethod.map((item) => (
                              <MenuItem key={item.value} value={item.value}>
                                {item.method}
                              </MenuItem>
                            ))}
                          </Select>
                        </MDBox>
                        <MDBox m={2}>
                          <MDInput
                            type="text"
                            label="Target URL"
                            fullWidth
                            value={triggerTargetURL}
                            onChange={(e) => setTriggerTargetURL(e.target.value)}
                            required
                          />
                        </MDBox>
                        <MDBox m={2}>
                          <MDInput
                            type="text"
                            label="JSON body"
                            fullWidth
                            value={triggerBody}
                            onChange={(e) => setTriggerBody(e.target.value)}
                            required
                          />
                        </MDBox>
                      </FormControl>
                    </MDBox>
                    <MDBox display="flex">
                      <MDButton
                        onClick={() => {
                          setUpdateAction(false);
                          setShowAddWebHook(false);
                          setShowActions(true);
                        }}
                        color="secondary"
                        variant="outlined"
                      >
                        Close
                      </MDButton>
                      <MDButton
                        style={{ marginLeft: 5 }}
                        onClick={() => addAction("webHook")}
                        color="success"
                        variant="gradient"
                      >
                        Save
                      </MDButton>
                    </MDBox>
                  </Card>
                )}

                {showAddCopy && (
                  <Card style={{ padding: 5, margin: 5 }}>
                    <MDBox>
                      <MDTypography variant="h4">Send copy</MDTypography>
                      <MDTypography variant="subtitle2">Send a email copy</MDTypography>
                    </MDBox>
                    {selectedEmailCopy != null && (
                      <MDBox mt={2} mb={2}>
                        <MDBox display="flex">
                          <MDTypography variant="h6">Subject:</MDTypography>
                          <MDTypography variant="body2">
                            {selectedEmailCopy ? selectedEmailCopy.emc_subject : ""}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    )}
                    <MDBox display="flex">
                      <MDButton
                        onClick={() => {
                          setUpdateAction(false);
                          setShowAddCopy(false);
                          setShowActions(true);
                        }}
                        color="secondary"
                        variant="outlined"
                      >
                        Close
                      </MDButton>
                      {selectedEmailCopy == null ? (
                        <MDButton
                          style={{ marginLeft: 5 }}
                          onClick={() => {
                            setSelectedEmailCopy(null);
                            setOpenEmailCopy(true);
                          }}
                          color="success"
                          variant="gradient"
                        >
                          Create
                        </MDButton>
                      ) : (
                        <MDButton
                          style={{ marginLeft: 5 }}
                          onClick={() => setOpenEmailCopy(true)}
                          color="success"
                          variant="gradient"
                        >
                          Edit
                        </MDButton>
                      )}
                    </MDBox>
                  </Card>
                )}

                {showContainTag && (
                  <Card style={{ padding: 5, margin: 5 }}>
                    <MDBox>
                      <MDTypography variant="h4">Check a tag</MDTypography>
                      <MDTypography variant="subtitle2">
                        The subscriber contains the following tag(s)
                      </MDTypography>
                    </MDBox>
                    <MDBox mt={2} mb={2}>
                      <MDBox display="flex">
                        <MDTypography variant="h6" fontWeight="medium">
                          Tags
                        </MDTypography>
                        <Tooltip title="Type to search or add a new tag and press enter">
                          <Icon style={{ marginTop: 0, marginLeft: 5 }}>info</Icon>
                        </Tooltip>
                      </MDBox>
                      <Autocomplete
                        value={tags}
                        onChange={(event, newValue) => {
                          setTags(
                            newValue.map((tag) =>
                              tag.indexOf('Create new tag "') === -1
                                ? tag
                                : tag.substring(16).slice(0, -1)
                            )
                          );
                        }}
                        multiple
                        id="tags-filled"
                        options={suggestionsTag.map((option) => option.name)}
                        freeSolo
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Type to search or add a new tag and press enter"
                          />
                        )}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);
                          const { inputValue } = params;
                          // Suggest the creation of a new value
                          const isExisting = options.some((option) => inputValue === option);
                          if (inputValue.trim() !== "" && !isExisting) {
                            filtered.push(`Create new tag "${inputValue}"`);
                          }
                          return filtered;
                        }}
                      />
                    </MDBox>
                    <MDBox display="flex">
                      <MDButton
                        onClick={() => {
                          setShowContainTag(false);
                          setUpdateAction(false);
                          setShowActions(true);
                        }}
                        color="secondary"
                        variant="outlined"
                      >
                        Close
                      </MDButton>
                      <MDButton
                        style={{ marginLeft: 5 }}
                        onClick={() => addAction("checkTag")}
                        color="success"
                        variant="gradient"
                      >
                        Save
                      </MDButton>
                    </MDBox>
                  </Card>
                )}
                {showAddTag && (
                  <Card style={{ padding: 5, margin: 5 }}>
                    <MDBox>
                      <MDTypography variant="h4">Add a tag</MDTypography>
                      <MDTypography variant="subtitle2">
                        Add the following tag(s) to the subscriber
                      </MDTypography>
                    </MDBox>
                    <MDBox mt={2} mb={2}>
                      <MDBox display="flex">
                        <MDTypography variant="h6" fontWeight="medium">
                          Tags
                        </MDTypography>
                        <Tooltip title="Type to search or add a new tag and press enter">
                          <Icon style={{ marginTop: 0, marginLeft: 5 }}>info</Icon>
                        </Tooltip>
                      </MDBox>
                      <Autocomplete
                        value={tags}
                        onChange={(event, newValue) => {
                          setTags(
                            newValue.map((tag) =>
                              tag.indexOf('Create new tag "') === -1
                                ? tag
                                : tag.substring(16).slice(0, -1)
                            )
                          );
                        }}
                        multiple
                        id="tags-filled"
                        options={suggestionsTag.map((option) => option.name)}
                        freeSolo
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Type to search or add a new tag and press enter"
                          />
                        )}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);
                          const { inputValue } = params;
                          // Suggest the creation of a new value
                          const isExisting = options.some((option) => inputValue === option);
                          if (inputValue.trim() !== "" && !isExisting) {
                            filtered.push(`Create new tag "${inputValue}"`);
                          }
                          return filtered;
                        }}
                      />
                    </MDBox>
                    <MDBox display="flex">
                      <MDButton
                        onClick={() => {
                          setShowAddTag(false);
                          setUpdateAction(false);
                          setShowActions(true);
                        }}
                        color="secondary"
                        variant="outlined"
                      >
                        Close
                      </MDButton>
                      <MDButton
                        style={{ marginLeft: 5 }}
                        onClick={() => addAction("addTag")}
                        color="success"
                        variant="gradient"
                      >
                        Save
                      </MDButton>
                    </MDBox>
                  </Card>
                )}
                {showRemoveTag && (
                  <Card style={{ padding: 5, margin: 5 }}>
                    <MDBox>
                      <MDTypography variant="h4">Remove a tag</MDTypography>
                      <MDTypography variant="subtitle2">
                        Remove the following tag(s) to the subscriber
                      </MDTypography>
                    </MDBox>
                    <MDBox mt={2} mb={2}>
                      <MDBox display="flex">
                        <MDTypography variant="h6" fontWeight="medium">
                          Tags
                        </MDTypography>
                        <Tooltip title="Type to search or add a new tag and press enter">
                          <Icon style={{ marginTop: 0, marginLeft: 5 }}>info</Icon>
                        </Tooltip>
                      </MDBox>
                      <Autocomplete
                        value={tags}
                        onChange={(event, newValue) => {
                          setTags(
                            newValue.map((tag) =>
                              tag.indexOf('Create new tag "') === -1
                                ? tag
                                : tag.substring(16).slice(0, -1)
                            )
                          );
                        }}
                        multiple
                        id="tags-filled"
                        options={suggestionsTag.map((option) => option.name)}
                        freeSolo
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Type to search or add a new tag and press enter"
                          />
                        )}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);
                          const { inputValue } = params;
                          // Suggest the creation of a new value
                          const isExisting = options.some((option) => inputValue === option);
                          if (inputValue.trim() !== "" && !isExisting) {
                            filtered.push(`Create new tag "${inputValue}"`);
                          }
                          return filtered;
                        }}
                      />
                    </MDBox>
                    <MDBox display="flex">
                      <MDButton
                        onClick={() => {
                          setShowRemoveTag(false);
                          setUpdateAction(false);
                          setShowActions(true);
                        }}
                        color="secondary"
                        variant="outlined"
                      >
                        Close
                      </MDButton>
                      <MDButton
                        style={{ marginLeft: 5 }}
                        onClick={() => addAction("removeTag")}
                        color="success"
                        variant="gradient"
                      >
                        Save
                      </MDButton>
                    </MDBox>
                  </Card>
                )}
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={9}>
            <Card style={{ width: "100%", height: "100%", backgroundColor: "white" }}>
              <ReactFlowProvider>
                <Flow
                  ref={ref}
                  onSelectNode={(n) => nodeSelect(n)}
                  onClickAdd={(e, node, type) => onClickAdd(e, node, type)}
                  nodes={[]}
                  edges={[]}
                />
              </ReactFlowProvider>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions style={{ color: "white", backgroundColor: "white" }}>
        <MDButton
          onClick={clearVariables}
          disabled={isLoading}
          variant="outlined"
          color="secondary"
        >
          Cancel
        </MDButton>
        <MDButton onClick={handleSave} disabled={isLoading} variant="gradient" color="success">
          Save
          {isLoading && (
            <CircularProgress size={24} style={{ marginLeft: 15, position: "relative", top: 4 }} />
          )}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
