import { useEffect, useRef, useState } from "react";
// react-router-dom components
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import client from "ApiClient";
import SimpleReactValidator from "simple-react-validator";
import {
  Autocomplete,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import MDButton from "components/MDButton";
import csc from "country-state-city";
import { DialogTitle, FormLabel, ListItemText, MenuItem, Select } from "@material-ui/core";
import { createFilterOptions } from "@mui/base";

/* eslint-disable */
function Add(props) {
  const [errorSB, setErrorSB] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [updateText, setUpdateText] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("Select a country");
  const [state, setState] = useState("Select a state");
  const [city, setCity] = useState("Select a city");
  const [zip, setZip] = useState("");
  const [education, setEducation] = useState("");
  const [children, setChildren] = useState("");
  const [ethnicity, setEthnicity] = useState("");
  const [employment, setEmployment] = useState("");
  const [tags, setTags] = useState([]);
  const [suggestionsTag, setSuggestionsTag] = useState([]);
  const { openModal, closeModal } = props;
  const closeErrorSB = () => setErrorSB(false);
  const [, forceUpdate] = useState();
  const simpleValidator = useRef(new SimpleReactValidator());
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const countries = csc.getAllCountries();
  const filter = createFilterOptions();
  const clearVariables = () => {
    setEmail("");
    setFirstName("");
    setLastName("");
    setPhone("");
    setCountry("Select a country");
    setState("Select a state");
    setCity("Select a city");
    setZip("");
    setEducation("");
    setChildren("");
    setEthnicity("");
    setEmployment("");
    setUpdateText("");
    setErrorSB(false);
    setTags([]);

    simpleValidator.current.hideMessages();
    closeModal();
  };

  const handleSave = () => {
    if (simpleValidator.current.allValid()) {
      setIsLoading(true);
      const options = {
        method: "POST",
        url: `subscribers`,
        headers: {
          "content-type": "application/json",
        },
      };

      options.data = JSON.stringify({
        sub_email: email,
        sub_first: firstName,
        sub_last: lastName,
        sub_phone: phone,
        sub_country: country == "Select a country" ? "" : country,
        sub_state: state == "Select a state" ? "" : state,
        sub_city: city == "Select a city" ? "" : city,
        sub_zip: zip,
        sub_source: "",
        sub_optinIP: "",
        sub_gender: 0,
        sub_dateOfBirth: null,
        sub_optinDate: null,
        sub_lastEngagementDate: null,
        sub_status: 0,
        sub_swe: 0,
        sub_emc_id: 0,
        sub_per_id: 0,
        sub_education: education,
        sub_children: children,
        sub_ethnicity: ethnicity,
        sub_employment: employment,
        sub_tags: tags.join(","),
      });

      client
        .request(options)
        .then((response) => {
          setIsLoading(false);
          switch (response) {
            case 0: // normal
              setUpdateText(
                "This subscriber already exists in your list. The new tag(s) will be added."
              );
              setErrorSB(true);
              break;
            case 1: // unsubscribed
              setUpdateText(
                "This subscriber already exists in your list. The current status is: Unsubscribed. Status will be updated to normal, and the new tag(s) will be added."
              );
              setErrorSB(true);
              break;
            case 2: // complained
              setUpdateText(
                "This subscriber already exists in your list. The current status is: Complained. Status will be updated to normal, and the new tag(s) will be added."
              );
              setErrorSB(true);
              break;
            case 3: // bounced
              setUpdateText(
                "This subscriber already exists in your list. The current status is: Bounced. Status will be updated to normal, and the new tag(s) will be added."
              );
              setErrorSB(true);
              break;
            case 8: // bounced
              setUpdateText(
                "This subscriber already exists in your list. The current status is: Re engagement. Status will be updated to normal, and the new tag(s) will be added."
              );
              setErrorSB(true);
              break;
            case 10: // bounced
              setUpdateText(
                "This subscriber already exists in your list. The current status is: Deleted. Status will be updated to normal, and the new tag(s) will be added."
              );
              setErrorSB(true);
              break;
            default:
              props.onDone();
              clearVariables();
              break;
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    } else {
      setIsLoading(false);
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  const handleUpdate = () => {
    if (simpleValidator.current.allValid()) {
      setIsLoading(true);
      const options = {
        method: "PUT",
        url: `subscribers/update`,
        headers: {
          "content-type": "application/json",
        },
      };

      options.data = JSON.stringify({
        sub_email: email,
        sub_first: firstName,
        sub_last: lastName,
        sub_phone: phone,
        sub_country: country == "Select a country" ? "" : country,
        sub_state: state == "Select a state" ? "" : state,
        sub_city: city == "Select a city" ? "" : city,
        sub_zip: zip,
        sub_source: "",
        sub_optinIP: "",
        sub_gender: 0,
        sub_dateOfBirth: null,
        sub_optinDate: null,
        sub_lastEngagementDate: null,
        sub_status: 0,
        sub_swe: 0,
        sub_emc_id: 0,
        sub_per_id: 0,
        sub_education: education,
        sub_children: children,
        sub_ethnicity: ethnicity,
        sub_employment: employment,
        sub_tags: tags.join(","),
      });

      client
        .request(options)
        .then((response) => {
          console.log(response);

          setIsLoading(false);
          props.onDone();
          clearVariables();
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    } else {
      setIsLoading(false);
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  const getTags = async () => {
    const options = {
      method: "GET",
      url: `broadcasts/getTags`,
      headers: {
        "content-type": "application/json",
      },
    };

    await client
      .request(options)
      .then((response) => {
        setSuggestionsTag([]);
        response.map((tag, index) => {
          setSuggestionsTag((prev) => [...prev, { id: index, name: tag }]);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getTags();
  }, []);

  useEffect(() => {
    if (country.trim() != "Select a country" && country != "" && country != null) {
      setStates(csc.getStatesOfCountry(countries.filter((c) => c.name == country)[0].id));
      setState("Select a state");
    }
  }, [country]);

  useEffect(() => {
    if (state.trim() != "Select a state" && state != "" && state != null) {
      if (state == "") return;
      setCities(csc.getCitiesOfState(states.filter((s) => s.name == state)[0].id));
      setCity("Select a city");
    }
  }, [state]);

  const renderErrorSB = (
    <Dialog open={errorSB}>
      <DialogTitle>Subscriber already exists</DialogTitle>
      <DialogContent>
        <MDTypography>{updateText}</MDTypography>
      </DialogContent>
      <DialogActions style={{ color: "white", backgroundColor: "white" }}>
        <MDButton autoFocus onClick={closeErrorSB} variant="gradient" color="secondary">
          No
        </MDButton>
        <MDButton autoFocus variant="gradient" color="error" onClick={() => handleUpdate()}>
          yes
        </MDButton>
      </DialogActions>
    </Dialog>
  );

  return (
    <Dialog
      open={openModal}
      onClose={clearVariables}
      aria-labelledby="responsive-dialog-title"
      fullWidth
    >
      <MDBox
        variant="gradient"
        bgColor="primary"
        borderRadius="lg"
        coloredShadow="primary"
        mx={2}
        mt={-1}
        p={2}
        mb={1}
        textAlign="center"
      >
        <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
          Add a new subscriber
        </MDTypography>
      </MDBox>
      <DialogContent>
        <MDBox pb={3} px={2}>
          <MDBox component="form" role="form">
            <MDBox m={2}>
              <MDInput
                fullWidth
                type="text"
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <MDBox color="red">
                {simpleValidator.current.message("Email", email, "required|email")}
              </MDBox>
            </MDBox>
            <MDBox m={2}>
              <MDInput
                fullWidth
                type="text"
                label="First name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </MDBox>
            <MDBox m={2}>
              <MDInput
                fullWidth
                type="text"
                label="Last name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </MDBox>
            <MDBox m={2}>
              <MDInput
                fullWidth
                type="text"
                label="Phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <MDBox color="red">{simpleValidator.current.message("Phone", phone, "phone")}</MDBox>
            </MDBox>
            <MDBox m={2}>
              <Autocomplete
                value={tags}
                onChange={(event, newValue) => {
                  setTags(
                    newValue.map((tag) =>
                      tag.indexOf('Create new tag "') === -1 ? tag : tag.substring(16).slice(0, -1)
                    )
                  );
                }}
                multiple
                id="tags-filled"
                options={suggestionsTag.map((option) => option.name)}
                freeSolo
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Type to search or add a new tag and press enter"
                  />
                )}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some((option) => inputValue === option);
                  if (inputValue.trim() !== "" && !isExisting) {
                    filtered.push(`Create new tag "${inputValue}"`);
                  }
                  return filtered;
                }}
              />
            </MDBox>
            <MDBox pt={2} pb={2}>
              <FormLabel>Country:</FormLabel>
              <Select
                fullWidth
                placeholder="Select a country"
                value={country}
                onChange={(e) => {
                  setCountry(e.target.value);
                }}
              >
                <MenuItem key={"Select a country"} value={"Select a country"}>
                  <ListItemText primary={"Select a country"} />
                </MenuItem>
                {countries.map((item) => (
                  <MenuItem key={item.sortname} value={item.name}>
                    <ListItemText primary={item.name} />
                  </MenuItem>
                ))}
              </Select>
            </MDBox>
            <MDBox pt={2} pb={2}>
              <FormLabel>State:</FormLabel>
              <Select
                fullWidth
                placeholder="Select a state"
                value={state}
                onChange={(e) => {
                  setState(e.target.value);
                }}
              >
                <MenuItem key={"Select a state"} value={"Select a state"}>
                  <ListItemText primary={"Select a state"} />
                </MenuItem>
                {states.map((item) => (
                  <MenuItem key={item.name} value={item.name}>
                    <ListItemText primary={item.name} />
                  </MenuItem>
                ))}
              </Select>
            </MDBox>
            <MDBox pt={2} pb={2}>
              <FormLabel>City:</FormLabel>
              <Select
                fullWidth
                placeholder="Select a city"
                value={city}
                onChange={(e) => {
                  setCity(e.target.value);
                }}
              >
                <MenuItem key={"Select a city"} value={"Select a city"}>
                  <ListItemText primary={"Select a city"} />
                </MenuItem>
                {cities.map((item) => (
                  <MenuItem key={item.name} value={item.name}>
                    <ListItemText primary={item.name} />
                  </MenuItem>
                ))}
              </Select>
            </MDBox>
            <MDBox m={2}>
              <MDInput
                fullWidth
                type="text"
                label="Zip"
                value={zip}
                onChange={(e) => setZip(e.target.value)}
              />
            </MDBox>
          </MDBox>
        </MDBox>
      </DialogContent>
      <DialogActions style={{ color: "white", backgroundColor: "white" }}>
        <MDButton
          onClick={clearVariables}
          disabled={isLoading}
          variant="outlined"
          color="secondary"
        >
          Cancel
        </MDButton>
        <MDButton onClick={handleSave} disabled={isLoading} variant="gradient" color="success">
          Save
          {isLoading && (
            <CircularProgress size={24} style={{ marginLeft: 15, position: "relative", top: 4 }} />
          )}
        </MDButton>
      </DialogActions>

      {renderErrorSB}
    </Dialog>
  );
}
export default Add;
