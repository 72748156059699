import { Card, CardHeader, Typography } from "@mui/material";
// import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import client from "ApiClient";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

/* eslint-disable */
export default function StatusPanel(props) {
  const [status, setStatus] = useState(true);
  const [settingsData, setSettingsData] = useState(null);
  const [connection, setConnection] = useState();
  const [serverMessage, setserverMessage] = useState();
  const [readyToRun, setReadyToRun] = useState(true);

  useEffect(() => {
    getData();
    //getReady();
    const connect = new HubConnectionBuilder()
      .withUrl("https://io.aimailer.net/signalr")
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build();
    setConnection(connect);
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          //  console.log("connected");

          connection.on("SendMessageSetup", (Message) => {
            setserverMessage(Message);
            // console.log(Message);
          });

          connection.on("OnConnectedAsync", (Message) => {
            //  console.log(Message);
          });
          connection?.invoke("joinGroup", localStorage.getItem("userName"));
        })
        .catch((error) => console.log(error));
    }
  }, [connection]);

  useEffect(() => {
    if (settingsData != null) {
      settingsData.set_running = status;
      handleSave();
    }
  }, [status]);

  const options = {
    method: "GET",
    url: "settings",
    headers: {
      "content-type": "application/json",
    },
  };

  const handleSave = async () => {
    options.method = "PUT";
    options.url = `settings`;
    options.data = settingsData;
    await client
      .request(options)
      .then(() => {})
      .catch((error) => {
        // eslint-disable-next-line
        console.log(error);
      });
  };

  const getData = async () => {
    options.method = "GET";
    options.url = `settings`;
    await client
      .request(options)
      .then((response) => {
        setSettingsData(response);
        setStatus(response.set_running);
        localStorage.setItem("requestApproval", response.set_requestApproval);
        localStorage.setItem("UserID", response.set_user_id);
        localStorage.setItem("timeZone", response.set_timezone);
        if (response.set_running == false) {
          const options = {
            method: "GET",
            url: `users/updatePaymentSubscription`,
            headers: {
              "content-type": "application/json",
            },
          };

          client
            .request(options)
            .then((response) => {
              if (response.indexOf("http") !== -1) window.location.href = response;
            })
            .catch(() => {
              // setErrorSB(true);
            });
        }
      })
      .catch((error) => {
        // eslint-disable-next-line
      });
  };

  const getReady = async () => {
    options.method = "GET";
    options.url = `settings/setupProgress`;
    await client
      .request(options)
      .then((response) => {
        setReadyToRun(response.ready);
        localStorage.setItem("readyToRun", response.ready);
      })
      .catch((error) => {
        // eslint-disable-next-line
      });
  };

  return (
    <Card>
      <CardHeader
        title={
          <MDBox display="flex">
            <MDTypography variant="h6" fontWeight="medium">
              Account status:
            </MDTypography>
            <Typography variant="h6" fontWeight="medium" color={status ? "green" : "error"}>
              {status ? "Active" : "Inactive"}
            </Typography>
          </MDBox>
        }
      />
    </Card>
  );
}

/* 
 <Switch checked={status} onChange={() => setStatus(!status)} disabled={!readyToRun} />
{!readyToRun && (
            <Grid item xs={12}>
              <TextareaAutosize
                defaultValue="Please complete your setup in order to run"
                readOnly
                style={{
                  padding: 5,
                  width: "100%",
                  border: "none",
                  resize: "none",
                  color: "#FF0000",
                }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextareaAutosize
              defaultValue={serverMessage}
              readOnly
              style={{
                padding: 5,
                width: "100%",
                border: "none",
                resize: "none",
              }}
            />
          </Grid>*/
