import client from "ApiClient";
import { useEffect, useState } from "react";
// import { ResponsiveIframeViewer, ViewportSize } from "react-responsive-iframe-viewer";
import { useSearchParams } from "react-router-dom";

export default function Preview() {
  const [searchParams] = useSearchParams();
  const [htmlSource, setHtmlSource] = useState("");

  /* window.open(
    `${
        process.env.REACT_APP_API_BASE_URL
      }broadcasts/preview?drp_id=${response}&user_id=${localStorage.getItem("userId")}`,
      "_blank"
    );
*/

  const options = {
    method: "POST",
    url: `emailCopy/Preview`,
    headers: {
      "content-type": "application/json",
    },
  };

  useEffect(() => {
    const id = searchParams.get("id");

    if (id) {
      setHtmlSource(
        `${
          process.env.REACT_APP_API_BASE_URL
        }emailCopy/preview?drp_id=${id}&user_id=${localStorage.getItem("userId")}`
      );
      /*
      options.method = "GET";
      options.url = `emailCopy/preview?drp_id=${id}&user_id=${localStorage.getItem("userId")}`;
      client
        .request(options)
        .then((response) => {
          console.log(response);
          setHtmlSource(response);
        })
        .catch((error) => {
          console.log(error);
        });




        <ResponsiveIframeViewer
        src={htmlSource}
        title="Preview"
        size={ViewportSize.desktop}
        width="100%"
        height="100%"
      />

      
        */
    }
  });
  useEffect(() => {
    console.log(htmlSource);
  }, [htmlSource]);

  return (
    <center>
      
    </center>
  );
}
