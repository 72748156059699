import { Button, List, ListItem, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import { useEffect, useRef, useState } from "react";
import client from "ApiClient";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import moment from "moment";
import Footer from "examples/Footer";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import DraftsIcon from "@mui/icons-material/Drafts";
import MailLockIcon from "@mui/icons-material/MailLock";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import SendIcon from "@mui/icons-material/Send";
import PersonIcon from "@mui/icons-material/Person";

/* eslint-disable */
export default function LogConsole() {
  const [logs, setLogs] = useState([]);
  const [show, setShow] = useState(false);

  const scrollRef = useRef(null);
  //const [lastID, setLastID] = useState(0);

  let lastID = 0;

  useEffect(() => {
    const interval = setInterval(() => {
      if (show) getData();
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (show) {
      getData();
    }
  }, [show]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behaviour: "smooth" });
    }
  }, [logs]);

  const options = {
    method: "GET",
    url: "logConsole",
  };

  const getData = async () => {
    if (lastID == undefined) lastID = 0;
    if (lastID == 0) setLogs([]);
    options.url = `logConsole?log_id=${lastID}`;
    await client
      .request(options)
      .then((response) => {
        if (response.length > 0) {
          setLogs((logs) => logs.concat(response));
          lastID = response[response.length - 1].id;
        }
      })
      .catch((error) => {
        // eslint-disable-next-line
      });
  };

  const renderIcon = (source, message) => {
    switch (source) {
      case "aimailer-lm":
        if (message.indexOf("clicked") !== -1)
          return <AdsClickIcon fontSize="medium" color="secondary" />;
        if (message.indexOf("opened") !== -1)
          return <DraftsIcon fontSize="medium" color="secondary" />;
        if (message.indexOf("bounce") !== -1)
          return <MailLockIcon fontSize="medium" color="secondary" />;
        if (message.indexOf("unsubscribed") !== -1)
          return <UnsubscribeIcon fontSize="medium" color="secondary" />;
        if (message.indexOf("complain") !== -1)
          return <MailLockIcon fontSize="medium" color="secondary" />;
      case "aimailer-tracking":
        return <FlightLandIcon fontSize="medium" color="secondary" />;
      case "aimailer-sent":
        return <SendIcon fontSize="medium" color="secondary" />;
      case "webhook-AddSubscriber":
        return <PersonIcon fontSize="medium" color="secondary" />;
      case "aimailer-ad":
        return <AdsClickIcon fontSize="medium" color="secondary" />;
    }
  };

  const getColors = (source, message) => {
    switch (source) {
      case "aimailer-lm":
        if (message.indexOf("clicked") !== -1) return "#00FF00";
        if (message.indexOf("opened") !== -1) return "#FFFFFF";
        if (message.indexOf("bounce") !== -1) return "red";
        if (message.indexOf("complain") !== -1) return "red";
        if (message.indexOf("unsubscribed") !== -1) return "red";
      case "aimailer-tracking":
        return "#00FF00";
      case "aimailer-sent":
        return "orange";
      case "aimailer-ad":
        return "#aaFFaa";
      case "webhook-AddSubscriber":
        return "#00B000";
    }
  };

  return (
    <DashboardLayout>
      <>
        <Button onClick={() => setShow((s) => !s)}>{show ? "Hide" : "Show"} events</Button>
      </>
      {show && (
        <>
          <MDBox bgColor="darkblue" color="white" p={2} mb={0}>
            <Typography>Latest updates from {process.env.REACT_MAIN_DOMAIN}</Typography>
          </MDBox>
          <List
            sx={{ width: "10", bgcolor: "#000000", color: "white", height: 300, flexFlow: "wrap" }}
            style={{ overflow: "auto" }}
          >
            {logs.length > 0 &&
              logs.map((item) => (
                <ListItem key={item.id} ref={scrollRef}>
                  {renderIcon(item.source, item.message)}
                  &nbsp;
                  <Typography color="#FFFF00" fontSize={18}>
                    {moment.utc(item.date).format("YYYY-MM-DD HH:mm:ss") + ":"}
                  </Typography>
                  &nbsp;
                  <Typography color={getColors(item.source, item.message)} fontSize={18}>
                    {item.message}
                  </Typography>
                </ListItem>
              ))}
          </List>
        </>
      )}
      &nbsp;
      <Footer />
    </DashboardLayout>
  );
}
