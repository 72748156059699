import { useEffect, useRef, useState } from "react";
// react-router-dom components
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import client from "ApiClient";
import SimpleReactValidator from "simple-react-validator";
import { CircularProgress, Dialog, DialogActions, DialogContent } from "@mui/material";
import MDButton from "components/MDButton";
/* eslint-disable */
function Edit(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [rea_id, setRea_id] = useState(0);
  const [rea_user_id, setRea_user_id] = useState(0);
  const [rea_email, setRea_email] = useState("");
  const [rea_smtpServer, setRea_smtpServer] = useState("");
  const [rea_smtpPort, setRea_smtpPort] = useState(587);
  const [rea_smtpUsername, setRea_smtpUsername] = useState("");
  const [rea_smtpPassword, setRea_smtpPassword] = useState("");
  const [rea_smtpEncryption, setRea_smtpEncryption] = useState("");
  const [rea_incomingServerType, setRea_incomingServerType] = useState(0); //0=IMAP;1=POP3
  const [rea_incomingServer, setRea_incomingServer] = useState("");
  const [rea_incomingPort, setRea_incomingPort] = useState(993);
  const [rea_incomingUsername, setRea_incomingUsername] = useState("");
  const [rea_incomingPassword, setRea_incomingPassword] = useState("");
  const [rea_incomingEncryption, setRea_incomingEncryption] = useState("");
  const [rea_status, setRea_status] = useState(0);
  const [testResponse, setTestResponse] = useState("");
  const { openModal, closeModal } = props;
  const closeErrorSB = () => setErrorSB(false);
  const [, forceUpdate] = useState();
  const simpleValidator = useRef(new SimpleReactValidator());

  const clearVariables = () => {
    setRea_id(0);
    setRea_user_id(0);
    setRea_email("");
    setRea_smtpServer("");
    setRea_smtpPort(587);
    setRea_smtpUsername("");
    setRea_smtpPassword("");
    setRea_smtpEncryption("");
    setRea_incomingServerType(0);
    setRea_incomingServer("");
    setRea_incomingPort(993);
    setRea_incomingUsername("");
    setRea_incomingPassword("");
    setRea_incomingEncryption("");
    setRea_status(0);
    setTestResponse("");
    simpleValidator.current.hideMessages();
    props.onClose();
  };

  const handleSave = () => {
    if (simpleValidator.current.allValid()) {
      setIsLoading(true);
      const options = {
        method: "PUT",
        url: `replyAccounts`,
        headers: {
          "content-type": "application/json",
        },
      };

      options.data = JSON.stringify({
        rea_id: rea_id,
        rea_user_id: rea_user_id,
        rea_email: rea_email,
        rea_smtpServer: rea_smtpServer,
        rea_smtpPort: rea_smtpPort,
        rea_smtpUsername: rea_smtpUsername,
        rea_smtpPassword: rea_smtpPassword,
        rea_smtpEncryption: "TLS",
        rea_incomingServerType: rea_incomingServerType,
        rea_incomingServer: rea_incomingServer,
        rea_incomingPort: rea_incomingPort,
        rea_incomingUsername: rea_incomingUsername,
        rea_incomingPassword: rea_incomingPassword,
        rea_incomingEncryption: "TLS",
        rea_status: rea_status,
      });

      client
        .request(options)
        .then((response) => {
          setIsLoading(false);
          props.onDone();
          clearVariables();
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    } else {
      setIsLoading(false);
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  const testIncomingServer = () => {
    if (simpleValidator.current.allValid()) {
      setIsLoading(true);
      const options = {
        method: "POST",
        url: `replyAccounts/testIncomingCredentials`,
        headers: {
          "content-type": "application/json",
        },
      };

      options.data = JSON.stringify({
        rea_id: rea_id,
        rea_user_id: rea_user_id,
        rea_email: rea_email,
        rea_smtpServer: rea_smtpServer,
        rea_smtpPort: rea_smtpPort,
        rea_smtpUsername: rea_smtpUsername,
        rea_smtpPassword: rea_smtpPassword,
        rea_smtpEncryption: "TLS",
        rea_incomingServerType: rea_incomingServerType,
        rea_incomingServer: rea_incomingServer,
        rea_incomingPort: rea_incomingPort,
        rea_incomingUsername: rea_incomingUsername,
        rea_incomingPassword: rea_incomingPassword,
        rea_incomingEncryption: "TLS",
        rea_status: rea_status,
      });

      client
        .request(options)
        .then((response) => {
          setIsLoading(false);
          if (response != "Connected") {
            setTestResponse("Error testing incoming server: " + response);
          } else {
            setTestResponse("");
            testOutgoingServer();
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    } else {
      setIsLoading(false);
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  const testOutgoingServer = () => {
    if (simpleValidator.current.allValid()) {
      setIsLoading(true);
      const options = {
        method: "POST",
        url: `replyAccounts/testOutgoingCredentials`,
        headers: {
          "content-type": "application/json",
        },
      };

      options.data = JSON.stringify({
        rea_id: rea_id,
        rea_user_id: rea_user_id,
        rea_email: rea_email,
        rea_smtpServer: rea_smtpServer,
        rea_smtpPort: rea_smtpPort,
        rea_smtpUsername: rea_smtpUsername,
        rea_smtpPassword: rea_smtpPassword,
        rea_smtpEncryption: "TLS",
        rea_incomingServerType: rea_incomingServerType,
        rea_incomingServer: rea_incomingServer,
        rea_incomingPort: rea_incomingPort,
        rea_incomingUsername: rea_incomingUsername,
        rea_incomingPassword: rea_incomingPassword,
        rea_incomingEncryption: "TLS",
        rea_status: rea_status,
      });

      client
        .request(options)
        .then((response) => {
          setIsLoading(false);
          if (response != "Connected") {
            setTestResponse("Error testing outgoing server: " + response);
          } else {
            setTestResponse("");
            handleSave();
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    } else {
      setIsLoading(false);
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  useEffect(() => {
    if (props.account != null) {
      setRea_id(props.account.rea_id);
      setRea_user_id(props.account.rea_user_id);
      setRea_email(props.account.rea_email);
      setRea_smtpServer(props.account.rea_smtpServer);
      setRea_smtpPort(props.account.rea_smtpPort);
      setRea_smtpUsername(props.account.rea_smtpUsername);
      setRea_smtpPassword(props.account.rea_smtpPassword);
      setRea_smtpEncryption(props.account.rea_smtpEncryption);
      setRea_incomingServerType(props.account.rea_incomingServerType);
      setRea_incomingServer(props.account.rea_incomingServer);
      setRea_incomingPort(props.account.rea_incomingPort);
      setRea_incomingUsername(props.account.rea_incomingUsername);
      setRea_incomingPassword(props.account.rea_incomingPassword);
      setRea_incomingEncryption(props.account.rea_incomingEncryption);
      setRea_status(props.account.rea_status);
    }
  }, [props]);

  return (
    <Dialog open={openModal} onClose={clearVariables} fullWidth maxWidth="sm">
      <MDBox
        variant="gradient"
        bgColor="primary"
        borderRadius="lg"
        coloredShadow="primary"
        mx={2}
        mt={-1}
        p={2}
        mb={1}
        textAlign="center"
      >
        <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
          Edit reply account
        </MDTypography>
      </MDBox>
      <DialogContent>
        <MDBox pt={4} pb={3} px={2}>
          <MDBox m={2}>
            <MDInput
              type="text"
              label="Email"
              fullWidth
              value={rea_email}
              onChange={(e) => setRea_email(e.target.value)}
              required
            />
            <MDBox color="red">
              {simpleValidator.current.message("Email", rea_email, "required|email")}
            </MDBox>
          </MDBox>
          <MDBox m={2}>
            <MDInput
              type="text"
              label="SMTP Server"
              fullWidth
              value={rea_smtpServer}
              onChange={(e) => setRea_smtpServer(e.target.value)}
              required
            />
            <MDBox color="red">
              {simpleValidator.current.message("SMTP Server", rea_smtpServer, "required")}
            </MDBox>
          </MDBox>
          <MDBox m={2}>
            <MDInput
              type="number"
              label="SMTP Port"
              fullWidth
              value={rea_smtpPort}
              onChange={(e) => setRea_smtpPort(e.target.value)}
              required
            />
            <MDBox color="red">
              {simpleValidator.current.message("SMTP Port", rea_smtpPort, "required")}
            </MDBox>
          </MDBox>
          <MDBox m={2}>
            <MDInput
              type="text"
              label="SMTP Username"
              fullWidth
              value={rea_smtpUsername}
              onChange={(e) => setRea_smtpUsername(e.target.value)}
              required
            />
            <MDBox color="red">
              {simpleValidator.current.message("SMTP Username", rea_smtpUsername, "required")}
            </MDBox>
          </MDBox>
          <MDBox m={2}>
            <MDInput
              type="password"
              label="SMTP Password"
              fullWidth
              value={rea_smtpPassword}
              onChange={(e) => setRea_smtpPassword(e.target.value)}
              required
            />
            <MDBox color="red">
              {simpleValidator.current.message("SMTP Password", rea_smtpPassword, "required")}
            </MDBox>
          </MDBox>

          <MDBox m={2}>
            <MDInput
              type="text"
              label="Incoming Server"
              fullWidth
              value={rea_incomingServer}
              onChange={(e) => setRea_incomingServer(e.target.value)}
              required
            />
            <MDBox color="red">
              {simpleValidator.current.message("Incoming Server", rea_incomingServer, "required")}
            </MDBox>
          </MDBox>
          <MDBox m={2}>
            <MDInput
              type="number"
              label="Incoming Port"
              fullWidth
              value={rea_incomingPort}
              onChange={(e) => setRea_incomingPort(e.target.value)}
              required
            />
            <MDBox color="red">
              {simpleValidator.current.message("Incoming Port", rea_incomingPort, "required")}
            </MDBox>
          </MDBox>
          <MDBox m={2}>
            <MDInput
              type="text"
              label="Incoming Username"
              fullWidth
              value={rea_incomingUsername}
              onChange={(e) => setRea_incomingUsername(e.target.value)}
              required
            />
            <MDBox color="red">
              {simpleValidator.current.message(
                "Incoming Username",
                rea_incomingUsername,
                "required"
              )}
            </MDBox>
          </MDBox>
          <MDBox m={2}>
            <MDInput
              type="password"
              label="Incoming Password"
              fullWidth
              value={rea_incomingPassword}
              onChange={(e) => setRea_incomingPassword(e.target.value)}
              required
            />
            <MDBox color="red">
              {simpleValidator.current.message(
                "Incoming Password",
                rea_incomingPassword,
                "required"
              )}
            </MDBox>
          </MDBox>
        </MDBox>
      </DialogContent>
      <DialogActions style={{ color: "white", backgroundColor: "white" }}>
        <MDBox color="red">{testResponse}</MDBox>
        <MDButton
          onClick={clearVariables}
          disabled={isLoading}
          variant="outlined"
          color="secondary"
        >
          Cancel
        </MDButton>
        <MDButton
          onClick={testIncomingServer}
          disabled={isLoading}
          variant="gradient"
          color="success"
        >
          Save
          {isLoading && (
            <CircularProgress size={24} style={{ marginLeft: 15, position: "relative", top: 4 }} />
          )}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
export default Edit;
