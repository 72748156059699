/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// import { useState } from "react";
// @mui material components
import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Chip, TableCell } from "@material-ui/core";
import moment from "moment";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import EditIcon from "@mui/icons-material/Edit";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import client from "ApiClient";
import ConfirmationAlert from "components/ConfirmationAlert";
import { CircularProgress, Grid, IconButton, MenuItem, Select, Tooltip } from "@mui/material";
import MDButton from "components/MDButton";
import CustomToolbarSelect from "./customToolbarSelect";
import Edit from "./edit";

// import Edit from "./edit";

/* eslint-disable */
export default function Table(props) {
  const [create, setCreate] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [openEdit, setOpenEdit] = useState(false);
  const [isClone, setIsClone] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [percentage, setPercentage] = useState("true");
  const [automations, setAutomations] = useState([]);
  const [selectedAutomation, setSelectedAutomation] = useState([]);
  const [openLog, setOpenLog] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  // const handleCloseModal = () => setOpenModal(false);

  const oldRender = TableCell.render;
  TableCell.render = function (...args) {
    const [props, ...otherArgs] = args;
    if (typeof props === "object" && props && "isEmpty" in props) {
      const { isEmpty, ...propsWithoutEmpty } = props;
      return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
    } else {
      return oldRender.apply(this, args);
    }
  };

  const options = {
    method: "GET",
    url: "automations",
    headers: {
      "content-type": "application/json",
    },
  };

  const getData = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `automations`;
    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);        
        setAutomations(response);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getSenderProfiles = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `senderProfiles`;
    await client
      .request(options)
      .then((response) => {
        response.length > 0 ? setCreate(true) : setCreate(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (window.sessionStorage.getItem("broadcast_percentage") == null)
      window.sessionStorage.setItem("broadcast_percentage", percentage);
    else setPercentage(window.sessionStorage.getItem("broadcast_percentage"));
    getSenderProfiles();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getData();
  }, [props.groupAdded]);

  useEffect(() => {
    if (selectedAutomation && selectedAutomation.length > 0 && isClone) {
      clone();
      setIsClone(false);
    }
  }, [selectedAutomation]);

  const handleEdit = () => {
    handleOpenModal();
  };

  const changePercentageHandler = (event) => {
    window.sessionStorage.setItem("broadcast_percentage", event.target.value);
    setPercentage(event.target.value);
  };

  const handleDelete = () => {
    setIsLoading(true);
    options.method = "DELETE";
    options.url = `automations?aut_id=${selectedAutomation.aut_id}`;
    options.headers = {
      "content-type": "application/json",
    };
    client
      .request(options)
      .then((response) => {
        setSelectedAutomation([]);
        setIsLoading(false);
        getData();
        setOpenAlert(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const handlePause = (automation) => {
    options.method = "PUT";
    options.url = `automations`;

    automation[0].aut_status = automation[0].aut_status === 0 ? 2 : 0;

    options.data = JSON.stringify({
      aut_id: automation[0].aut_id,
      aut_name: automation[0].aut_name,
      aut_status: automation[0].aut_status,
      aut_description: automation[0].aut_description,
      aut_user_id: automation[0].aut_user_id,
      aut_createdDate: automation[0].aut_createdDate,
      aut_totalSent: automation[0].aut_totalSent,
      aut_json: automation[0].aut_json,
    });
    client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        getData();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const clone = () => {
    setIsLoading(true);
    options.method = "POST";
    options.url = `automations/clone`;
    options.data = selectedAutomation[0];

    client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        response.aut_name = response.aut_name + " (cloned)";
        setSelectedAutomation([response]);
        setOpenEdit(true);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const getDateValue = (date) => {
    return moment(date).format("L");
  };

  const getStatus = (aut) => {
    switch (aut.aut_status) {
      case 0:
        return <Chip style={{ backgroundColor: "#bce2be" }} label="Active" />;
      case 1:
        return <Chip style={{ backgroundColor: "#6DA3FA" }} label="Draft" />;
      case 2:
        return <Chip style={{ backgroundColor: "#6DA3FA" }} label="Paused" />;
    }
  };

  const columns = [
    {
      label: "Status",
      name: "aut_status",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex, rowIndex) => getStatus(automations[dataIndex]),
      },
    },
    {
      label: "Name",
      name: "aut_name",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "Sent",
      name: "aut_totalSent",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "Opens",
      name: "total_opens",
      options: {
        display: percentage == "true" ? false : true,
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "Clicks",
      name: "total_clicks",
      options: {
        display: percentage == "true" ? false : true,
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "Opens %",
      name: "total_opens_perc",
      options: {
        display: percentage == "true" ? true : false,
        filterOptions: { fullWidth: true },
        filter: true,
        customBodyRender: (value) => (percentage ? value + " %" : value),
      },
    },
    {
      label: "Clicks %",
      name: "total_clicks_perc",
      options: {
        display: percentage == "true" ? true : false,
        filterOptions: { fullWidth: true },
        filter: true,
        customBodyRender: (value) => (percentage ? value + " %" : value),
      },
    },
    {
      label: "Unsubscribes",
      name: "total_unsubscribes",
      options: {
        display: percentage == "true" ? false : true,
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "Unsubscribes %",
      name: "total_unsubs_perc",
      options: {
        display: percentage == "true" ? true : false,
        filterOptions: { fullWidth: true },
        filter: true,
        customBodyRender: (value) => (percentage ? value + " %" : value),
      },
    },
    {
      label: "Complaints",
      name: "total_complaints",
      options: {
        display: percentage == "true" ? false : true,
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "Complaints %",
      name: "total_complaints_perc",
      options: {
        display: percentage == "true" ? true : false,
        filterOptions: { fullWidth: true },
        filter: true,
        customBodyRender: (value) => (percentage ? value + " %" : value),
      },
    },
    {
      label: "Creation date",
      name: "aut_createdDate",
      options: {
        filter: true,
        customBodyRender: (value) => getDateValue(value),
      },
    },
    {
      label: " ",
      name: "aut_id",
      options: {
        filter: true,
        setCellHeaderProps: () => {
          return { align: "right" };
        },
        setCellProps: () => {
          return { align: "right" };
        },
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div>
              <IconButton
                onClick={() => {
                  setSelectedAutomation([automations[dataIndex]]);
                  setOpenEdit(true);
                }}
                sx={{ fontWeight: "bold" }}
                color="primary"
                aria-label="prompt"
              >
                <Tooltip id="button-report" title="Edit">
                  <EditIcon />
                </Tooltip>
              </IconButton>
              <IconButton
                onClick={() => {
                  setIsClone(true);
                  setSelectedAutomation([automations[dataIndex]]);
                }}
                sx={{ fontWeight: "bold" }}
                color="primary"
                aria-label="prompt"
              >
                <Tooltip id="button-report" title="Clone and edit">
                  <ContentCopyIcon fontSize="small" />
                </Tooltip>
              </IconButton>
              <Tooltip
                title={
                  automations[dataIndex].aut_status === 0 ? "Pause" : "Resume" + " automation "
                }
              >
                <IconButton
                  onClick={() => {
                    setSelectedAutomation([automations[dataIndex]]);
                    handlePause([automations[dataIndex]]);
                  }}
                >
                  {automations[dataIndex].aut_status === 0 ? <PauseIcon /> : <PlayArrowIcon />}
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
    },
  ];

  const customToolbarSelect = () => <CustomToolbarSelect onDelete={() => setOpenAlert(true)} />;
  const CustomToolbar = ({ displayData }) => {
    return (
      <Tooltip title="Change the stats view between counts and percentage" placement="top">
        <Select
          style={{ marginLeft: 15 }}
          value={percentage}
          label="Change the view of opens and clicks"
          onChange={(event) => changePercentageHandler(event)}
        >
          <MenuItem value="false">#</MenuItem>
          <MenuItem value="true">%</MenuItem>
        </Select>
      </Tooltip>
    );
  };
  const tableOptions = {
    textLabels: {
      body: {
        noMatch: "You don't have any automation",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "Clear filter",
      },
      filterType: "dropdown",
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "automation/s selected",
      },
    },
    print: false,
    filter: false,
    viewColumns: false,
    selectableRowsHideCheckboxes: false,
    selectableRowsOnClick: false,
    responsive: "simple",
    filterType: "dropdown",
    selectableRows: "single",
    fixedHeader: true,
    fixedSelectColumn: true,
    searchOpen: true,
    searchAlwaysOpen: true,
    searchPlaceholder: "Search",
    downloadOptions: { filename: "automations.csv", separator: "," },
    onRowSelectionChange: (curRowSelected, allRowsSelected, rowsSelected) => {
      setSelectedAutomation(automations[rowsSelected[0]]);
    },
    customToolbarSelect: customToolbarSelect,
    customToolbar: CustomToolbar,
  };

  return (
    <div>
      <ConfirmationAlert
        openModal={openAlert}
        onClose={() => setOpenAlert(false)}
        onYes={handleDelete}
        yesText="Yes"
        title="Are you sure you want to delete this automation?"
      />
      <Edit
        openModal={openEdit}
        closeModal={() => setOpenEdit(false)}
        automation={selectedAutomation}
        onDone={getData}
      />
      <MDBox mx={2} mt={1} py={3} px={2} bgColor="white" borderRadius="lg">
        <Grid container>
          <Grid item xs={12} md={10}>
            <MDTypography variant="h2">
              Automations
              {isLoading && (
                <CircularProgress
                  size={30}
                  style={{ marginLeft: 15, position: "relative", top: 4 }}
                />
              )}
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={2} style={{ width: "100%", textAlign: "right" }}>
            <MDBox justifyContent="right">
              {create && (
                <Tooltip title="Create a new automation" arrow placement="top">
                  <MDButton variant="gradient" color="secondary" onClick={props.onAdd}>
                    Create new
                  </MDButton>
                </Tooltip>
              )}
            </MDBox>
          </Grid>
        </Grid>
        {!create && !isLoading && (
          <MDTypography variant="caption" color="error">
            You need to create a sender profile in order to add an automation
          </MDTypography>
        )}
      </MDBox>

      <MDBox>
        <Grid container p={2}>
          <Grid item xs={12}>
            <div style={{ height: "100%", width: "100%" }}>
              <MUIDataTable data={automations} columns={columns} options={tableOptions} />
            </div>
          </Grid>
        </Grid>
      </MDBox>
    </div>
  );
}
