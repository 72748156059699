import { useEffect, useRef, useState } from "react";
import theme from "assets/theme";
// react-router-dom components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import client from "ApiClient";
import SimpleReactValidator from "simple-react-validator";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Switch,
  Tooltip,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import { useMaterialUIController } from "context";

/* eslint-disable */
function Add(props) {
  const [margin, setMargin] = useState(300);
  const [errorSB, setErrorSB] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCatalog, setIsLoadingCatalog] = useState(false);
  const [passVisible, setPassVisible] = useState(false);
  const [fetchArticles, setFetchArticles] = useState(false);
  const [blogFriendlyName, setblogFriendlyName] = useState("");
  const [selectedCatalog, setSelectedCatalog] = useState(0);
  const [catalogs, setCatalogs] = useState([]);
  const [catalogName, setCatalogName] = useState("");
  const [blogUrl, setBlogUrl] = useState("");
  const [wpUsername, setWpUsername] = useState("");
  const [wpPassword, setWpPassword] = useState("");
  const [blogLogoURL, setBlogLogoURL] = useState("");
  const [interests, setInterests] = useState("");
  const { openModal, closeModal } = props;
  const closeErrorSB = () => setErrorSB(false);
  const [, forceUpdate] = useState();
  const simpleValidator = useRef(new SimpleReactValidator());

  const [controller] = useMaterialUIController();
  const { miniSidenav } = controller;

  useEffect(() => {
    if (miniSidenav) setMargin(0);
    else setMargin(300);
  }, [miniSidenav]);

  const clearVariables = (reason) => {
    if (reason === "backdropClick") return;
    setblogFriendlyName("");
    setBlogUrl("");
    setWpUsername("");
    setWpPassword("");
    setBlogLogoURL("");
    simpleValidator.current.hideMessages();
    props.onClose();
  };

  const handleSave = () => {
    if (simpleValidator.current.allValid()) {
      setIsLoading(true);
      const options = {
        method: "put",
        url: `blogs`,
        headers: {
          "content-type": "application/json",
        },
      };

      options.data = JSON.stringify({
        blog_id: props.blog.blog_id,
        blog_cat_id: selectedCatalog,
        blog_user_id: props.blog.blog_user_id,
        blog_friendlyName: blogFriendlyName,
        blog_url: blogUrl,
        blog_logoURL: blogLogoURL,
        blog_username: wpUsername,
        blog_password: wpPassword,
        blog_status: props.blog.blog_status,
        interests_csv: interests,
        fetch_articles: fetchArticles,
      });

      client
        .request(options)
        .then((response) => {
          setIsLoading(false);
          props.onDone();
          clearVariables();
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    } else {
      setIsLoading(false);
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  const getCatalogs = async () => {
    const options = {
      method: "GET",
      url: "catalogs",
    };
    await client
      .request(options)
      .then((response) => {
        setCatalogs(response);

        if (catalogName != "") {
          let createdCatalog = response.filter((cat) => cat.cat_name == catalogName);
          setSelectedCatalog(createdCatalog[0].cat_id);
        }
        setCatalogName("");
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const validateCredentials = async () => {
    if (simpleValidator.current.allValid()) {
      setIsLoading(true);
      const options = {
        method: "GET",
        url: `blogs/validateCredentials?blogURL=${blogUrl}&editor_username=${wpUsername}&editor_password=${wpPassword}`,
        headers: {
          "content-type": "application/json",
        },
      };

      await client
        .request(options)
        .then((response) => {
          if (response == "Ok") {
            handleSave();
          } else {
            setErrorSB(true);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          // eslint-disable-next-line
          console.log(error);
        });
    } else {
      setIsLoading(false);
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  const addCatalog = () => {
    setIsLoadingCatalog(true);
    const options = {
      method: "POST",
      url: "catalogs",
    };
    options.headers = {
      "content-type": "application/json",
    };
    options.data = JSON.stringify({
      cat_name: catalogName,
    });

    client
      .request(options)
      .then((response) => {
        setIsLoadingCatalog(false);
        getCatalogs();
      })
      .catch((error) => {
        setIsLoadingCatalog(false);
        console.log("ERROR", error);
      });
  };

  const changeBlogUrlChange = (event) => {
    setBlogUrl(event.target.value);
  };

  const wpUsernameChange = (event) => {
    setWpUsername(event.target.value);
  };

  const wpPasswordChange = (event) => {
    setWpPassword(event.target.value);
  };

  const blogFriendlyNameChange = (event) => {
    setblogFriendlyName(event.target.value);
  };

  const blogLogoURLChange = (event) => {
    setBlogLogoURL(event.target.value);
  };

  const handleChangeCatalog = (event) => {
    setSelectedCatalog(event.target.value);
  };
  const changeInterests = (event) => {
    setInterests(event.target.value);
  };

  useEffect(() => {
    setblogFriendlyName(props.blog.blog_friendlyName);
    setBlogUrl(props.blog.blog_url);
    setWpUsername(props.blog.blog_username);
    setWpPassword(props.blog.blog_password);
    setBlogLogoURL(props.blog.blog_logoURL);
  }, [props.blog]);

  useEffect(() => {
    if (catalogs.length > 0 && props.blog.blog_cat_id != undefined)
      setSelectedCatalog(props.blog.blog_cat_id);
  }, [catalogs, props.blog.blog_cat_id]);

  useEffect(() => {
    getCatalogs();
  }, []);

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Connection failed"
      content="Your credentails are wrong"
      dateTime=""
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  return (
    <Dialog
      open={openModal}
      onClose={(e, reason) => clearVariables(reason)}
      fullScreen
      style={{ marginLeft: margin }}
      sx={{
        //You can copy the code below in your theme
        background: theme.palette.background.default,
        "& .MuiPaper-root": {
          background: theme.palette.background.default,
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent", // Try to remove this to see the result
        },
      }}
    >
      <MDBox
        variant="gradient"
        bgColor="primary"
        borderRadius="lg"
        coloredShadow="primary"
        mx={2}
        mt={-1}
        p={2}
        mb={1}
        textAlign="center"
      >
        <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
          Create a new blog
        </MDTypography>
      </MDBox>
      <DialogContent>
        <MDBox pb={3} px={2}>
          <MDBox component="form" role="form">
            <Card style={{ padding: 10, backgroundColor: "white", marginBottom: 10 }}>
              <Card style={{ padding: 10, backgroundColor: "white", marginBottom: 10 }}>
                <MDBox
                  pt={2}
                  pb={2}
                  display="flex"
                  justifyContent="left"
                  style={{ marginLeft: 20 }}
                >
                  <MDTypography>
                    Select the catalog where the articles of blog will be added.
                  </MDTypography>

                  {catalogs && (
                    <Select onChange={handleChangeCatalog} value={selectedCatalog}>
                      {catalogs.map((item) => (
                        <MenuItem key={item.cat_id} value={item.cat_id}>
                          {item.cat_name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </MDBox>
                <MDBox
                  pt={2}
                  pb={2}
                  display="flex"
                  justifyContent="left"
                  style={{ marginLeft: 20 }}
                >
                  <MDInput
                    type="text"
                    label="Type the name of catalog"
                    value={catalogName}
                    onChange={(e) => setCatalogName(e.target.value)}
                  />
                  <MDButton
                    style={{ marginLeft: 20 }}
                    onClick={addCatalog}
                    disabled={catalogName == ""}
                    variant="outlined"
                    color="success"
                  >
                    Create new catalog
                    {isLoadingCatalog && (
                      <CircularProgress
                        size={24}
                        style={{ marginLeft: 15, position: "relative", top: 4 }}
                      />
                    )}
                  </MDButton>
                </MDBox>
              </Card>
              <MDBox m={2} display="flex" justifyContent="left">
                <Switch checked={fetchArticles} onChange={() => setFetchArticles(!fetchArticles)} />
                <MDTypography variant="h6" fontWeight="medium" mt={1}>
                  Fetch articles from blog
                </MDTypography>
              </MDBox>
              <MDBox m={2}>
                <MDInput
                  type="text"
                  label="Blog Friendly Name"
                  fullWidth
                  value={blogFriendlyName}
                  onChange={blogFriendlyNameChange}
                  required
                />
                <MDBox color="red">
                  {simpleValidator.current.message(
                    "blogFriendlyName",
                    blogFriendlyName,
                    "required"
                  )}
                </MDBox>
              </MDBox>
              <MDBox m={2}>
                <MDInput
                  type="text"
                  label="Blog Url"
                  fullWidth
                  value={blogUrl}
                  onChange={changeBlogUrlChange}
                  required
                />
                <MDBox color="red">
                  {simpleValidator.current.message("blogUrl", blogUrl, "required|url")}
                </MDBox>
              </MDBox>
              <MDBox m={2}>
                <MDInput
                  type="text"
                  label="Blog Logo URL"
                  fullWidth
                  value={blogLogoURL}
                  onChange={blogLogoURLChange}
                  required
                />
                <MDBox color="red">
                  {simpleValidator.current.message("blogLogoURL", blogLogoURL, "required|url")}
                </MDBox>
              </MDBox>
              <MDBox m={2}>
                <MDInput
                  type="text"
                  label="Word press Username"
                  fullWidth
                  value={wpUsername}
                  onChange={wpUsernameChange}
                  required
                />
                <MDBox color="red">
                  {simpleValidator.current.message("wordpress username", wpUsername, "required")}
                </MDBox>
              </MDBox>
              <MDBox m={2}>
                <MDInput
                  type={passVisible ? "text" : "password"}
                  label="Word press Password"
                  fullWidth
                  value={wpPassword}
                  onChange={wpPasswordChange}
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setPassVisible(!passVisible)}
                          color="secondary"
                          aria-label="prompt"
                        >
                          {!passVisible ? (
                            <Tooltip title="View password">
                              <VisibilityIcon />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Hide password">
                              <VisibilityOffIcon />
                            </Tooltip>
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <MDBox color="red">
                  {simpleValidator.current.message("wpPassword", wpPassword, "required")}
                </MDBox>
              </MDBox>
            </Card>
          </MDBox>
          <Card sx={{ mt: 2 }}>
            <MDBox
              mx={2}
              mt={1}
              py={3}
              px={2}
              variant="gradient"
              bgColor="secondary"
              borderRadius="lg"
              coloredShadow="secondary"
            >
              <MDTypography variant="subtitle1" color="white">
                The initial interests that your subscriber had in order to sign up on your website.
                i.e.: Join focus groups, Make money online, Golf tourneament, Stay in luxury hotels,
                Student loan.
              </MDTypography>
            </MDBox>
            <MDBox m={2}>
              <MDInput
                type="text"
                label="Interest separated by comma"
                fullWidth
                value={interests}
                onChange={changeInterests}
                required
              />
            </MDBox>
          </Card>
        </MDBox>
      </DialogContent>
      <DialogActions style={{ color: "white", backgroundColor: "white" }}>
        <MDButton
          onClick={clearVariables}
          disabled={isLoading}
          variant="outlined"
          color="secondary"
        >
          Cancel
        </MDButton>
        <MDButton
          onClick={validateCredentials}
          disabled={isLoading}
          variant="gradient"
          color="success"
        >
          Save
        </MDButton>
        {isLoading && (
          <CircularProgress size={24} style={{ marginLeft: 15, position: "relative", top: 4 }} />
        )}
      </DialogActions>

      {renderErrorSB}
    </Dialog>
  );
}
export default Add;
