import { useRef, useState, useEffect, Fragment } from "react";
import Card from "@mui/material/Card";
import ImageIcon from "@mui/icons-material/Image";
import WarningIcon from "@material-ui/icons/Warning";
import UploadIcon from "@mui/icons-material/Upload";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import client from "ApiClient";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
// import openAILogo from "assets/images/logos/ChatGPT_logo.png";
import SimpleReactValidator from "simple-react-validator";
import MDButton from "components/MDButton";
import InputFiles from "react-input-files";
import Moment from "moment";
import { Editor } from "@tinymce/tinymce-react";
import MDInput from "components/MDInput";
import { Step, StepLabel, Stepper } from "@material-ui/core";
import theme from "assets/theme";
import { useMaterialUIController } from "context";

/* eslint-disable */
function AddArticle(props) {
  const [margin, setMargin] = useState(300);
  const [loadingOpenAITopics, setLoadingOpenAITopics] = useState(false);
  // const [loadingGoogleTrendsTopics, setLoadingGoogleTrendsTopics] = useState(false);
  const [loadingSubTopics, setLoadingSubTopics] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingGoogleContent, setIsLoadingGoogleContent] = useState(false);
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);
  const [isGeneratingImage, setIsGeneratingImage] = useState(false);
  const [loadingContent, setLoadingContent] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isReadyToPublish, setIsReadyToPublish] = useState(false);
  const [onUpdating, setOnUpdating] = useState(false);

  // const [updateType, setUpdateType] = useState(null); //0:headline,1:intro,2:article
  const [loadingLabel, setLoadingLabel] = useState("");
  const [topic, setTopic] = useState("");
  const [prompt, setPrompt] = useState([]);
  const [promptContent, setPromptContent] = useState([]);
  const [promptName, setPromptName] = useState("");
  const [headlineArr, setHeadlineArr] = useState([]);
  const [intro, setIntro] = useState("");
  const [article, setArticle] = useState("");
  const [url, setURL] = useState("");
  const [post, setPost] = useState(null);
  // const [contentEditorValue, setContentEditorValue] = useState("");

  const { openModal, closeModal } = props;
  // const [, forceUpdate] = useState();
  const simpleValidator = useRef(new SimpleReactValidator());
  const [editPrompt, setEditPrompt] = useState(false);

  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [imagePrompt, setImagePrompt] = useState("");

  const [characters, setCharacters] = useState([]);
  const [categories, setCategories] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedCharacter, setSelectedCharacter] = useState(8);
  const [selectedInterest, setSelectedInterests] = useState(null);
  const [selectedOffer, setSelectedOffer] = useState(0);
  const [selectedSubtopic, setSelectedSubtopic] = useState("");
  const [selectedHeadline, setSelectedHeadline] = useState("");
  const [selectedUpdatedContent, setSelectedUpdatedContent] = useState("");
  const [selectedPrompt, setSelectedPrompt] = useState(0);
  const [headline, setHeadline] = useState("");
  const [interests, setInterests] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [topics, setTopics] = useState([]);
  const [googleTopics, setGoogleTopics] = useState([]);
  const [subtopics, setSubTopics] = useState([]);
  const [updatedContent, setUpdatedContent] = useState([]);
  const editorRef = useRef(null);

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [skippedSteps, setSkippedSteps] = useState([2, 5]);
  const [failedSteps, setFailedSteps] = useState([]);


  const [controller] = useMaterialUIController();
  const { miniSidenav } = controller;

  useEffect(() => {
    if (miniSidenav) setMargin(0);
    else setMargin(300);
  }, [miniSidenav]);

  document.addEventListener("focusin", (e) => {
    if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
      e.stopImmediatePropagation();
    }
  });

  const clearVariables = (reason) => {
    if (reason === "backdropClick") return;
    setLoadingOpenAITopics(false);

    setLoadingSubTopics(false);
    setIsLoading(false);
    setIsLoadingGoogleContent(false);
    setIsLoadingPreview(false);
    setIsGeneratingImage(false);
    setLoadingContent(false);
    setIsUploading(false);
    setIsReadyToPublish(false);
    setOnUpdating(false);
    setLoadingLabel("");
    setTopic("");
    setPrompt([]);
    setPromptContent([]);
    setPromptName("");
    setHeadlineArr([]);
    setIntro("");
    setArticle("");
    setURL("");
    setPost(null);
    setEditPrompt(false);

    setFile(null);
    setImageUrl("");
    setImagePrompt("");

    setSelectedCategory(0);
    setSelectedCharacter(8);
    setSelectedInterests(null);
    setSelectedOffer(0);
    setSelectedSubtopic("");
    setSelectedHeadline("");
    setSelectedUpdatedContent("");
    setSelectedPrompt(0);
    setHeadline("");
    setSelectedTopic(null);
    setSubTopics([]);
    setUpdatedContent([]);
    setActiveStep(0);
    props.onDone();
    closeModal();
  };

  ///////////////////////////////////////////////////////////STEPS START ///////////////////////////////////////////////////////////////////////////////

  const steps = [
    "Interest",
    "Topic",
    "Updated content",
    "Prompt",
    "Article",
    "Image",
    "Preview",
    "Publish",
  ];

  const isStepOptional = (step) => {
    return skippedSteps.includes(step);
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const isStepFailed = (step) => {
    return failedSteps.includes(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    // setMappedHeaders(mappedHeaders.filter((item) => !mappedField.includes(item) && item != selectedHeader));
    let fail = false;
    switch (activeStep) {
      case 0:
        if (selectedInterest == null) {
          fail = true;
          setFailedSteps((failedSteps) => [...failedSteps, activeStep]);
        } else setFailedSteps(failedSteps.filter((item) => item != activeStep));
        setTopics([]);
        break;
      case 1:
        if (topic == "") {
          fail = true;
          setFailedSteps((failedSteps) => [...failedSteps, activeStep]);
        } else setFailedSteps(failedSteps.filter((item) => item != activeStep));
        handleGoogleSearch();
        break;
      case 2:
        break;
      case 3:
        handleGenerate();
        break;
      case 4:
        break;
      case 5:
        setURL("");
        previewArticle();
        break;
      case 6:
        break;
      case 7:
        handleSave();
        break;
    }

    if (fail) return;
    if (activeStep === steps.length - 1) {
      handleSave();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const handleBack = () => {
    switch (activeStep) {
      case 0:
        break;
      case 1:
        break;
      case 2:
        break;
      case 3:
        break;
      case 4:
        break;
      case 5:
        break;
      case 6:
        break;
      case 7:
        break;
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    if (activeStep === 2) handleCancelGoogleSearch();

    if (activeStep === 5) {
      setFile(null);
      setImageUrl("");
      previewArticle();
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  useEffect(() => {}, [activeStep]);

  const getStep = (step) => {
    let label = "";
    switch (step) {
      case 0: //INTERESTS
        return (
          <MDBox mb={2} pb={2}>
            <MDTypography variant="h5" fontWeight="medium" ml={1} mr={2}>
              What is the interest?
            </MDTypography>
            <List
              sx={{ width: "100%", height: 300 }}
              disabled={isLoading}
              style={{ overflow: "auto" }}
            >
              {interests.map((item, index) => (
                <ListItemButton
                  key={item.int_id}
                  disabled={isLoading}
                  selected={selectedInterest === index}
                  onClick={(event) => handleListInterestsClick(event, index)}
                >
                  <ListItemText primary={item.int_keyword} secondary={item.int_description} />
                </ListItemButton>
              ))}
            </List>
          </MDBox>
        );
      case 1: //TOPIC
        return (
          <Fragment>
            <MDBox mb={2} display="flex" justifyContent="left">
              <MDTypography variant="h5" fontWeight="medium" ml={1} mr={2}>
                What is the article topic?
              </MDTypography>
            </MDBox>
            <MDBox mb={2} display="flex" justifyContent="left">
              <List
                sx={{ width: "100%", height: 300 }}
                disabled={isLoading}
                style={{ overflow: "auto" }}
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    {loadingOpenAITopics && (
                      <MDBox display="flex" justifyContent="center">
                        <MDTypography variant="h6" fontWeight="medium" ml={1} mr={2}>
                          Loading topics
                        </MDTypography>
                        <CircularProgress
                          size={24}
                          style={{ marginLeft: 15, position: "relative", top: 4 }}
                        />
                      </MDBox>
                    )}
                  </ListSubheader>
                }
              >
                {topics &&
                  topics.map((item) => (
                    <ListItemButton
                      key={item.topic}
                      disabled={isLoading}
                      selected={selectedTopic == item.topic}
                      onClick={() => handleChangeTopic(item.topic)}
                    >
                      <ListItemText primary={item.topic} />
                    </ListItemButton>
                  ))}
              </List>
            </MDBox>
            <MDBox mb={2} display="flex" justifyContent="left">
              <MDInput
                type="text"
                label="Selected topic"
                fullWidth
                value={topic}
                disabled={isLoading}
                onChange={handleChangeTextTopic}
              />
            </MDBox>
          </Fragment>
        );
      case 2: //UPDATED CONTENT
        return (
          <Fragment>
            {isLoadingGoogleContent && (
              <Fragment>
                <MDBox display="flex" justifyContent="center">
                  <MDTypography variant="h6" fontWeight="medium" ml={1} mr={2}>
                    Searching content
                  </MDTypography>
                  <CircularProgress
                    size={24}
                    style={{ marginLeft: 15, position: "relative", top: 4 }}
                  />
                </MDBox>
              </Fragment>
            )}

            {updatedContent.length > 0 && (
              <Fragment>
                <Grid item>
                  <MDBox display="flex" justifyContent="left">
                    <List
                      sx={{ width: "100%", height: 300 }}
                      disabled={isLoading}
                      style={{ overflow: "auto" }}
                    >
                      {updatedContent.map((item) => (
                        <ListItemButton
                          key={item.url}
                          disabled={isLoading}
                          selected={selectedUpdatedContent == item.url}
                          onClick={() => handleChangeUpdatedContent(item.url)}
                        >
                          <ListItemText primary={item.title} secondary={item.snippet} />
                          <MDButton
                            color="info"
                            onClick={() => window.open(item.url, "_blank", "noreferrer")}
                          >
                            Open
                          </MDButton>
                        </ListItemButton>
                      ))}
                    </List>
                  </MDBox>
                </Grid>
              </Fragment>
            )}
          </Fragment>
        );
      case 3: //PROMPT
        return (
          <Fragment>
            <MDBox pt={2} pb={2} display="flex" justifyContent="left">
              <MDTypography variant="h5" fontWeight="medium" ml={1} mr={2}>
                What writing style would you like to use?
              </MDTypography>
              <Select
                disabled={isLoading}
                onChange={handleChangeCharacter}
                value={selectedCharacter}
                inputProps={{
                  name: "character",
                  id: "characterSelect",
                }}
              >
                {characters.map((item) => (
                  <MenuItem key={item.cha_id} value={item.cha_id}>
                    {item.cha_name}
                  </MenuItem>
                ))}
              </Select>
            </MDBox>

            <MDBox pb={2}>
              <MDTypography variant="h5" fontWeight="medium" ml={1} mr={2}>
                Select the prompt to use
              </MDTypography>
              <List
                disabled={isLoading}
                style={{ overflow: "auto" }}
                sx={{ width: "100%", height: 300 }}
              >
                {prompt.map((item, index) => (
                  <ListItemButton
                    key={item.pro_id}
                    disabled={isLoading}
                    selected={selectedPrompt === index}
                    onClick={(event) => handleListPromptClick(event, index)}
                  >
                    <ListItemText primary={item.pro_name} secondary={item.pro_content} />
                  </ListItemButton>
                ))}
              </List>
              <MDBox color="red">{simpleValidator.current.message("Name", name, "required")}</MDBox>
            </MDBox>
            <MDBox mb={2} pb={2}>
              <MDButton color="success" onClick={() => setEditPrompt(true)} disabled={isLoading}>
                Edit prompt
              </MDButton>
            </MDBox>
            {editPrompt && (
              <>
                <MDBox mb={2} display="flex" justifyContent="left">
                  <MDInput
                    type="text"
                    label="Prompt name"
                    fullWidth
                    value={promptName}
                    disabled={isLoading}
                    onChange={handleChangePromptName}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDTypography>
                    Create a blog post about “topic” in HTML format. Write it in a “character” tone.
                  </MDTypography>
                  <TextField
                    variant="outlined"
                    placeholder="Blog article prompt"
                    value={promptContent}
                    onChange={handleChangePromptContent}
                    multiline
                    fullWidth
                    rows={10}
                    disabled={isLoading}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDButton
                    color="secondary"
                    variant="outlined"
                    onClick={() => setEditPrompt(!editPrompt)}
                    disabled={isLoading}
                  >
                    Close
                  </MDButton>
                </MDBox>
              </>
            )}
          </Fragment>
        );
      case 4: //Article
        return (
          <MDBox mb={2} mt={2} ml={2} mr={2}>
            <Grid mb={2} container spacing={4}>
              <Grid item xs={12}>
                <MDBox display="flex" justifyContent="center">
                  {isLoading && (
                    <Fragment>
                      <MDTypography variant="h6" fontWeight="medium" mr={2}>
                        Generating article
                      </MDTypography>
                      <CircularProgress
                        size={24}
                        style={{
                          marginRight: 15,
                          marginLeft: 15,
                          position: "relative",
                          top: 4,
                        }}
                      />
                    </Fragment>
                  )}
                </MDBox>
              </Grid>
              {!isLoading && (
                <Fragment>
                  <Grid item xs={12}>
                    <MDBox pb={2} display="flex" justifyContent="left">
                      <MDTypography variant="h6" fontWeight="medium" mt={1} mr={2}>
                        Select a Headline
                      </MDTypography>
                      <Select
                        disabled={isLoading}
                        onChange={handleChangeSelectedHeadline}
                        value={selectedHeadline}
                        label="Pick a headline to use"
                      >
                        {headlineArr.map((item) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </MDBox>
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      type="text"
                      label="Headline"
                      multiline
                      fullWidth
                      value={headline}
                      onChange={handleChangeHeadline}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      type="text"
                      label="Introduction paragraph"
                      multiline
                      fullWidth
                      value={intro}
                      onChange={handleChangeIntro}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Editor
                      apiKey="9tgbg46laoyq2e9c3x41umzy6i0vgy4stjcr25g7b0sednhu"
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      value={article}
                      onEditorChange={handleChangeEditor}
                      init={{
                        height: 500,
                        menubar: false,
                        plugins: "advlist code anchor fullscreen table lists link image ",
                        toolbar:
                          "undo redo | fullscreen code | styles | fontfamily fontsize | bold italic underline strikethrough | align |link image | bullist numlist | table tabledelete | emoticons",
                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                      p={2}
                      mb={1}
                      textAlign="center"
                    >
                      <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
                        Sub topics
                      </MDTypography>
                    </MDBox>
                    {loadingContent && (
                      <MDBox textAlign="center">
                        <CircularProgress
                          size={24}
                          style={{ marginLeft: 15, position: "relative", top: 4 }}
                        />
                        <MDTypography variant="h6" fontWeight="medium" ml={1}>
                          {loadingLabel}...
                        </MDTypography>
                      </MDBox>
                    )}
                    {loadingSubTopics && (
                      <MDBox textAlign="center">
                        <CircularProgress
                          size={24}
                          style={{ marginLeft: 15, position: "relative", top: 4 }}
                        />
                        <MDTypography variant="h6" fontWeight="medium" ml={1}>
                          {loadingLabel}...
                        </MDTypography>
                      </MDBox>
                    )}
                    {!loadingSubTopics && !loadingContent && (
                      <List sx={{ width: "100%" }} style={{ height: "500px", overflow: "auto" }}>
                        {subtopics.map((item, index) => (
                          <ListItemButton
                            key={index}
                            onClick={(event) => handleListSubtopicClick(event, index)}
                          >
                            <ListItemText secondary={item.subtopic} />
                          </ListItemButton>
                        ))}
                      </List>
                    )}
                  </Grid>
                </Fragment>
              )}
            </Grid>
          </MDBox>
        );
      case 5: // IMAGE
        return (
          <Fragment>
            <MDTypography variant="h5" fontWeight="medium">
              Image to insert in the article
            </MDTypography>
            {imageUrl != "" && <img src={imageUrl} width={300} height={300} />}
            <TextField
              type="text"
              label="Image prompt"
              multiline
              fullWidth
              value={imagePrompt}
              onChange={handleChangeImagePrompt}
            />
            <MDButton
              color="info"
              onClick={() => generateImage()}
              mr={2}
              disabled={isGeneratingImage || imagePrompt == ""}
            >
              Generate a new image from prompt
              {isGeneratingImage && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 15, position: "relative", top: 4 }}
                />
              )}
            </MDButton>
            <MDTypography variant="h5" fontWeight="medium" mt={1}>
              Upload an image related to this article
            </MDTypography>
            <InputFiles
              onChange={(file) => onFileChange(file)}
              disabled={isUploading}
              accept="image/*"
            >
              <IconButton color="black" aria-label="upload">
                <Tooltip id="button-report" title="Upload image">
                  <ImageIcon />
                </Tooltip>
                <MDTypography variant="h6" fontWeight="medium" ml={1}>
                  Pick a image to upload
                </MDTypography>
              </IconButton>
            </InputFiles>
            <MDButton
              onClick={() => uploadImage()}
              disabled={isUploading || file == null}
              mb={2}
              color="info"
              variant="contained"
              endIcon={<UploadIcon />}
            >
              Upload
            </MDButton>
            {isUploading && (
              <MDBox display="flex">
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 15, position: "relative", top: 4 }}
                />
                <MDTypography variant="h6" fontWeight="medium" ml={1}>
                  {loadingLabel}...
                </MDTypography>
              </MDBox>
            )}
          </Fragment>
        );
      case 6: //PREVIEW
        return (
          <Fragment>
            {isLoadingPreview && (
              <MDBox display="flex" justifyContent="center">
                <MDTypography variant="h6" fontWeight="medium" ml={1} mr={2}>
                  Generating preview
                </MDTypography>
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 15, position: "relative", top: 4 }}
                />
              </MDBox>
            )}

            <MDBox mb={2}>
              <iframe
                src={url}
                height="600"
                width="100%"
                allowFullScreen
                style={{ border: "none" }}
              />
            </MDBox>
          </Fragment>
        );
      case 7: // PUBLISH
        return (
          <MDBox display="flex" justifyContent="center">
            <MDTypography variant="h5" fontWeight="medium" mr={2} ml={1}>
              Select a category to publish the article
            </MDTypography>
            {categories != null && (
              <Select
                onChange={handleChangeCategory}
                value={selectedCategory}
                label="Pick a category"
              >
                {categories.map((item, index) => (
                  <MenuItem key={item.id} value={index}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          </MDBox>
        );
    }
  };

  ///////////////////////////////////////////////////////////STEPS END ///////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    getPrompt();
    getCharacters();
    getInterests();
  }, []);

  useEffect(() => {
    if (selectedSubtopic != "") getSubtopicContent();
  }, [selectedSubtopic]);

  useEffect(() => {
    if (selectedInterest != null) {
      getTopics();
      // getGoogleTopics();
    }
  }, [selectedInterest]);

  useEffect(() => {
    setSelectedCategory(0);
  }, [categories]);

  const onCancel = () => {
    setHeadlineArr([]);
    setTopics([]);
    setSubTopics([]);
    setGoogleTopics([]);
    setUpdatedContent([]);
    setSelectedCharacter(8);
    setSelectedTopic(null);
    setSelectedSubtopic("");
    setSelectedUpdatedContent("");
    setIntro("");
    setArticle("");
    setURL("");
    setImageUrl("");
    setImagePrompt("");
    setTopic("");
    setSelectedInterests(null);
    setSelectedPrompt(null);
    setPost(null);
    setFile(null);
    setCategories(null);
    setActiveStep(0);
    setFailedSteps([]);
    closeModal();
  };

  //#region API Calls
  const getCharacters = async () => {
    const options = {
      method: "GET",
    };
    options.url = `characters`;
    client
      .request(options)
      .then((response) => {
        setCharacters(response);
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };

  const getInterests = async () => {
    const options = {
      method: "GET",
    };
    options.url = `blogArticles/getInterests?blog_id=` + props.blogId;

    client
      .request(options)
      .then((response) => {
        setInterests(response);
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };

  const getTopics = async () => {
    setLoadingOpenAITopics(true);
    setLoadingLabel("Loading topics");
    const options = {
      method: "GET",
    };
    options.url = `blogArticles/getTopics?interest=` + interests[selectedInterest].int_keyword;

    client
      .request(options)
      .then((response) => {
        setTopics(response);
        setLoadingOpenAITopics(false);
        // setSelectedTopic(response[0].topic);
        // setTopic(response[0].topic);
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };

  const getPrompt = async () => {
    const options = {
      method: "GET",
    };
    options.url = `blogArticles/GetContentPrompt`;
    // console.log(options.url);
    client
      .request(options)
      .then((response) => {
        //console.log(response);
        setPrompt(response);
        setSelectedPrompt(selectedPrompt);
        setPromptContent(response[0].pro_content);
        setPromptName(response[0].pro_name);
        // setTopic(response[0].topic);
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };

  const handleGoogleSearch = async () => {
    setIsLoadingGoogleContent(true);
    const options = {
      method: "GET",
    };
    options.url = `blogArticles/GoogleSearch?query=` + topic;
    client
      .request(options)
      .then((response) => {
        setIsLoadingGoogleContent(false);
        setUpdatedContent(response);
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };

  const getSubTopics = async () => {
    setLoadingSubTopics(true);
    setLoadingLabel("Loading subtopics");
    const options = {
      method: "GET",
    };
    options.url = `blogArticles/getSubTopics?topic=${topic}`;
    // console.log(options.url);
    client
      .request(options)
      .then((response) => {
        setSubTopics(response);
        setLoadingSubTopics(false);
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };

  const handleChangeCharacter = (event) => {
    setSelectedCharacter(event.target.value);
  };

  const generateImage = async () => {
    setIsGeneratingImage(true);
    const options = {
      method: "GET",
    };
    options.url = `blogArticles/generateImage?prompt=${imagePrompt}`;
    client
      .request(options)
      .then((response) => {
        setIsGeneratingImage(false);
        setFile(null);
        setImageUrl(response);
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };

  const handleGenerate = async () => {
    setIsLoading(true);
    setLoadingLabel("Generating article");
    const options = {
      method: "GET",
    };
    options.url =
      `blogArticles/getArticleContent?topic=${topic}&character_id=${selectedCharacter}&custom_prompt=${promptContent}&prompt_name=${promptName}` +
      `${selectedUpdatedContent != "" ? `&url=${selectedUpdatedContent}` : ``}`;

    // console.log(options.url);
    client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        //  console.log(response);
        setHeadlineArr(response.headlines);
        response.headlines[0] ?? setSelectedHeadline(response.headlines[0]);
        response.headlines[0] ?? setHeadline(response.headlines[0]);
        setIntro(response.introduction);
        setArticle(response.introduction + response.article);
        setImageUrl(response.imageURL);
        getSubTopics();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const getSubtopicContent = async () => {
    setLoadingContent(true);
    setLoadingLabel("Loading subtopic content");
    const options = {
      method: "GET",
    };
    options.url = `blogArticles/getSubTopicContent?topic=${topic}&subtopic=${selectedSubtopic}`;
    client
      .request(options)
      .then((response) => {
        setLoadingContent(false);
        editorRef.current.insertContent("<h2>" + selectedSubtopic + "</h2>" + response);
      })
      .catch((error) => {
        setLoadingContent(false);
        console.log("ERROR", error);
      });
  };

  const handleSave = async () => {
    setOnUpdating(true);
    setLoadingLabel("Publishing article");
    const options = {
      method: "POST",
      url: `blogArticles/RefreshPreview`,
      headers: {
        "content-type": "application/json",
      },
    };

    post.categories = [categories[selectedCategory].id];
    options.data = JSON.stringify({
      post: post,
      contentHtml: article,
      Title: headline,
      Introduction: intro,
      imageFilename: "",
      save: true,
      topic: topic,
      bla_blo_id: 0,
      blog_id: props.blogId,
    });

    client
      .request(options)
      .then((response) => {
        setOnUpdating(false);
        clearVariables();
        onCancel();
        // setHeadline(response.headline);
        //setIntro(response.introduction);
        //setArticle(response.post.content.raw);
        //setPost(response.post);
        //setURL(response.articleURL + "?h=" + Moment().format("YYYYMMDDHHmmss"));
      })
      .catch((error) => {
        setOnUpdating(false);
        console.log("ERROR", error);
      });
  };

  const previewArticle = () => {
    setIsLoadingPreview(true);
    setLoadingLabel("Updating article");
    const options = {
      method: "POST",
      url: `blogArticles/GeneratePreview`,
      headers: {
        "content-type": "application/json",
      },
    };

    options.data = JSON.stringify({
      post: {},
      contentHtml: article,
      Title: headline,
      Introduction: intro,
      imageFilename: file != null ? file : imageUrl,
      save: false,
      topic: topic,
      bla_blo_id: 0,
      blog_id: props.blogId,
    });
    // console.log(options.data);
    // console.log(file);
    if (post != null) {
      options.url = `blogArticles/RefreshPreview`;
      options.data = JSON.stringify({
        post: post,
        contentHtml: article,
        Title: headline,
        Introduction: intro,
        imageFilename: file != null ? "" : imageUrl != null ? imageUrl : "",
        save: false,
        topic: topic,
        bla_blo_id: 0,
        blog_id: props.blogId,
      });
    }
    // console.log(options.data);
    client
      .request(options)
      .then((response) => {
        setIsLoadingPreview(false);
        setURL(response.articleURL + "?h=" + Moment().format("YYYYMMDDHHmmss"));
        setPost(response.post);
        setCategories(response.categories);
        setIsReadyToPublish(true);
      })
      .catch((error) => {
        setIsLoadingPreview(false);
        console.log(error);
      });
  };

  const onFileChange = (event) => {
    setFile(event[0]);
  };

  const uploadImage = () => {
    setIsUploading(true);
    setLoadingLabel("Uploading image and updating article");
    const options = {
      method: "POST",
      url: `imageUpload`,
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    const formData = new FormData();
    formData.append("file", file);
    formData.append("post_id", post.id);
    formData.append("blog_id", props.blogId);

    options.data = formData;
    client
      .request(options)
      .then((response) => {
        setIsUploading(false);
        setPost(response);
        setURL(url + "?h=" + Moment().format("YYYYMMDDHHmmss"));
      })
      .catch((error) => {
        setIsUploading(false);
        console.log(error);
      });
  };

  //#endregion

  //#region HandleChangeFunctions

  const handleChangePrompt = (event) => {
    setPrompt(event.target.value);
  };

  const handleChangeTopic = (topic) => {
    setSelectedTopic(topic);
    setTopic(topic);
  };

  const handleChangeUpdatedContent = (url) => {
    setSelectedUpdatedContent(url);
  };

  const handleCancelGoogleSearch = () => {
    setSelectedUpdatedContent("");
    setUpdatedContent([]);
  };

  const handleChangeTextTopic = (event) => {
    // setSelectedTopic(event.target.value);
    setTopic(event.target.value);
  };

  const handleListInterestsClick = (event, index) => {
    setSelectedInterests(index);
  };

  const handleListPromptClick = (event, index) => {
    setSelectedPrompt(index);
    setPromptContent(prompt[index].pro_content);
    setPromptName(prompt[index].pro_name);
  };

  const handleListSubtopicClick = (event, index) => {
    setSelectedSubtopic(subtopics[index].subtopic);
  };

  const handleChangeCategory = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleChangeOffer = (event) => {
    setSelectedOffer(event.target.value);
  };

  const handleChangeSelectedHeadline = (event) => {
    setSelectedHeadline(event.target.value);
    setHeadline(event.target.value);
  };

  const handleChangeHeadline = (event) => {
    setHeadline(event.target.value);
  };

  const handleChangeIntro = (event) => {
    setIntro(event.target.value);
  };

  const handleChangeImagePrompt = (event) => {
    setImagePrompt(event.target.value);
  };

  const handleChangePromptName = (event) => {
    setPromptName(event.target.value);
  };

  const handleChangePromptContent = (event) => {
    setPromptContent(event.target.value);
  };

  const handleChangeBlog = (event) => {
    setSelectedBlog(event.target.value);
  };

  const handleChangeEditor = (e) => {
    setArticle(e);
    //Get Content Inside Editor
  };

  //#endregion

  return (
    <Dialog
      open={openModal}
      onClose={(e, reason) => clearVariables(reason)}
      fullScreen
      disableEscapeKeyDown
      style={{ marginLeft: margin }}
      sx={{
        //You can copy the code below in your theme
        background: theme.palette.background.default,
        "& .MuiPaper-root": {
          background: theme.palette.background.default,
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent", // Try to remove this to see the result
        },
      }}
    >
      <MDBox
        variant="gradient"
        bgColor="primary"
        borderRadius="lg"
        coloredShadow="primary"
        mx={2}
        mt={-1}
        p={2}
        mb={1}
        textAlign="center"
      >
        <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
          Create a Blog Article
        </MDTypography>
      </MDBox>
      <DialogContent>
        <MDBox
          pt={4}
          pb={3}
          px={2}
          sx={{
            //You can copy the code below in your theme
            background: "#FFFFFF",
            "& .MuiPaper-root": {
              background: "#FFFFFF",
            },
            "& .MuiBackdrop-root": {
              backgroundColor: "transparent", // Try to remove this to see the result
            },
          }}
        >
          <MDBox component="form" role="form">
            <Card>
              <Stepper activeStep={activeStep} style={{ padding: 10 }}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  if (isStepOptional(index)) {
                    labelProps.optional = <MDTypography variant="caption">Optional</MDTypography>;
                  }
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  if (isStepFailed(index)) {
                    labelProps.optional = (
                      <MDTypography variant="body" color="error">
                        <WarningIcon />
                        You must select a value
                      </MDTypography>
                    );
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel i {...labelProps}>
                        {label}
                      </StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              <Fragment>
                <MDBox m={5}>{getStep(activeStep)}</MDBox>
              </Fragment>
            </Card>
          </MDBox>
        </MDBox>
      </DialogContent>
      <DialogActions style={{ color: "white", backgroundColor: "white" }}>
        <MDBox
          sx={{
            display: "flex",
            justifyContent: "left",
            flexDirection: "row",
            p: 2,
          }}
        >
          <MDButton
            disabled={activeStep === 0 || isLoading}
            onClick={handleBack}
            sx={{ mr: 1 }}
            variant="outlined"
            color="info"
          >
            Back
          </MDButton>

          {isStepOptional(activeStep) && (
            <MDButton
              disabled={isLoading}
              onClick={handleSkip}
              sx={{ mr: 1 }}
              variant="outlined"
              color="info"
            >
              Skip
            </MDButton>
          )}
          <MDButton disabled={isLoading} onClick={handleNext} variant="gradient" color="info">
            {activeStep === steps.length - 1 ? "Publish" : "Next"}
          </MDButton>
        </MDBox>
        {onUpdating && (
          <MDBox display="flex">
            <CircularProgress size={24} style={{ marginLeft: 15, position: "relative", top: 4 }} />
            <MDTypography variant="h6" fontWeight="medium" ml={1}>
              {loadingLabel}...
            </MDTypography>
          </MDBox>
        )}
        <MDButton
          onClick={() => clearVariables()}
          disabled={isLoading}
          variant="outlined"
          color="secondary"
        >
          Cancel
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default AddArticle;
