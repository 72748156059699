/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// import { useState } from "react";
// @mui material components
import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { TableCell } from "@material-ui/core";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import EditIcon from "@mui/icons-material/Edit";
import client from "ApiClient";
import ConfirmationAlert from "components/ConfirmationAlert";
import { CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import CustomToolbarSelect from "./customToolbarSelect";
import Edit from "./edit";

// import Edit from "./edit";

/* eslint-disable */
export default function Table(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [items, setItems] = useState([]);
  const [editedItem, setEditedItem] = useState(null);
  const [selectedItem, setSelectedItem] = useState([]);
  const [create, setCreate] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  // const handleCloseModal = () => setOpenModal(false);

  const oldRender = TableCell.render;
  TableCell.render = function (...args) {
    const [props, ...otherArgs] = args;
    if (typeof props === "object" && props && "isEmpty" in props) {
      const { isEmpty, ...propsWithoutEmpty } = props;
      return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
    } else {
      return oldRender.apply(this, args);
    }
  };

  const options = {
    method: "GET",
    url: "senderProfiles",
  };

  const getData = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `senderProfiles`;
    await client
      .request(options)
      .then((response) => {
        console.log(response);
        setIsLoading(false);
        setItems(response);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getSenderDomains = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `senderDomains?verified=true`;
    await client
      .request(options)
      .then((response) => {
        response.length > 0 ? setCreate(true) : setCreate(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEdit = () => {
    handleOpenModal();
  };

  useEffect(() => {
    setIsLoading(true);
    getSenderDomains();
    getData();
  }, [props.groupAdded]);

  const handleDelete = () => {
    setIsLoading(true);
    options.method = "DELETE";
    options.url = `senderProfiles?sep_id=${items
      .filter((item, index) => {
        return selectedItem.indexOf(index) >= 0 ? true : false;
      })
      .map((b) => b.sep_id)
      .toString()}`;
    options.headers = {
      "content-type": "application/json",
    };
    client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        getData();
        setOpenAlert(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const columns = [
    {
      label: "Sender name",
      name: "sep_name",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "From email",
      name: "sep_envelopFrom",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "Reply email",
      name: "sep_replyTo",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "Company",
      name: "sep_company",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "Address",
      name: "sep_address",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: " ",
      name: "sep_id",
      options: {
        filter: true,
        setCellHeaderProps: () => {
          return { align: "right" };
        },
        setCellProps: () => {
          return { align: "right" };
        },
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div>
              <IconButton
                onClick={() => {
                  setEditedItem(items[dataIndex]);
                  setOpenEdit(true);
                }}
                sx={{ fontWeight: "bold" }}
                color="primary"
                aria-label="prompt"
              >
                <Tooltip id="button-report" title="Edit">
                  <EditIcon />
                </Tooltip>
              </IconButton>
            </div>
          );
        },
      },
    },
  ];

  const customToolbar = () => <CustomToolbarSelect onDelete={() => setOpenAlert(true)} />;

  const tableOptions = {
    textLabels: {
      body: {
        noMatch: "You don't have any sender profile",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "Clear filter",
      },
      filterType: "dropdown",
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "sender profile/s selected",
      },
    },
    print: false,
    filter: false,
    viewColumns: false,
    download: false,
    selectableRowsHideCheckboxes: false,
    selectableRowsOnClick: false,
    responsive: "simple",
    filterType: "dropdown",
    selectableRows: "single",
    fixedHeader: true,
    fixedSelectColumn: true,
    searchOpen: true,
    searchAlwaysOpen: true,
    searchPlaceholder: "Search",
    downloadOptions: { filename: "blogs.csv", separator: "," },
    onRowSelectionChange: (curRowSelected, allRowsSelected, rowsSelected) => {
      setSelectedItem(rowsSelected);
    },
    customToolbarSelect: customToolbar,
  };

  return (
    <div>
      <ConfirmationAlert
        openModal={openAlert}
        onClose={() => setOpenAlert(false)}
        onYes={handleDelete}
        yesText="Yes"
        title="Are you sure you want to delete this sender profile?"
      />
      <Edit
        openModal={openEdit}
        onClose={() => setOpenEdit(false)}
        profile={editedItem}
        onDone={() => getData()}
      />

      <MDBox
        mx={2}
        mt={1}
        py={3}
        px={2}
        bgColor="badgeColors.primary"
        borderRadius="lg"
        variant="gradient"
      >
        <Grid container>
          <Grid item xs={12} md={10}>
            <MDTypography variant="h2">
              Sender profiles
              {isLoading && (
                <CircularProgress
                  size={30}
                  style={{ marginLeft: 15, position: "relative", top: 4 }}
                />
              )}
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={2} style={{ width: "100%", textAlign: "right" }}>
            <MDBox justifyContent="right">
              {create && (
                <Tooltip title="Create a new sender profile">
                  <MDButton variant="gradient" color="secondary" onClick={props.onAdd}>
                    Create new
                  </MDButton>
                </Tooltip>
              )}
            </MDBox>
          </Grid>
        </Grid>
        {!create && !isLoading && (
          <MDTypography variant="caption" color="error">
            You need to verify a sender domain to add a sender profile.
          </MDTypography>
        )}
      </MDBox>

      <MDBox>
        <Grid container p={2}>
          <Grid item xs={12}>
            <div style={{ height: "100%", width: "100%" }}>
              <MUIDataTable data={items} columns={columns} options={tableOptions} />
            </div>
          </Grid>
        </Grid>
      </MDBox>
    </div>
  );
}
