import { useEffect, useState } from "react";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import MDTypography from "./MDTypography";
import MDBox from "./MDBox";

/* eslint-disable */
export default function SignalRMessages(props) {
  const [connection, setConnection] = useState();
  const [serverMessage, setserverMessage] = useState();

  useEffect(() => {
    const connect = new HubConnectionBuilder()
      .withUrl("https://io.aimailer.net/signalr")
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build();
    setConnection(connect);
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          //  console.log("connected");

          connection.on(props.listener, (Message) => {
            if (Message.includes("Error")) console.log(Message);
            else setserverMessage(Message);
          });

          connection.on("OnConnectedAsync", (Message) => {
            //  console.log(Message);
          });
          connection?.invoke("joinGroup", localStorage.getItem("userName"));
        })
        .catch((error) => console.log(error));
    }
  }, [connection]);

  return (
    <MDBox display="flex" ml={2}>
      <MDTypography>{serverMessage}</MDTypography>
    </MDBox>
  );
}
