// https://dailyfix.org

import client from "ApiClient";
import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

/* eslint-disable */
export default function AdditionalContent(props) {
  const [isLoading, setIsLoading] = useState(false);
  const { openModal, closeModal } = props;
  const [adcID, setAdcID] = useState([]);
  const [qod, setQod] = useState("");
  const [tq, setTq] = useState("");
  const [jotd, setJotd] = useState("");
  const [adcif, setAdcif] = useState("");
  const [qaa, setQaa] = useState("");
  const [totd, setTotd] = useState("");
  const [slh, setSlh] = useState("");

  useEffect(() => {
    clearData();
    if (props.blaId > 0) getData();
  }, [props.blaId]);

  const clearData = () => {
    setAdcID("");
    setQod("");
    setTq("");
    setJotd("");
    setAdcif("");
    setQaa("");
    setTotd("");
    setSlh("");
  };

  const options = {
    method: "GET",
    url: "emailIntros",
    headers: {
      "content-type": "application/json",
    },
  };

  const generate = async () => {
    setIsLoading(true);
    const options = {
      method: "GET",
      url: `blogArticles/GenerateAdditionalContent?bla_id=${props.blaId}&topic=${props.article}`,
    };
    // console.log(options.data);
    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        getData();
      })
      .catch((error) => {
        setIsLoading(false);
        // eslint-disable-next-line
        console.log(error);
      });
  };

  const getData = async () => {
    options.method = "GET";
    options.url = `emailIntros/additionalContent?bla_id=${props.blaId}`;
    await client
      .request(options)
      .then((response) => {
        setAdcID(response.adc_id);
        setQod(response.adc_qod);
        setTq(response.adc_tq);
        setJotd(response.adc_jotd);
        setAdcif(response.adc_if);
        setQaa(response.adc_qaa);
        setTotd(response.adc_totd);
        setSlh(response.adc_slh);
        // console.log(JSON.parse(response.adc_tq));
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.log(error);
      });
  };

  const savePost = async () => {
    setIsLoading(true);
    const options = {
      method: "PUT",
      url: `emailIntros/additionalContent`,
      headers: {
        "content-type": "application/json",
      },
    };
    options.data = JSON.stringify({
      adc_id: adcID,
      adc_bla_id: props.blaId,
      adc_qod: qod,
      adc_tq: tq,
      adc_jotd: jotd,
      adc_if: adcif,
      adc_qaa: qaa,
      adc_totd: totd,
      adc_slh: slh,
    });

    // console.log(options.data);
    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        closeModal();
      })
      .catch((error) => {
        setIsLoading(false);
        // eslint-disable-next-line
        console.log(error);
      });
  };

  const handleChangeQod = (event) => {
    setQod(event.target.value);
  };

  const handleChangeTq = (event) => {
    setTq(event.target.value);
  };

  const handleChangeJotd = (event) => {
    setJotd(event.target.value);
  };

  const handleChangeIf = (event) => {
    setAdcif(event.target.value);
  };

  const handleChangeQaa = (event) => {
    setQaa(event.target.value);
  };

  const handleChangeTotd = (event) => {
    setTotd(event.target.value);
  };

  const handleChangeSlh = (event) => {
    setSlh(event.target.value);
  };

  return (
    <div>
      <Dialog open={openModal} onClose={closeModal} fullScreen>
        <Card>
          <MDBox pt={4} pb={3} px={2}>
            <DialogContent>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={0}
                mt={0}
                p={2}
                mb={4}
                textAlign="center"
              >
                <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
                  Additional content for "{props.article}"
                </MDTypography>
              </MDBox>
              <MDBox pb={2} display="flex" justifyContent="left">
                <TextField
                  type="text"
                  label="Qoute of the day"
                  multiline
                  fullWidth
                  disabled
                  value={qod}
                  onChange={handleChangeQod}
                />
              </MDBox>
              <MDBox pb={2} display="flex" justifyContent="left">
                <TextField
                  type="text"
                  label="Trivia questions"
                  multiline
                  fullWidth
                  value={tq}
                  disabled
                  onChange={handleChangeTq}
                />
              </MDBox>
              <MDBox pb={2} display="flex" justifyContent="left">
                <TextField
                  type="text"
                  label="Joke of the day"
                  multiline
                  fullWidth
                  disabled
                  value={jotd}
                  onChange={handleChangeJotd}
                />
              </MDBox>
              <MDBox pb={2} display="flex" justifyContent="left">
                <TextField
                  type="text"
                  label="Interesting facts"
                  multiline
                  disabled
                  fullWidth
                  value={adcif}
                  onChange={handleChangeIf}
                />
              </MDBox>
              <MDBox pb={2} display="flex" justifyContent="left">
                <TextField
                  type="text"
                  label="Question and answer"
                  multiline
                  disabled
                  fullWidth
                  value={qaa}
                  onChange={handleChangeQaa}
                />
              </MDBox>
              <MDBox pb={2} display="flex" justifyContent="left">
                <TextField
                  type="text"
                  label="Tip of the day"
                  multiline
                  disabled
                  fullWidth
                  value={totd}
                  onChange={handleChangeTotd}
                />
              </MDBox>
              <MDBox pb={2} display="flex" justifyContent="left">
                <TextField
                  type="text"
                  label="Secret little hack"
                  multiline
                  disabled
                  fullWidth
                  value={slh}
                  onChange={handleChangeSlh}
                />
              </MDBox>
            </DialogContent>
            <DialogActions style={{ color: "white", backgroundColor: "white" }}>
            
              <Button autoFocus onClick={closeModal} disabled={isLoading}>
                Close
              </Button>
             
              {isLoading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 15, position: "relative", top: 4 }}
                />
              )}
            </DialogActions>
          </MDBox>
        </Card>
      </Dialog>
    </div>
  );
}
/*
 <Button autoFocus onClick={generate} disabled={isLoading}>
                Generate
              </Button>
              <Button onClick={savePost} disabled={isLoading} autoFocus>
                Save
              </Button>
              */