import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import client from "ApiClient";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Switch,
  TextField,
} from "@mui/material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useMaterialUIController } from "context";
import theme from "assets/theme";

/* eslint-disable */
function Add(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [expires, setExpires] = useState(false);
  const [externalID, setExternalID] = useState("");
  const [name, setName] = useState("");
  const [text, setText] = useState("");
  const [link, setLink] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const { openModal, closeModal } = props;

  const [margin, setMargin] = useState(300);
  const [controller] = useMaterialUIController();
  const { miniSidenav } = controller;

  useEffect(() => {
    if (miniSidenav) setMargin(0);
    else setMargin(300);
  }, [miniSidenav]);

  document.addEventListener("focusin", (e) => {
    if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
      e.stopImmediatePropagation();
    }
  });

  const clearVariables = (reason) => {
    if (reason === "backdropClick") return;
    setExpirationDate("");
    setExternalID("");
    setName("");
    setText("");
    setLink("");
    setImageURL("");
    setExpires(false);
    closeModal();
  };

  const handleSave = async () => {
    setIsLoading(true);
    const options = {
      method: "POST",
      url: `items`,
      headers: {
        "content-type": "application/json",
      },
    };

    options.data = JSON.stringify({
      item_cat_id: props.catId,
      item_externalID: externalID,
      item_name: name,
      item_text: text,
      item_link: link,
      item_imageURL: imageURL,
      item_expirationDate: expires ? expirationDate : null,
      item_status: 0,
    });

    client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        props.onDone();
        props.closeModal();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  return (
    <Dialog
      open={openModal}
      onClose={(e, reason) => clearVariables(reason)}
      fullScreen
      disableEscapeKeyDown
      style={{ marginLeft: margin }}
      sx={{
        //You can copy the code below in your theme
        background: theme.palette.background.default,
        "& .MuiPaper-root": {
          background: theme.palette.background.default,
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent", // Try to remove this to see the result
        },
      }}
    >
      <Card spacing={2}>
        <MDBox
          variant="gradient"
          bgColor="primary"
          borderRadius="lg"
          coloredShadow="primary"
          mx={2}
          mt={2}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
            Add an item
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={2}>
          <DialogContent>
            <MDBox component="form" role="form">
              <Card>
                <MDBox mb={2} mt={2} ml={2} mr={2}>
                  <MDBox pb={2} display="flex" justifyContent="left">
                    <MDInput
                      type="text"
                      label="Item name"
                      fullWidth
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </MDBox>
                  <MDBox pb={2} display="flex" justifyContent="left">
                    <MDInput
                      type="text"
                      label="Text"
                      multiline
                      fullWidth
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                    />
                  </MDBox>
                  <MDBox pb={2} display="flex" justifyContent="left">
                    <TextField
                      type="text"
                      label="Link"
                      fullWidth
                      value={link}
                      onChange={(e) => setLink(e.target.value)}
                    />
                  </MDBox>
                  <MDBox pb={2} display="flex" justifyContent="left">
                    <TextField
                      type="text"
                      label="External ID"
                      fullWidth
                      value={externalID}
                      onChange={(e) => setExternalID(e.target.value)}
                    />
                  </MDBox>
                  <MDBox pb={2} display="flex" justifyContent="left">
                    <MDInput
                      type="text"
                      label="Image URL"
                      fullWidth
                      value={imageURL}
                      onChange={(e) => setImageURL(e.target.value)}
                    />
                  </MDBox>
                  <MDBox pb={2} display="flex" justifyContent="left">
                    <Switch checked={expires} onChange={() => setExpires(!expires)} />
                    <MDTypography>Set expiration date</MDTypography>
                  </MDBox>
                  {expires && (
                    <MDBox pb={2} display="flex" justifyContent="left">
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          required
                          renderInput={(props) => <TextField {...props} />}
                          label="Select the expiration date "
                          value={expirationDate}
                          onChange={(newValue) => {
                            setExpirationDate(newValue);
                          }}
                        />
                      </LocalizationProvider>
                    </MDBox>
                  )}
                </MDBox>
              </Card>
            </MDBox>
          </DialogContent>
          <DialogActions style={{ color: "white", backgroundColor: "white" }}>
            <MDButton
              onClick={closeModal}
              disabled={isLoading}
              variant="outlined"
              color="secondary"
            >
              Cancel
            </MDButton>
            <MDButton onClick={handleSave} variant="gradient" color="success">
              Save
              {isLoading && (
                <MDBox display="flex">
                  <CircularProgress
                    size={24}
                    style={{ marginLeft: 15, position: "relative", top: 4 }}
                  />
                </MDBox>
              )}
            </MDButton>
          </DialogActions>
        </MDBox>
      </Card>
    </Dialog>
  );
}

export default Add;
