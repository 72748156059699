/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// import { useState } from "react";
// @mui material components
import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { TableCell } from "@material-ui/core";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  CircularProgress,
  IconButton,
  Tooltip,
} from "@mui/material";
import LanguageIcon from "@material-ui/icons/Language";
import Moment from "moment";
import client from "ApiClient";
import MDButton from "components/MDButton";

/* eslint-disable */
export default function Table(props) {
  const [openModal, setOpenModal] = useState(false);
  const handleCloseModal = () => setOpenModal(false);
  const [isLoading, setIsLoading] = useState(true);
  const [sales, setSales] = useState([]);

  const oldRender = TableCell.render;
  TableCell.render = function (...args) {
    const [props, ...otherArgs] = args;
    if (typeof props === "object" && props && "isEmpty" in props) {
      const { isEmpty, ...propsWithoutEmpty } = props;
      return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
    } else {
      return oldRender.apply(this, args);
    }
  };

  const options = {
    method: "GET",
    url: "series",
  };

  const getData = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `sales`;
    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        setSales(response);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const getDateValue = (date) => {
    return Moment(date).format("M/D/YYYY  H:mm:ss");
  };

  const columns = [
    {
      label: "Source",
      name: "source",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "Source name",
      name: "name",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "Amount",
      name: "amount",
      options: {
        filter: true,
      },
    },
    {
      label: "Email",
      name: "email",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "Date",
      name: "date",
      options: {
        filter: true,
        customBodyRender: (value) => getDateValue(value),
      },
    },
  ];

  const tableOptions = {
    textLabels: {
      body: {
        noMatch: "You don't have any sales",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "Clear filter",
      },
      filterType: "dropdown",
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "sales/s selected",
      },
    },
    print: false,
    filter: false,
    viewColumns: false,
    selectableRowsHideCheckboxes: false,
    selectableRowsOnClick: false,
    responsive: "simple",
    filterType: "dropdown",
    selectableRows: "none",
    fixedHeader: true,
    fixedSelectColumn: true,
    searchOpen: true,
    searchAlwaysOpen: true,
    searchPlaceholder: "Search",
    downloadOptions: { filename: "sales.csv", separator: "," },
  };

  return (
    <div>
      <Dialog open={openModal} onClose={handleCloseModal} fullScreen>
        <MDBox
          variant="gradient"
          bgColor="primary"
          borderRadius="lg"
          coloredShadow="primary"
          mx={2}
          mt={-1}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
            Webhook instructions for reporting sales back to{process.env.REACT_APP_MAIN_DOMAIN}:
          </MDTypography>
        </MDBox>
        <DialogContent>
          <p>
            In order to send your sales information back to {process.env.REACT_APP_MAIN_DOMAIN},
            you need to call our sales reporting URL located at:
          </p>
          <p>https://lm.{process.env.REACT_APP_MAIN_DOMAIN}/sales</p>
          <p>With the following two parameters:</p>
          <table>
            <thead>
              <tr>
                <th align="left" width="200">
                  Parameter name
                </th>
                <th align="left" width="200">
                  Type
                </th>
                <th align="left" width="600">
                  Description
                </th>
                <th align="left" width="00">
                  Required
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ outline: "thin solid" }}>
                <td>ms_id</td>
                <td>string</td>
                <td>
                  Short for {localStorage.getItem("plName")} ID. It contains the unique internal
                  tracking information about the recipient, series / broadcast. You can use this
                  variable on your links sent from {localStorage.getItem("plName")} by using the
                  [ms-id] variable on your email copy links.
                </td>
                <td>Yes</td>
              </tr>
              <tr style={{ outline: "thin solid" }}>
                <td>amount</td>
                <td>decimal number (float)</td>
                <td>The amount of the sale.</td>
                <td>Yes</td>
              </tr>
            </tbody>
          </table>
          <br />
          <p>
            Example: https://ads.{process.env.REACT_APP_MAIN_DOMAIN}
            /webhook?ms_id=00213F210ABA14083F261E&amp;amount=39.89
          </p>
        </DialogContent>

        <DialogActions style={{ color: "white", backgroundColor: "white" }}>
          <MDButton
             
            onClick={() => setOpenModal(false)}
            variant="outlined"
            color="secondary"
          >
            Close
          </MDButton>
        </DialogActions>
      </Dialog>
      <MDBox
        mx={2}
        mt={1}
        py={3}
        px={2}
        bgColor="badgeColors.primary"
        borderRadius="lg"
        variant="gradient"
      >
        <MDTypography variant="h2">
          Sales
          {isLoading ? (
            <CircularProgress size={30} style={{ marginLeft: 15, position: "relative", top: 4 }} />
          ) : (
            <IconButton onClick={() => setOpenModal(true)} sx={{ fontWeight: "bold" }}>
              <Tooltip id="button-add" title="Webhook instructions">
                <LanguageIcon />
              </Tooltip>
            </IconButton>
          )}
        </MDTypography>
      </MDBox>

      <MDBox>
        <Grid container p={2}>
          <Grid item xs={12}>
            <div style={{ height: "100%", width: "100%" }}>
              <MUIDataTable data={sales} columns={columns} options={tableOptions} />
            </div>
          </Grid>
        </Grid>
      </MDBox>
    </div>
  );
}
