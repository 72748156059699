/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { useEffect, useState } from "react";
import TimelineItem from "examples/Timeline/TimelineItem";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import DraftsIcon from "@mui/icons-material/Drafts";
import MailLockIcon from "@mui/icons-material/MailLock";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import AdbIcon from "@mui/icons-material/Adb";
import NavigationIcon from "@mui/icons-material/Navigation";
import SellIcon from "@mui/icons-material/Sell";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import client from "ApiClient";
import moment from "moment/moment";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import MDButton from "components/MDButton";

/* eslint-disable */
function SubscriberLog(props) {
  const [subscriberLog, setSubscriberLog] = useState(null);
  const [noLog, setNoLog] = useState(false);
  const [subDetails, setSubDetails] = useState(null);
  const { openModal, onClose, subscriber } = props;

  const clearVariables = () => {
    setSubscriberLog(null);
    setSubDetails(null);
    props.onClose();
  };

  const getSubscriberLog = async () => {
    const options = {
      method: "POST",
      url: `subscribers/log`,
      headers: {
        "content-type": "application/json",
      },
    };
    options.data = JSON.stringify(subscriber);

    await client
      .request(options)
      .then((response) => {
        setSubscriberLog(response);
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };

  const getSubscriberDetails = async () => {
    const options = {
      method: "GET",
      url: `subscribers/getById?sub_id=${subscriber.id}`,
      headers: {
        "content-type": "application/json",
      },
    };

    await client
      .request(options)
      .then((response) => {
        setSubDetails(response);
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };

  useEffect(() => {
    setSubscriberLog(null);
    if (openModal) {
      getSubscriberLog();
      getSubscriberDetails();
    }
  }, [openModal]);

  useEffect(() => {
    setNoLog(false);
    if (subscriberLog !== null) {
      //  console.log(subscriberLog.length);
      if (subscriberLog.length === 0) setNoLog(true);
    }
  }, [subscriberLog]);

  /*
  useEffect(() => {
    const interval = setInterval(() => {
      getSubscriberLog();
    }, 60000);
    return () => clearInterval(interval);
  }, []);
  */

  const getEventName = (event) => {
    switch (event) {
      case 0:
        return "Clicked";
      case 1:
        return "Opened";
      case 2:
        return "Unsubscribed";
      case 3:
        return "Bounced";
      case 4:
        return "Complained";
      case 6:
        return "Landed";
      case 7:
        return "Navigated";
      case 8:
        return "Offer click";
      case 9:
        return "Offer open";
      case 10:
        return "Offer land";
      case 11:
        return "Offer navigated";
      case 12:
        return "Offer convert";
      case 101:
        return "Bot click";
      case 102:
        return "Bot open";
      case 103:
        return "Bot navigated";
      case 109:
        return "Open by Apple's proxy";
      case 110:
        return "Clicked by Apple's proxy";
    }
  };

  const getEventColor = (event) => {
    switch (event) {
      case 0:
        return "success";
      case 1:
        return "primary";
      case 2:
        return "warning";
      case 3:
        return "error";
      case 4:
        return "error";
      case 6:
        return "info";
      case 7:
        return "info";
      case 8:
        return "success";
      case 9:
        return "primary";
      case 10:
        return "info";
      case 11:
        return "info";
      case 12:
        return "success";
      case 101:
        return "error";
      case 102:
        return "error";
      case 103:
        return "error";
      case 109:
        return "primary";
      case 110:
        return "success";
    }
  };

  const getEventIcon = (event) => {
    switch (event) {
      case 0:
        return <AdsClickIcon fontSize="medium" />;
      case 1:
        return <DraftsIcon fontSize="medium" />;
      case 2:
        return <UnsubscribeIcon fontSize="medium" />;
      case 3:
        return <CancelScheduleSendIcon fontSize="medium" />;
      case 4:
        return <MailLockIcon fontSize="medium" />;
      case 6:
        return <FlightLandIcon fontSize="medium" />;
      case 7:
        return <NavigationIcon fontSize="medium" />;
      case 8:
        return <AdsClickIcon fontSize="medium" />;
      case 9:
        return <DraftsIcon fontSize="medium" />;
      case 10:
        return <FlightLandIcon fontSize="medium" />;
      case 11:
        return <NavigationIcon fontSize="medium" />;
      case 12:
        return <SellIcon fontSize="medium" />;
      case 101:
        return <AdbIcon fontSize="medium" />;
      case 102:
        return <AdbIcon fontSize="medium" />;
      case 103:
        return <AdbIcon fontSize="medium" />;
      case 109:
        return <DraftsIcon fontSize="medium" />;
      case 110:
        return <AdsClickIcon fontSize="medium" />;
    }
  };

  const getTitle = (log) => {
    return `${getEventName(log.tra_event)} on '${log.emc_subject}' from ${log.tra_ip}`;
  };

  return (
    <Dialog open={openModal} onClose={onClose}>
      <MDBox
        variant="gradient"
        bgColor="primary"
        borderRadius="lg"
        coloredShadow="primary"
        mx={2}
        mt={-1}
        p={2}
        mb={1}
        textAlign="center"
      >
        {subscriber && (
          <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
            Subscriber Log for {subscriber.email}
          </MDTypography>
        )}
      </MDBox>
      <DialogContent>
        {subDetails && (
          <MDBox p={2}>
            <MDTypography>
              <strong>Email:</strong> {subDetails.sub_email}
            </MDTypography>
            {subDetails.sub_first && (
              <MDTypography>
                <strong>First Name:</strong> {subDetails.sub_first}
              </MDTypography>
            )}
            {subDetails.sub_last && (
              <MDTypography>
                <strong>Last Name:</strong> {subDetails.sub_last}
              </MDTypography>
            )}
            {subDetails.sub_phone && (
              <MDTypography>
                <strong>Phone:</strong> {subDetails.sub_phone}
              </MDTypography>
            )}
            {subDetails.sub_country && (
              <MDTypography>
                <strong>Country:</strong> {subDetails.sub_country}
              </MDTypography>
            )}
            {subDetails.sub_state && (
              <MDTypography>
                <strong>State:</strong> {subDetails.sub_state}
              </MDTypography>
            )}
            {subDetails.sub_city && (
              <MDTypography>
                <strong>City:</strong> {subDetails.sub_city}
              </MDTypography>
            )}
            {subDetails.sub_zip && (
              <MDTypography>
                <strong>Zip:</strong> {subDetails.sub_zip}
              </MDTypography>
            )}
            {subDetails.sub_address && (
              <MDTypography>
                <strong>Address:</strong> {subDetails.sub_address}
              </MDTypography>
            )}
            {subDetails.sub_tags && (
              <MDTypography>
                <strong>Tags:</strong> {subDetails.sub_tags}
              </MDTypography>
            )}
            {subDetails.sub_optinDate && (
              <MDTypography>
                <strong>Optin Date :</strong> {moment(subDetails.sub_optinDate).format("lll")}
              </MDTypography>
            )}
            {subDetails.sub_lastEngagementDate && (
              <MDTypography>
                <strong>Last Engagement Date :</strong>{" "}
                {moment(subDetails.sub_lastEngagementDate).format("lll")}
              </MDTypography>
            )}
          </MDBox>
        )}
        {subscriberLog && (
          <MDBox p={2}>
            {subscriberLog.map((log) => (
              <TimelineItem
                key={log.tra_date}
                color={getEventColor(log.tra_event)}
                icon={getEventIcon(log.tra_event)}
                title={getTitle(log)}
                dateTime={`${moment.utc(log.tra_date).format("lll")} (${moment
                  .utc(log.tra_date)
                  .fromNow()})`}
              />
            ))}
          </MDBox>
        )}
        {noLog && (
          <MDBox p={2}>
            <MDTypography>No logs found for this subscriber</MDTypography>
          </MDBox>
        )}
      </DialogContent>

      <DialogActions style={{ color: "white", backgroundColor: "white" }}>
        <MDButton onClick={() => clearVariables()} variant="outlined" color="secondary">
          Close
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default SubscriberLog;
