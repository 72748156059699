/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// import { useState } from "react";
// @mui material components
import React, { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import client from "ApiClient";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MUIDataTable from "mui-datatables";
import {
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TableCell,
  Tooltip,
} from "@mui/material";
import Items from "layouts/items";
import ConfirmationAlert from "components/ConfirmationAlert";
import List from "./list";
import HtmlBlocks from "./htmlBlocks";

/* eslint-disable */
export default function Table(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);
  const [edit, setEdit] = useState(false);
  const [catalogs, setCatalogs] = useState([]);
  const [catId, setCatId] = useState();
  const [catName, setCatName] = useState();
  const [catalogName, setCatalogName] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openHtmlBlocks, setOpenHtmlBlocks] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  let catalogsSelected = [];

  const oldRender = TableCell.render;
  TableCell.render = function (...args) {
    const [props, ...otherArgs] = args;
    if (typeof props === "object" && props && "isEmpty" in props) {
      const { isEmpty, ...propsWithoutEmpty } = props;
      return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
    } else {
      return oldRender.apply(this, args);
    }
  };

  const options = {
    method: "GET",
    url: "catalogs",
  };

  const getData = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `catalogs`;
    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        setCatalogs(response);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, [props.groupAdded]);

  const handleEdit = (id) => {
    catalogsSelected = catalogs.filter((cat) => cat.cat_id == id);
    handleOpenModal();
  };

  const addCatalog = () => {
    options.url = `catalogs`;
    options.headers = {
      "content-type": "application/json",
    };

    if (edit) {
      options.method = "PUT";
      catalogsSelected = catalogs.filter((cat) => cat.cat_id == catId);
      catalogsSelected[0].cat_name = catalogName;
      options.data = catalogsSelected[0];
    } else {
      options.method = "POST";
      options.data = JSON.stringify({
        cat_name: catalogName,
      });
    }

    client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        setEdit(false);
        getData();
        setOpenModal(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const handleDelete = () => {
    setIsLoading(true);
    options.method = "DELETE";
    options.url = `catalogs`;
    options.headers = {
      "content-type": "application/json",
    };
    // catalogsSelected = catalogs.filter((cat) => cat.cat_id == catId);

    options.data = JSON.stringify(catId);
    client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        getData();
        setOpenAlert(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const changeStatus = (id, status) => {
    setIsLoading(true);
    options.method = "PUT";
    options.url = `catalogs`;
    catalogsSelected = catalogs.filter((cat) => cat.cat_id == id);
    catalogsSelected[0].cat_status = status == 0 ? 1 : 0;
    options.data = JSON.stringify(catalogsSelected[0]);
    options.headers = {
      "content-type": "application/json",
    };

    client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        getData();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  return (
    <div>
      <ConfirmationAlert
        openModal={openAlert}
        onClose={() => setOpenAlert(false)}
        onYes={handleDelete}
        yesText="Yes"
        title="Are you sure you want to delete this catalog? The items associated with it will be deleted."
      />
      <HtmlBlocks
        openModal={openHtmlBlocks}
        onClose={() => setOpenHtmlBlocks(false)}
        catID={catId}
        catName={catName}
      />
      <Dialog open={openModal} onClose={handleCloseModal}>
        <MDBox
          variant="gradient"
          bgColor="primary"
          borderRadius="lg"
          coloredShadow="primary"
          mx={2}
          mt={-1}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
            {edit ? "Edit catalog" : "Create a new catalog"}
          </MDTypography>
        </MDBox>
        <DialogContent>
          <MDInput
            type="text"
            label="Catalog name"
            fullWidth
            value={catalogName}
            onChange={(e) => setCatalogName(e.target.value)}
          />
        </DialogContent>

        <DialogActions style={{ color: "white", backgroundColor: "white" }}>
          <MDButton onClick={() => setOpenModal(false)} variant="outlined" color="secondary">
            Close
          </MDButton>
          <MDButton
            onClick={addCatalog}
            disabled={catalogName == ""}
            variant="gradient"
            color="success"
          >
            Save
          </MDButton>
        </DialogActions>
      </Dialog>
      <MDBox
        mx={2}
        mt={1}
        py={3}
        px={2}
        bgColor="badgeColors.primary"
        borderRadius="lg"
        variant="gradient"
      >
        <Grid container>
          <Grid item xs={12} md={10}>
            <MDTypography variant="h2">
              Catalogs
              {isLoading && (
                <CircularProgress
                  size={30}
                  style={{ marginLeft: 15, position: "relative", top: 4 }}
                />
              )}
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={2} style={{ width: "100%", textAlign: "right" }}>
            <MDBox justifyContent="right">
              <Tooltip title="Create a new catalog">
                <MDButton
                  variant="gradient"
                  color="secondary"
                  onClick={() => {
                    setEdit(false);
                    setOpenModal(true);
                  }}
                >
                  Create new
                </MDButton>
              </Tooltip>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox>
        <Grid container p={2}>
          {catalogs.length > 0 ? (
            <Grid item xs={12}>
              <List
                catalogs={catalogs}
                onChangeStatus={(id, status) => changeStatus(id, status)}
                onEdit={(id, name) => {
                  setCatId(id);
                  setCatalogName(name);
                  setEdit(true);
                  setOpenModal(true);
                }}
                onEditBlocks={(id, name) => {
                  setCatId(id);
                  setCatName(name);
                  setOpenHtmlBlocks(true);
                }}
                onDelete={(id) => {
                  setCatId(id);
                  setOpenAlert(true);
                }}
                viewItems={(id, name) => {
                  setCatId(id);
                  setCatName(name);
                }}
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <MDBox display="flex" justifyContent="center" alignItems="center">
                <MDTypography variant="h4" color="text">
                  You don't have any catalogs
                </MDTypography>
              </MDBox>
            </Grid>
          )}
        </Grid>
        {catId && (
          <Grid item xs={12} pt={2}>
            <div style={{ height: "100%", width: "100%" }}>
              <Card>
                <Items catId={catId} catName={catName} />
              </Card>
            </div>
          </Grid>
        )}
        <div style={{ height: "100%", width: "100%" }} hidden>
          <MUIDataTable data={[]} />
        </div>
      </MDBox>
    </div>
  );
}
