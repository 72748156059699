/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useRef, useState, React, Fragment, useEffect } from "react";
import theme from "assets/theme";

// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import client from "ApiClient";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  // IconButton,
  Input,
  // InputAdornment,
  InputLabel,
  Tooltip,
} from "@mui/material";
import SimpleReactValidator from "simple-react-validator";
import PublishIcon from "@mui/icons-material/Publish";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
/* eslint-disable */
function Edit(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [failUpload, setFailUpload] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const { openModal, closeModal } = props;
  const [, forceUpdate] = useState();
  const simpleValidator = useRef(new SimpleReactValidator());
  const [selectedFile, setSelectedFile] = useState(null);

  const [margin, setMargin] = useState(300);
  const [controller] = useMaterialUIController();
  const { miniSidenav } = controller;

  useEffect(() => {
    if (miniSidenav) setMargin(0);
    else setMargin(300);
  }, [miniSidenav]);

  const clearVariables = (reason) => {
    if (reason === "backdropClick") return;
    setFailUpload(false);
    setIsSelected(false);
    setSelectedFile(null);
    props.onClose();
  };

  const uploadFile = () => {
    if (simpleValidator.current.allValid()) {
      setIsLoading(true);
      try {
        const options = {
          method: "POST",
          url: `suppression/upload?list_id=${props.listId}`,
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        const formData = new FormData();
        formData.append("file", selectedFile);
        options.data = formData;
        client
          .request(options)
          .then((response) => {
            setIsLoading(false);
            clearVariables();
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  return (
    <Dialog
      open={openModal}
      onClose={(e, reason) => clearVariables(reason)}
      fullScreen
      disableEscapeKeyDown
      style={{ marginLeft: margin }}
      sx={{
        //You can copy the code below in your theme
        background: "#F4F0F700",
        "& .MuiPaper-root": {
          background: theme.palette.background.default,
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent", // Try to remove this to see the result
        },
      }}
    >
      <MDBox
        variant="gradient"
        bgColor="primary"
        borderRadius="lg"
        coloredShadow="primary"
        mx={2}
        mt={-1}
        p={2}
        mb={1}
        textAlign="center"
      >
        <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
          Add a suppression list
        </MDTypography>
      </MDBox>
      <DialogContent>
        <MDBox pt={4} pb={3} px={2}>
          <Card style={{ padding: 10, backgroundColor: "white", marginBottom: 10 }}>
            <MDBox component="form" role="form" justifyContent="center" alignItems="center">
              <MDBox mb={2} alignItems="center" display="flex" justifyContent="center">
                <MDTypography fontWeight="medium" mt={1}>
                  Please upload your file.
                </MDTypography>
              </MDBox>
              <MDBox alignItems="center" display="flex" justifyContent="center">
                <InputLabel htmlFor="import-button">
                  <Input
                    id="import-button"
                    inputProps={{
                      accept:
                        ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                    }}
                    onChange={handleFileSelect}
                    style={{ display: "none" }}
                    type="file"
                  />
                  <Tooltip id="button-add" title="Select a CSV file">
                    <Grid container spacing={2} m={2}>
                      <Grid item mt={1}>
                        <MDTypography>Pick your file</MDTypography>
                      </Grid>
                      <Grid item>
                        <PublishIcon fontSize="large" color="primary" />
                      </Grid>
                    </Grid>
                  </Tooltip>
                </InputLabel>
              </MDBox>
              {selectedFile != null && (
                <Fragment>
                  <MDBox alignItems="center" display="flex" justifyContent="center">
                    <MDButton
                      onClick={() => uploadFile()}
                      disabled={!isSelected}
                      variant="gradient"
                      color="info"
                    >
                      Upload
                    </MDButton>
                  </MDBox>
                  <MDBox mb={2} alignItems="center" display="flex" justifyContent="center">
                    <MDTypography>{selectedFile.name}</MDTypography>
                  </MDBox>
                </Fragment>
              )}
            </MDBox>
          </Card>
          {failUpload && (
            <Card style={{ padding: 10, backgroundColor: "white", marginBottom: 10 }}>
              <MDBox mb={2} alignItems="center" display="flex" justifyContent="center">
                <MDTypography color="error" mt={1}>
                  Your file does not seem to be valid.
                </MDTypography>
              </MDBox>
            </Card>
          )}
        </MDBox>
      </DialogContent>
      <DialogActions style={{ color: "white", backgroundColor: "white" }}>
        <MDButton
          onClick={() => clearVariables()}
          disabled={isLoading}
          variant="outlined"
          color="secondary"
        >
          Close
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default Edit;
