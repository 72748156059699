/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useRef, useEffect } from "react";
import theme from "assets/theme";
// import FacebookLogin from "react-facebook-login";
// import { GoogleLogin } from "react-google-login";
// react-router-dom components
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// @mui material components
import PasswordChecklist from "react-password-checklist";
import bcrypt from "bcryptjs-react";
import client from "ApiClient";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// import client from "ApiClient";
// import md5 from "md5";
import { Grid } from "@mui/material";
import { IconButton, InputAdornment, Tooltip } from "@material-ui/core";
import MSLeftPanel from "components/MSLeftPanel/MSLeftPanel";

/* eslint-disable */
function Cover() {
  const [termsAccepted, setTermsAccepted] = useState(true);
  const handlesetTermsAccepted = () => setTermsAccepted(!termsAccepted);
  const [isLoading, setIsLoading] = useState(false);
  const [passVisible, setPassVisible] = useState(false);
  const [emailTaken, setEmailTaken] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [, forceUpdate] = useState();
  const simpleValidator = useRef(new SimpleReactValidator());
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [couponCode, setCouponCode] = useState("");

  useEffect(() => {
    if (searchParams.get("email")) setEmail(searchParams.get("email"));

    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "1007872140481856",
        autoLogAppEvents: true,
        xfbml: true,
        version: "v18.0",
      });
    };

    (function (d, s, id) {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      const js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);

  /*
  const responseFacebook = (response) => {
    console.log(response);
  };

  const responseGoogle = (response) => {
    console.log(response);
  };
*/
  const Login = () => {
    navigate("/done?email=" + email);
  };

  const handleLogin = () => {
    if (simpleValidator.current.allValid()) {
      setEmailTaken(false);
      setIsLoading(true);
      const options = {
        method: "POST",
        url: `users/register`,
      };

      options.params = {
        name: name,
        email: email,
        coupon: couponCode,
        password: bcrypt.hashSync(password),
      };

      client
        .request(options)
        .then((response) => {
          setIsLoading(false);
          if (response.indexOf("http") !== -1) window.location.href = response;
          else {
            if (response === "User already exists") setEmailTaken(true);
            else if (response !== "Unauthorized") Login();
            else console.log("Unauthorized");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  /*
   <MDBox mt={4} mb={1}>
              <FacebookLogin
                appId="1007872140481856"
                autoLoad={false}
                fields="name,email"
                callback={responseFacebook}
                scope="email"
              />
              <GoogleLogin
                clientId="658977310896-knrl3gka66fldh83dao2rhgbblmd4un9.apps.googleusercontent.com"
                buttonText="Login"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy="single_host_origin"
              />
            </MDBox>
            */

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        xl={4}
        style={{ height: "100vh" }}
        sx={{
          //You can copy the code below in your theme
          background: theme.palette.background.default,
          "& .MuiPaper-root": {
            background: theme.palette.background.default,
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent", // Try to remove this to see the result
          },
        }}
      >
        <MSLeftPanel type="sign-up" />
      </Grid>
      <Grid
        item
        xs={12}
        xl={8}
        sx={{
          //You can copy the code below in your theme
          background: "#FFFFFF",
          "& .MuiPaper-root": {
            background: "#FFFFF",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent", // Try to remove this to see the result
          },
        }}
      >
        <MDBox mt={10} display="flex" justifyContent="center">
          <MDBox component="form" role="form" style={{ width: "40%" }}>
            <MDTypography variant="h2" fontWeight="medium" mb={2}>
              Sign up
            </MDTypography>

            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Full name"
                fullWidth
                required
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </MDBox>

            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                fullWidth
                required
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
              {emailTaken && (
                <MDBox ml={2}>
                  <MDTypography fontWeight="bold" color="error" variant="button" textGradient>
                    User already exists
                  </MDTypography>
                </MDBox>
              )}
            </MDBox>

            <MDBox mb={2}>
              <MDInput
                required
                type={passVisible ? "text" : "password"}
                label="Password"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setPassVisible(!passVisible)}
                        color="secondary"
                        aria-label="prompt"
                      >
                        {!passVisible ? (
                          <Tooltip title="View password" color="text">
                            <VisibilityIcon />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Hide password" color="text">
                            <VisibilityOffIcon />
                          </Tooltip>
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDTypography variant="button" fontWeight="bold" color="text">
                Your password must contain:
              </MDTypography>
              <PasswordChecklist
                rules={["minLength", "specialChar", "number", "capital", "lowercase", "match"]}
                minLength={8}
                value={password}
                valueAgain={passwordAgain}
                onChange={(isValid) => setValidPassword(isValid)}
                iconSize={12}
                style={{ fontSize: 12 }}
                messages={{
                  minLength: "Minimum 8 characters",
                  specialChar: "At least 1 special character",
                  number: "At least 1 number",
                  capital: "At least 1 upper case",
                  lowercase: "At least 1 lower case",
                  match: "Passwords match",
                }}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                required
                type={passVisible ? "text" : "password"}
                label="Confirm Password"
                fullWidth
                value={passwordAgain}
                onChange={(e) => setPasswordAgain(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setPassVisible(!passVisible)}
                        color="secondary"
                        aria-label="prompt"
                      >
                        {!passVisible ? (
                          <Tooltip title="View password" color="text">
                            <VisibilityIcon />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Hide password" color="text">
                            <VisibilityOffIcon />
                          </Tooltip>
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </MDBox>

            <MDBox mb={2}>
              <MDInput
                type="couponCode"
                label="Coupon code"
                placeholder="Enter a coupon code"
                fullWidth
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
              />
            </MDBox>
            <MDBox display="flex" alignItems="center">
              <MDTypography variant="button" fontWeight="regular" color="text">
                By creating an account, you agree to our &nbsp;
                <MDTypography
                  component="a"
                  href="#"
                  variant="button"
                  fontWeight="bold"
                  color="info"
                  textGradient
                  sx={{ cursor: "pointer", userSelect: "none" }}
                >
                  Terms
                </MDTypography>
                &nbsp; and have read and acknowledge the &nbsp;
                <MDTypography
                  component="a"
                  href="#"
                  variant="button"
                  fontWeight="bold"
                  color="info"
                  textGradient
                  sx={{ cursor: "pointer", userSelect: "none" }}
                >
                  Privacy Statement.
                </MDTypography>
              </MDTypography>
            </MDBox>
            <MDTypography fontWeight="bold" color="error" variant="button" textGradient>
              {simpleValidator.current.message("Terms and Conditions", termsAccepted, "accepted")}
            </MDTypography>

            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="primary" 
                fullWidth
                onClick={handleLogin}
                disabled={
                  isLoading ||
                  !validPassword ||
                  !simpleValidator.current.check(email, "required|email")
                }
              >
                Register
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} display="felx" textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
              </MDTypography>
              <MDTypography
                component={Link}
                to="/"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Sign In
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Grid>
    </Grid>
  );
}

export default Cover;
