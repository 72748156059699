import { Card, CircularProgress, Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MUIDataTable from "mui-datatables";
import AddIcon from "@mui/icons-material/Add";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import client from "ApiClient";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { Dialog, DialogActions, DialogContent, Menu, MenuItem } from "@material-ui/core";
import CustomToolbarSelect from "./customToolbarSelect";

/* eslint-disable */
export default function Interests(props) {
  const [interestsData, setInterestsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [subscriptorInterest, setSubscriptorInterest] = useState("");
  const [subscriptorDescriptionInterest, setSubscriptorDescriptionInterest] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let interestSelected = [];

  useEffect(() => {
    if (props.blogID) getInterests();
  }, [props.blogID]);

  const options = {
    method: "GET",
    url: "settings",
    headers: {
      "content-type": "application/json",
    },
  };

  const getInterests = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `interests/getInterests?blog_id=` + props.blogID;
    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        if (JSON.stringify(response) !== "[{}]") setInterestsData(response);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const handleDeleteInterests = () => {
    setIsLoading(true);
    options.method = "DELETE";
    options.url = `interests/deleteInterests`;
    options.data = JSON.stringify(interestSelected);
    options.headers = {
      "content-type": "application/json",
    };

    client
      .request(options)
      .then(() => {
        setIsLoading(false);
        getInterests();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const addInterest = async () => {
    setIsLoading(true);
    options.method = "POST";
    options.url = `interests/addInterests`;
    options.data = JSON.stringify({
      int_keyword: subscriptorInterest,
      int_description: subscriptorDescriptionInterest,
      int_blog_id: props.blogID,
    });

    await client
      .request(options)
      .then(() => {
        setIsLoading(false);
        setIsAdding(false);
        setSubscriptorInterest("");
        setSubscriptorDescriptionInterest("");
        getInterests();
      })
      .catch((error) => {
        setIsLoading(false);
        // eslint-disable-next-line
        console.log(error);
      });
  };

  const SuggestInterest = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `interests/suggestInterests?blog_id=` + props.blogID;

    await client
      .request(options)
      .then(() => {
        setIsLoading(false);
        getInterests();
      })
      .catch((error) => {
        setIsLoading(false);
        // eslint-disable-next-line
        console.log(error);
      });
  };

  const onChangeSubscriptorInterest = (event) => {
    setSubscriptorInterest(event.target.value);
  };

  const onChangeSubscriptorDescriptionInterest = (event) => {
    setSubscriptorDescriptionInterest(event.target.value);
  };

  const columnsInterest = [
    {
      label: "Interest",
      name: "int_keyword",
    },
    {
      label: "Description",
      name: "int_description",
      options: {
        filterOptions: { fullWidth: true },
      },
    },
  ];

  const customToolbar = () => <CustomToolbarSelect onDelete={handleDeleteInterests} />;

  const tableInterestsOptions = {
    textLabels: {
      body: {
        noMatch: "You don't have any interest",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "Clear filter",
      },
      filterType: "dropdown",
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "interest selected",
      },
    },
    print: false,
    filter: false,
    viewColumns: false,
    selectableRowsHideCheckboxes: false,
    selectableRowsOnClick: false,
    responsive: "simple",
    filterType: "dropdown",
    selectableRows: "multiple",
    fixedHeader: true,
    fixedSelectColumn: true,
    downloadOptions: { filename: "interests.csv", separator: "," },
    onRowSelectionChange: (curRowSelected, allRowsSelected) => {
      interestSelected = [];
      allRowsSelected.map((row) => {
        interestSelected.push(interestsData[row.dataIndex]);
      });
    },
    customToolbarSelect: customToolbar,
  };

  return (
    <Dialog
      open={props.openModal}
      onClose={() => props.onClose()}
      fullScreen
      sx={{
        //You can copy the code below in your theme
        background: "#F0F2F5",
        "& .MuiPaper-root": {
          background: "#F0F2F5",
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent", // Try to remove this to see the result
        },
      }}
    >
      <MDBox
        mx={2}
        mt={1}
        py={3}
        px={2}
        bgColor="badgeColors.primary"
        borderRadius="lg"
        variant="gradient"
      >
        <Grid container>
          <Grid item xs={10}>
            <MDTypography variant="h2">
              Interests for blog {props.blogName}
              {isLoading && (
                <CircularProgress
                  size={30}
                  style={{ marginLeft: 15, position: "relative", top: 4 }}
                />
              )}
            </MDTypography>
          </Grid>
          <Grid item xs={2}>
            <MDBox justifyContent="right">
              <MDBox display="flex" justifyContent="center">
                <MDButton variant="gradient" color="secondary" onClick={handleClick}>
                  &nbsp;&nbsp; Add Interests &nbsp;&nbsp;
                  <KeyboardArrowDownIcon />
                </MDButton>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setIsAdding(!isAdding);
                    }}
                  >
                    <AddIcon />
                    &nbsp; Manually
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      SuggestInterest();
                    }}
                  >
                    <AutoAwesomeIcon color="white" />
                    &nbsp; Suggest
                  </MenuItem>
                </Menu>
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <DialogContent>
        <Grid container spacing={1} py={3} px={2}>
          {isAdding && (
            <Grid item xs={3}>
              <Card>
                <MDBox m={2}>
                  <MDInput
                    type="text"
                    label="Keyword "
                    fullWidth
                    value={subscriptorInterest}
                    onChange={onChangeSubscriptorInterest}
                    required
                  />
                </MDBox>
                <MDBox m={2}>
                  <MDInput
                    type="text"
                    label="Description"
                    fullWidth
                    value={subscriptorDescriptionInterest}
                    onChange={onChangeSubscriptorDescriptionInterest}
                    required
                  />
                </MDBox>
                <MDBox m={2}>
                  <MDButton
                    autoFocus
                    onClick={() => setIsAdding(false)}
                    variant="gradient"
                    color="error"
                    style={{ marginRight: 10 }}
                  >
                    Close
                  </MDButton>
                  <MDButton onClick={addInterest} autoFocus variant="gradient" color="info">
                    Add
                  </MDButton>
                </MDBox>
              </Card>
            </Grid>
          )}
          <Grid item xs={12}>
            <MUIDataTable
              data={interestsData}
              columns={columnsInterest}
              options={tableInterestsOptions}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions style={{ color: "white", backgroundColor: "white" }}>
        <MDButton autoFocus onClick={() => props.onClose()} variant="gradient" color="secondary">
          Close
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
