import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import { useState } from "react";
import BlogArticlesTable from "./blogArticlesTable";
import AddArticle from "./add";

/* eslint-disable */
export default function BlogArticles(props) {
  const [addGroup, setAddGroup] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [currentArticle, setCurrentArticle] = useState();
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleAddGroup = () => {
    const refresh = addGroup + 1;
    setAddGroup(refresh);
  };

  const changeGroup = (currentGroupSelected) => {
    setCurrentArticle(currentGroupSelected);
  };

  return (
    <>
      <AddArticle
        openModal={openModal}
        closeModal={handleCloseModal}
        onDone={() => handleAddGroup()}
        blogId={props.blogId}
      />
      <MDBox pb={3} pt={2}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <BlogArticlesTable
              onChange={changeGroup}
              onAdd={handleOpenModal}
              groupAdded={addGroup}
              blogId={props.blogId}
              blogName={props.blogName}
            />
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
