/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// import { useState } from "react";
// @mui material components
import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { TableCell } from "@material-ui/core";
// import Table from "@mui/material/Table";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PublishIcon from "@mui/icons-material/Publish";
import ConfirmationAlert from "components/ConfirmationAlert";
import MDButton from "components/MDButton";
import client from "ApiClient";

import { CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import CustomToolbarSelect from "./customToolbarSelect";
import Edit from "./edit";

/* eslint-disable */
export default function Table(props) {
  const [acconuntsData, setAcconuntsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [sListSelected, setsListSelected] = useState([]);
  const [listSelected, setListSelected] = useState(0);
  const [openLog, setOpenLog] = useState(false);
  const handleOpenModal = () => setOpenModal(true);

  const oldRender = TableCell.render;
  TableCell.render = function (...args) {
    const [props, ...otherArgs] = args;
    if (typeof props === "object" && props && "isEmpty" in props) {
      const { isEmpty, ...propsWithoutEmpty } = props;
      return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
    } else {
      return oldRender.apply(this, args);
    }
  };

  const options = {
    method: "GET",
    url: "suppression",
  };

  const getData = async () => {
    options.method = "GET";
    options.url = `suppression`;
    await client
      .request(options)
      .then((response) => {
        if (JSON.stringify(response) !== "[{}]") setAcconuntsData(response);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };
  useEffect(() => {
    setIsLoading(true);
    getData();
  }, [props.groupAdded]);

  useEffect(() => {
    if (!openLog) {
      setIsLoading(true);
      setAcconuntsData([]);
      getData();
    }
  }, [openLog]);

  const handleEdit = () => {
    handleOpenModal();
  };

  const handleDelete = () => {
    setIsLoading(true);
    options.method = "DELETE";
    options.url = `suppression?spr_id=${sListSelected[0].spr_id}`;
    options.headers = {
      "content-type": "application/json",
    };

    client
      .request(options)
      .then((response) => {
        // setIsLoading(false);
        setOpenAlert(false);
        getData();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const columns = [
    {
      label: "Count",
      name: "count",
      options: {
        filter: true,
      },
    },
    {
      label: "Name",
      name: "spr_name",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: " ",
      name: "spr_id",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div>
              <IconButton
                onClick={() => {
                  setListSelected(acconuntsData[dataIndex]);
                  setOpenEdit(true);
                }}
                sx={{ fontWeight: "bold" }}
                color="primary"
                aria-label="prompt"
              >
                <Tooltip id="button-report" title="Append ">
                  <PublishIcon />
                </Tooltip>
              </IconButton>
            </div>
          );
        },
        filter: true,
      },
    },
  ];

  const customToolbar = () => <CustomToolbarSelect onDelete={() => setOpenAlert(true)} />;

  const tableOptions = {
    textLabels: {
      body: {
        noMatch: "you don't have any broadcast",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "Clear filter",
      },
      filterType: "dropdown",
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "broadcast/s selected",
      },
    },
    print: false,
    filter: false,
    viewColumns: false,
    download: false,
    selectableRowsHideCheckboxes: false,
    selectableRowsOnClick: false,
    responsive: "simple",
    filterType: "dropdown",
    selectableRows: "multiple",
    fixedHeader: true,
    fixedSelectColumn: true,
    downloadOptions: { filename: "broadcasts.csv", separator: "," },
    searchOpen: true,
    searchAlwaysOpen: true,
    searchOpen: true,
    searchAlwaysOpen: true,
    searchPlaceholder: "Search",
    searchPlaceholder: "Search",
    onRowSelectionChange: (curRowSelected, allRowsSelected) => {
      let selected = [];
      allRowsSelected.map((row) => {
        selected.push(acconuntsData[row.dataIndex]);
      });
      setsListSelected(selected);
    },
    customToolbarSelect: customToolbar,
    options: {
      setTableProps: () => {
        return {
          // material ui v4 only
          size: "small",
        };
      },
    },
  };

  return (
    <div>
      <ConfirmationAlert
        openModal={openAlert}
        onClose={() => setOpenAlert(false)}
        onYes={handleDelete}
        yesText="Yes"
        title="Are you sure you want to delete this suppression list?"
      />
      <Edit
        openModal={openEdit}
        onClose={() => {
          setOpenEdit(false);
          getData();
        }}
        listId={listSelected.spr_id}
      />
      <MDBox
        mx={2}
        mt={1}
        py={3}
        px={2}
        bgColor="badgeColors.primary"
        borderRadius="lg"
        variant="gradient"
      >
        <Grid container>
          <Grid item xs={12} md={10}>
            <MDTypography variant="h2">
              Suppression lists
              {isLoading && (
                <CircularProgress
                  size={30}
                  style={{ marginLeft: 15, position: "relative", top: 4 }}
                />
              )}
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={2} style={{ width: "100%", textAlign: "right" }}>
            <MDBox justifyContent="right">
              <Tooltip title="Create a new suppression list">
                <MDButton variant="gradient" color="secondary" onClick={props.onAdd}>
                  create new
                </MDButton>
              </Tooltip>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>

      <Grid container p={2}>
        <Grid item xs={12}>
          <div style={{ height: "100%", width: "100%" }}>
            <MUIDataTable data={acconuntsData} columns={columns} options={tableOptions} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
