/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// import { useState } from "react";
// @mui material components
import React, { useEffect } from "react";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import moment from "moment";

/* eslint-disable */
export default function GetSegmentation(props) {
  useEffect(() => {}, [props.segment]);

  const getDateFilter = (cf) => {
    if (cf.from_date != null && cf.to_date != null) {
      return cf.field_name + "= From:" +  moment(cf.from_date).format("MM-DD-YYYY") + " - To:" +  moment(cf.to_date).format("MM-DD-YYYY");
    } else if (cf.from_date != null) {
      return cf.field_name + "= After:" + moment(cf.from_date).format("MM-DD-YYYY");
    } else if (cf.to_date != null) {
      return cf.field_name + "= Before:" +  moment(cf.to_date).format("MM-DD-YYYY");
    } else if (cf.specific_date != null) {
      return cf.field_name + "= On:" +  moment(cf.specific_date).format("MM-DD-YYYY");
    }
  };

  const renderSegments = () => {
    const segmentProps = props.segment;
    const statuses =
      segmentProps.statuses.filter((element) => element.selected).length > 0 ? (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Status:{" "}
          </MDTypography>
          <MDTypography variant="body">
            {segmentProps.statuses
              .filter((element) => element.selected)
              .map((s) => s.status)
              .join(", ")}
          </MDTypography>
        </div>
      ) : (
        ""
      );

    const educations =
      segmentProps.educations.filter((element) => element.selected).length > 0 ? (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Education:{" "}
          </MDTypography>
          <MDTypography variant="body">
            {segmentProps.educations
              .filter((element) => element.selected)
              .map((s) => s.education)
              .join(", ")}
          </MDTypography>
        </div>
      ) : (
        ""
      );

    const children =
      segmentProps.children.filter((element) => element.selected).length > 0 ? (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Children:{" "}
          </MDTypography>
          <MDTypography variant="body">
            {segmentProps.children
              .filter((element) => element.selected)
              .map((s) => s.children)
              .join(", ")}
          </MDTypography>
        </div>
      ) : (
        ""
      );

    const ethnicities =
      segmentProps.ethnicities.filter((element) => element.selected).length > 0 ? (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Ethnicities:{" "}
          </MDTypography>
          <MDTypography variant="body">
            {segmentProps.ethnicities
              .filter((element) => element.selected)
              .map((s) => s.ethnicity)
              .join(", ")}
          </MDTypography>
        </div>
      ) : (
        ""
      );

    const genders =
      segmentProps.genders.filter((element) => element.selected).length > 0 ? (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Genders:{" "}
          </MDTypography>
          <MDTypography variant="body">
            {segmentProps.genders
              .filter((element) => element.selected)
              .map((s) => s.gender)
              .join(", ")}
          </MDTypography>
        </div>
      ) : (
        ""
      );

    const age =
      segmentProps.ageRanges.filter((element) => element.selected).length > 0 ? (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Age ranges:{" "}
          </MDTypography>
          <MDTypography variant="body">
            {segmentProps.ageRanges
              .filter((element) => element.selected)
              .map((s) => s.age_range)
              .join(", ")}
          </MDTypography>
        </div>
      ) : (
        ""
      );

    const employments =
      segmentProps.employments.filter((element) => element.selected).length > 0 ? (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Employments:{" "}
          </MDTypography>
          <MDTypography variant="body">
            {segmentProps.employments
              .filter((element) => element.selected)
              .map((s) => s.employment)
              .join(", ")}
          </MDTypography>
        </div>
      ) : (
        ""
      );

    const broadcastsEngaged =
      segmentProps.broadcastsEngaged.filter((element) => element.selected).length > 0 ? (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Broadcasts engaged:{" "}
          </MDTypography>
          <MDTypography variant="body">
            {segmentProps.broadcastsEngaged
              .filter((element) => element.selected)
              .map((s) => s.name)
              .join(", ")}
          </MDTypography>
        </div>
      ) : (
        ""
      );

    const broadcastsNoEngaged =
      segmentProps.broadcastsNoEngaged.filter((element) => element.selected).length > 0 ? (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Broadcasts no engaged:{" "}
          </MDTypography>
          <MDTypography variant="body">
            {segmentProps.broadcastsNoEngaged
              .filter((element) => element.selected)
              .map((s) => s.name)
              .join(", ")}
          </MDTypography>
        </div>
      ) : (
        ""
      );

    if (segmentProps.tags == null) segmentProps.tags = [];
    const tags =
      segmentProps.tags.length > 0 ? (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Tags:{" "}
          </MDTypography>
          <MDTypography variant="body">{segmentProps.tags.join(", ")}</MDTypography>
        </div>
      ) : (
        ""
      );

    let customFields = "";
    if (
      segmentProps.customFieldsText != null &&
      segmentProps.customFieldsText.filter(
        (cf) => cf.field_values.filter((fv) => fv.selected).length > 0
      ).length > 0
    ) {
      customFields = (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Custom fields text:{" "}
          </MDTypography>
          <MDTypography variant="body">
            {segmentProps.customFieldsText
              .filter((cf) => cf.field_values.filter((fv) => fv.selected).length > 0)
              .map((arrf) =>
                arrf.field_values.filter((field) => field.selected).map((s) => s.value)
              )
              .join(", ")}
          </MDTypography>
        </div>
      );
    }

    let customFieldsDate = "";
    if (
      segmentProps.customFieldsDate != null &&
      segmentProps.customFieldsDate.filter(
        (cf) => cf.from_date != null || cf.from_date != null || cf.to_date != null
      ).length > 0
    ) {
      customFieldsDate = (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Custom fields date:{" "}
          </MDTypography>
          <MDTypography variant="body">
            {segmentProps.customFieldsDate
              .filter((cf) => cf.from_date != null || cf.from_date != null || cf.to_date != null)
              .map((cf) => {
                return (
                  <div>
                    <MDTypography variant="body">{getDateFilter(cf)}</MDTypography>
                  </div>
                );
              })}
          </MDTypography>
        </div>
      );
    }

    if (
      statuses == "" &&
      educations == "" &&
      children == "" &&
      employments == "" &&
      ethnicities == "" &&
      genders == "" &&
      age == "" &&
      broadcastsEngaged == "" &&
      broadcastsNoEngaged == "" &&
      tags == "" &&
      customFields == "" &&
      customFieldsDate == ""
    )
      return (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            None
          </MDTypography>
        </div>
      );
    else
      return (
        <div>
          {statuses}
          {educations}
          {employments}
          {children}
          {ethnicities}
          {genders}
          {age}
          {broadcastsEngaged}
          {broadcastsNoEngaged}
          {tags}
          {customFields}
          {customFieldsDate}
        </div>
      );
  };

  return <MDBox> {renderSegments()}</MDBox>;
}
